/**
 * Create the redux store
 */
import { combineReducers, configureStore, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { intelDataSlice } from '../slices/IntelDataSlice';
import { collectionRequestSlice } from '../slices/CollectionRequestSlice';
import { atrSlice } from '../slices/AtrSlice';
import { iSpySlice, viewAllDetects } from '../slices/IspySlice';
import { iSpyInstanceImageSlice } from '../slices/IspyInstanceImageSlice';
import reducer from '../slices/AppSlice';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// DEV NOTE: 20250130 -- WIP start of experimental use of listeners to eventually help manage connections
const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  actionCreator: iSpySlice.actions.onSocketConnection,
  effect: async (action, listenerApi) => {
    console.log(`listenerMiddleware onSocketConnection effect`);
    listenerApi.cancelActiveListeners();
    // @ts-ignore
    const os = listenerApi.getOriginalState().iSpy;
    // @ts-ignore
    const s = listenerApi.getState().iSpy;
    // @ts-ignore
    console.log(`original state: ${listenerApi.getOriginalState().iSpy}`);
    // @ts-ignore
    console.log(`current state: ${listenerApi.getState().iSpy}`);
    console.log(`instanceId`);
    // const task = listenerApi.fork(async (forkApi) => {
    // });
    // const result = await task.result;
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(viewAllDetects.fulfilled, iSpySlice.actions.onError),
  // actionCreator: iSpySlice.actions.viewAllDetects.fulfilled ,
  effect: async (action, listenerApi) => {
    console.log(`listenerMiddleware viewAllDetects OR onError effect`);
    listenerApi.cancelActiveListeners();
    // @ts-ignore

    if (
      await listenerApi.condition((action, state) => {
        // @ts-ignore
        return state.iSpy.instanceId !== null;
      })
    ) {
      console.log(`viewAllDetects action, instanceId not null....do stuff`);
    }
    // const task = listenerApi.fork(async (forkApi) => {
    // });
    // const result = await task.result;
  },
});

const rootReducer = combineReducers({
  collectionRequests: collectionRequestSlice.reducer,
  atr: atrSlice.reducer,
  intelData: intelDataSlice.reducer,
  iSpy: iSpySlice.reducer,
  iSpyInstanceImages: iSpyInstanceImageSlice.reducer,
  app: reducer,
});

//Used currently to initialize store by when loading & for unit tests only.
export const initStore = () => {
  // const reducer = {
  //   collectionRequests: collectionRequestSlice.reducer,
  //   intelData: intelDataSlice.reducer,
  //   intelDataStatus: intelDataStatusSlice.reducer,
  // };

  //This will be enhanced as features/functionality is required
  const store = configureStore({
    reducer: rootReducer,
    // middleware: getDefaultMiddleware =>
    //   getDefaultMiddleware({
    //     serializableCheck: false,
    //   }),
    // middleware: () => {
    //   return getDefaultMiddleware();
    // },
    // middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
    middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
    // TODO --20240108 enable when state is stable
    // devTools: process.env.NODE_ENV !== 'production',
    devTools: true,
  });

  return store;
};

export const setupStoreForTest = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type AppStore = ReturnType<typeof setupStoreForTest>;

//Components requiring access to redux should import this store
export const store = initStore();
