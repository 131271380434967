import { createSelector, createSlice } from '@reduxjs/toolkit';

interface AppState {
  error: boolean;
  errorMessage: string | null;
  detailedErrorMessage: string | null;
}

const appSlice = createSlice({
  name: 'app',
  initialState: { error: false, errorMessage: null, detailedErrorMessage: null } as AppState,
  reducers: {
    setError: (state, param) => {
      const { payload } = param;
      state.error = true;
      state.errorMessage = payload.errorMessage;
      state.detailedErrorMessage = payload.detailedErrorMessage;
    },
    clear: (state, param) => {
      state.errorMessage = null;
      state.error = false;
    },
  },
});

const errorState = state => state.app.error;
const errorMessageState = state => state.app.errorMessage;
const detailedErrorMessageState = state => state.app.detailedErrorMessage;

export const selectError = createSelector([errorState], x => x);
export const selectErrorMessage = createSelector([errorMessageState], x => x);
export const selectDetailedErrorMessage = createSelector([detailedErrorMessageState], x => x);

const { actions, reducer } = appSlice;
export const { setError, clear } = actions;
export default reducer;
