import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { RequestStatus } from '../types/RequestStatus';
import { Feature } from 'geojson';
import axios from 'axios';
import axiosRetry from 'axios-retry';

//////////////////////////////////////////////////////////
// test state while we are waiting for full ATR path

export const atrTestState = {
  ids: [
    '004c598e-333c-4bc7-b26a-7a4f57540953',
    '01fb0f4b-2c38-4254-a074-f2905623029b',
    '025f8c1f-5f62-496b-a2eb-d121bef23967',
    '042e2607-8733-4992-85c2-b6ed1ae0c616',
    '052aa977-58e7-4ead-81bd-36fc587867d6',
    '063ba487-94ea-445c-afb2-16c0b0d2283b',
    '075f5308-374e-4b33-957b-6f16d2da8c9b',
    '09434267-15ab-4953-8160-7e17a5bfcb3b',
    '0a60ca22-7bf9-4011-840c-8ed265b1edf3',
    '0a8df166-03b3-43e1-8037-5a0a9957d595',
    '0e5b8fd7-352c-426a-98fa-72a71c4bb878',
    '0e9568a3-1050-48e6-b039-63790e1bb5b7',
    '1040130f-62d9-406c-ac44-7f5536345217',
    '10b89e4f-1ecf-4416-bddf-10b433c39110',
    '11b89e4f-1ecf-4416-bddf-11b433c39111',
    '11d24104-bd22-4c2e-be58-b852af1b328c',
    '13b89e4f-1ecf-4416-bddf-13b433c39113',
    '14b89e4f-1ecf-4416-bddf-14b433c39114',
    '1604c841-7211-499a-aa69-63b32a2fa7ad',
    '16359a8f-2067-4dd6-ae36-b8cefdb45e50',
    '1951a720-09db-4851-8e44-ff39c2d65850',
    '19bec521-6106-4203-aa4c-ba1f4fde7558',
    '1afdcb9f-01b6-4fce-b6cd-86aa2bd843d4',
    '1bcd2a95-7cd6-4647-ad9a-645ab022efc6',
    '1d86d20c-dac4-4097-b802-bf06466c9117',
    '1def0704-8dca-448a-8618-53ebb88d6eb1',
    '1e040c1d-2b68-49d1-b490-82e5e9cc149a',
    '1f4833e1-3a0f-485e-a781-f016c3821268',
    '1fd3133a-3e1a-40af-a69e-01b9b5598238',
    '20bdfccf-25a5-45e4-8883-26947cfb7691',
    '23cf6be1-de56-485c-9ce5-6a839b82e9d5',
    '246f2226-d22b-44c2-b7c6-db05438cac1f',
    '248afddc-54c1-414e-bef8-f06cb53461c9',
    '2532795c-aa4e-4a07-8d8d-05ae937c7101',
    '25c14077-d6b5-4a2f-b8cf-06be50c9945a',
    '26b54553-461b-43b7-978b-9097fdcdd324',
    '27017e67-0bd0-47fa-a134-9607820bbd87',
    '28decc01-8517-4174-af43-893b34e337ee',
    '2c4f22df-6350-4a33-bedf-16a2badbddeb',
    '2ccbfa3b-1e5f-4788-b3c1-3aabd72f2e87',
    '2ce00b06-5ea6-421c-b44e-57c976415f14',
    '2d3a1051-b848-493f-9e68-3de2e482e8cd',
    '337d1258-e42c-475c-a6a2-1c20a6c9a359',
    '346d1ccc-bf30-479a-a692-44d50ed15fa4',
    '348a548c-f9d5-4ce7-b7c5-e13bc89c2909',
    '3538a9d9-d81f-46ce-b2f8-b80dd6407836',
    '359734bf-3092-42c1-9222-06d5dcd610a2',
    '36d96b16-5e20-471f-8a51-125752ddaea0',
    '37c59752-c4cf-420a-9947-5bc1f53cf304',
    '3815e766-c4e6-4757-9237-f4f0f91bd72e',
    '388d334d-140c-403d-b3e0-94ebc76281a5',
    '3a53fea4-54c4-417d-bcd7-27dd7e5f4a43',
    '3ad06ded-d977-4fda-9aa0-32aefe1083e7',
    '3b45460b-c616-4c25-baf0-42815c24fbd8',
    '3bde004a-5fd2-4fb1-8320-464577457fbd',
    '3dabd63e-2fde-42d6-b05b-89513de479be',
    '3ef63248-4ce2-4260-9b4f-cb09b0a8c704',
    '3f960e77-bb26-4594-b5c7-2aae29445ffa',
    '412a9d2c-d7fd-4dc2-b2a1-9389b1fc396d',
    '41a12e40-d289-4cec-a315-e5191a755fcd',
    '428c41e6-83ee-427b-be58-395346bbaddb',
    '432163ce-bf37-4c4c-9086-71239c7b863d',
    '44284456-c6b9-4cc3-b0f6-5fe4cf0cde67',
    '45277ac7-ab88-4945-a165-5701a6bb29c4',
    '47736295-894d-49d7-aba4-11c497637ade',
    '47a41f9f-c113-4a6c-8eea-78d046c15329',
    '48dcf0ec-b2e9-4973-9654-324d40609da2',
    '4bc09d6f-33ca-4f9e-84ff-b5fff0aa6313',
    '4bc68825-18db-4470-8af4-0b4f011d9a9e',
    '4d4c4645-ca19-4b12-9341-8635c29b3c5c',
    '4d7dfb60-eb23-4d69-91df-d809b0e29383',
    '4e10f766-39cd-4138-b2b7-00d88a83cd33',
    '5059b19a-23fd-48f2-9c2c-27d54b5a7d8d',
    '50bccf14-1b6c-41ca-b0da-d93b92107f63',
    '537444e3-b45b-42f8-8f56-811f489a1de5',
    '53bc41a0-da38-4f6c-adb8-cfe8222093a9',
    '54fecec6-42d9-4e41-a8cb-0e1c94a3e1c8',
    '595f498a-146f-4f76-8666-83ad7ccf1043',
    '5b062927-6f2f-4d73-9700-fec4f1447d9f',
    '5d3be3c0-349d-42da-a0ff-944b0be195af',
    '5dc10694-8791-4f5c-98a4-0fdea00ec46f',
    '5ddc7a87-9be7-4362-933a-a5fe34988f68',
    '5eb77d69-3b39-47d0-a661-dc88629d5503',
    '62e738bc-6df4-43bf-ab3d-9f18e47b0a5d',
    '654d9914-3b57-4369-8f0a-d6830bebf4ae',
    '6691f26f-270d-4eff-adcc-49b1611d6351',
    '698d3670-6cce-4535-af1a-fa4d68ed63a0',
    '6aebf6ec-feec-4fbe-9657-d9be2bee1b77',
    '6bba42bf-038d-4170-93f3-8e2d9f3cc345',
    '6e0de562-96ee-433f-9d73-efe761f7af9b',
    '6edeb8d6-3197-431a-bb82-48a78c2fffee',
    '6f4f37c0-fa8c-43d0-a523-17bd82636e4a',
    '6ff1838e-623c-4f56-b221-289c900c2545',
    '7005a797-2fe9-4452-a435-dff6b0b127aa',
    '7080214a-dc8e-469d-9395-0909dfc66622',
    '70833d5a-1d02-48ed-86b3-5aab86ae51a4',
    '70f34e1c-88f4-41e5-8f1b-4f8dedd8a29f',
    '7399737e-fad1-4f20-9d6c-d09c37ff326d',
    '75b89e4f-1ecf-4416-bddf-71b433c39187',
    '75e9bb32-76a2-4434-9f08-157a1f0f4e03',
    '767400ba-4633-4898-8e11-db07a3641b1d',
    '76996b2b-8016-4208-b92b-bb6447da181f',
    '76f840f8-f496-4f1b-a312-1861f7d5bf7a',
    '775f83c2-b8e5-422a-bf53-b802bdf1193b',
    '785019ff-302e-4bae-8e28-72252e81d2a1',
    '7886c034-222e-4d68-9e23-6eb79321b56c',
    '78ba3b4c-3080-4794-8505-7bba48c0d027',
    '7d63b09e-0088-4f7a-a5e4-9124f5ac75dc',
    '7f2a29ca-8648-42d0-9e79-59f19d6ac435',
    '7fdda0eb-f739-48c2-93cd-3bd46b0a8c73',
    '80c41bae-4fcc-49b0-b482-a291b23227e7',
    '81893e3a-1e1b-41d1-8ebe-6503a557d8c3',
    '829a524c-418b-4e91-b441-94ef042d514b',
    '829b7fc7-4d4b-4b97-8999-6f94083e1946',
    '83fb7d77-391e-4b2f-b085-07aa16fa9c5a',
    '851da353-6086-489a-9c1f-60394b8c4e62',
    '85b89e4f-1ecf-4416-bddf-81b433c39188',
    '87d523ee-57d8-40e0-b67f-525b561f3845',
    '88885baf-b783-40bd-88a1-f50191d09c37',
    '8a8caddf-6336-40cd-bb48-628b81fd796a',
    '8c10b398-f8f6-422f-9264-6f68d292febc',
    '8d59f37b-f59c-4287-b12b-5de02f5f05ca',
    '8d67b1a0-2797-4a2c-a283-f55cdc698abf',
    '90317da6-4a27-493b-9e2d-b4b53b7067ca',
    '91e96022-d93d-444d-af4a-04b10b9d6ecb',
    '92c379dc-be87-4400-95c6-dbf4c130aa88',
    '92d2eb68-c84d-41e9-a982-7adf2b976839',
    '93e7a366-3c38-4e6a-a9dc-f0057e699157',
    '952fcd90-7a7b-4c47-b7ee-977269c06e70',
    '95b89e4f-1ecf-4416-bddf-91b433c39189',
    '95c0ed4a-7559-4622-8a45-161cd2691446',
    '969e8b70-a29a-4773-aa2f-c2aaee5dc17f',
    '9ba93075-5c27-416e-be5c-fe1ea7084d5e',
    '9c2ea151-f2e1-46f2-86e2-0ed8d7bc6c05',
    '9d6f5f05-d961-49ce-85d8-15bde77eea7d',
    '9d81dda3-91c9-4c72-b448-ecd3004e4129',
    '9e2c86c3-d6d8-4aa7-b1b7-2daf39deab7f',
    '9e545541-6ea7-41c8-993e-2da7333e3fa3',
    '9e937ccd-68ee-48fc-a581-61571ced8b9d',
    '9f5f7695-c486-4f7c-b062-ec4c8e9a6969',
    '9f9eeec6-db36-48cc-aaa4-236dadd0e57c',
    'a02ad6f4-b1e8-4354-a3de-99208f698f09',
    'a3e60e03-8b6d-4cfa-a526-9f79fce3a025',
    'a427f497-fa3a-4e56-9a35-2374c256e36e',
    'a4491235-dce4-4efb-81f5-d75f830fbff3',
    'a4a804e9-bb04-4460-9c0d-31250ee20beb',
    'a6122658-6efa-4381-9513-da30f7251cc6',
    'aa86620e-7cb3-478f-a81c-489c71a845a3',
    'aa889fac-c859-4faf-9d12-5e3287ebba0c',
    'ab4c8194-feaa-44c3-bbfe-d2dbb3fb1ec1',
    'ab5f5ba2-5d4f-40cd-adb4-c6816d978435',
    'abfab2e1-fea0-4aae-b7b1-361e89471a5c',
    'ace81492-c715-4447-96ce-98d34c9d8153',
    'ada2f0c2-5428-4115-a5c2-4a0218a6e7fd',
    'ada7ebfa-6949-4139-a6ba-7fc12e5dbbb5',
    'ae68bdaa-5119-48e3-b4a4-4be56e705e59',
    'aec3fb69-b1d8-4311-a940-3af980866294',
    'aef8f4ef-b89f-4550-926c-1047c8b01dac',
    'af954ae7-24aa-4f8b-a5c4-4c9fa791a644',
    'b09284f1-7c04-4ad8-8633-6863265aae53',
    'b18831d2-a6b2-4630-92c7-5265f6a8f2ab',
    'b27831b5-a6b2-4630-23c8-2265f6a8f2bc',
    'b3367bd8-1fe5-46ea-bc31-eae6b84b7d45',
    'b336b876-8a0e-4d0d-937d-3e23f75e14f8',
    'b34aea30-579a-46f0-922a-e57593a96d6a',
    'b38d4c87-0e1b-431b-9ffb-a63c4363842c',
    'b3bc33f8-5db1-45e7-aaff-c66cadc517fb',
    'b3c2ac5a-a671-4d89-96bc-df456e27cd86',
    'b4318171-bd9e-4d3c-b254-5d2ca252497b',
    'b5937c0c-9206-4a8a-ac04-53b67347cc1c',
    'b5b89e4f-1ecf-4416-bddf-01b433c39180',
    'b5b89e4f-1ecf-4416-bddf-11b433c39181',
    'b5b89e4f-1ecf-4416-bddf-21b433c3918a',
    'b5f1a027-bdb9-4fc8-8001-d9b33f6c5e4b',
    'b9acbbbe-b480-49f7-a2a3-d876045d0fe7',
    'babd2c84-6cca-451f-8a6a-e5db8261642f',
    'bb7b2397-b303-4dc3-b357-db8b8b753529',
    'bc044053-86f2-4195-8078-49c910fc35a8',
    'be4dc8c6-1eb7-4a09-9c2d-0b21f9c4c705',
    'be9c40d8-809e-40bd-aabb-d465d3c8817d',
    'be9c709d-ce6f-47e6-bce6-98c832c3a19d',
    'bee8517a-2895-4859-ae84-06879858f56a',
    'bf466764-91bf-4d0d-bfe6-b53e368f08bb',
    'bf6c8191-6748-4dc7-856c-7587de63bd89',
    'c05f2b9c-3ddc-4fcc-a815-0587b1c2023a',
    'c0977094-5e10-48c7-8914-26ea1249034c',
    'c10b7c02-a254-4e6d-b842-41428ae83c4f',
    'c44e300c-3b6e-43d3-995c-02debf89973f',
    'c477d578-7794-450b-9d7c-b6fb024790da',
    'c48a82c9-bffe-4532-a4d7-742371e46688',
    'c56129d9-0092-4966-b96f-00ba1a7d867b',
    'c58501e9-f477-4646-bce7-d283a6b75093',
    'c5b89e4f-1ecf-4416-bddf-21b433c39182',
    'c866ae6a-a17a-4a90-a384-1e1f12eed262',
    'c97fb086-7b7b-45f0-aad9-39c9ed6c2712',
    'caee8bb1-1e0e-42de-8e6d-8b641119233f',
    'cbffcb64-d47b-4a25-8bc5-d782a6692866',
    'cc07f245-ab0f-4189-90d9-84874c10fceb',
    'cc4190a9-000b-4a1b-a302-5d264785cff9',
    'cd71def1-b475-4227-bfca-284f5da6db44',
    'cf2974f7-65c5-418a-a8fb-7d5408f9ea9a',
    'cfbccb2a-3485-4fae-9dd5-c8797eac1c15',
    'd0687d54-ad19-4281-a561-4b5cc05ff4cd',
    'd073d8e3-7e2d-4b0a-b7bc-e23883d5045a',
    'd28721e2-2ff2-4b02-85d3-5c8e864664a4',
    'd39495e5-2164-4588-9e7f-55904965fe01',
    'd4f0fb57-9c59-4869-9717-d711c3c698a8',
    'd5b89e4f-1ecf-4416-bddf-41b433c39184',
    'd7332123-b0cb-4142-a0f7-aa1e41853fbc',
    'd7d26829-d474-4911-8354-dc0e44865ff0',
    'd8152a32-69b5-44ef-bfb8-97b1a126a7c6',
    'd8c7e109-bd07-4adc-87c3-b66640931221',
    'd932f0c0-9cf9-4458-ad59-5216456c2bdf',
    'd9f66c4c-a9af-4e6d-8cb2-fc98a1ad2827',
    'daa5fc4a-a68d-4cf3-8e42-1fd6ac87feb1',
    'dc4a5733-318b-40d0-8aef-fea7df5b7796',
    'dcd9f650-6d31-4cd4-83fd-f937b0bba606',
    'de877fbf-ce37-4675-b26b-7367384e0938',
    'debf9cd0-57cf-4bdb-bea9-783782fc221c',
    'df498090-317a-4d74-8906-9f3f87876112',
    'dfa839a5-90cf-4737-b9cc-a8a3aa9f8519',
    'dfef8b3e-e3ae-49d3-9001-a81ea7c4de43',
    'e0582775-8db1-4118-953b-36cc73db1267',
    'e1514645-040b-4e43-8df1-e9cd2b78852f',
    'e18831d6-f6b2-4630-93c6-5265f6a8f2fd',
    'e19df4fe-65ca-4abf-ac0f-e242e20de698',
    'e212689e-4b43-44ab-af31-1c5e48886a60',
    'e2b21dda-affd-4e88-831b-9b9c66d79d67',
    'e2fe2709-bceb-4108-ab52-70e45808af18',
    'e3b18db7-de66-4c6d-b764-2e62e62d72c9',
    'e3c8f72e-cd47-4247-a0de-7f10065528d1',
    'e5b89e4f-1ecf-4416-bddf-51b433c39185',
    'e60b75ff-4349-4bf1-a7aa-2984e9098a8e',
    'e6ef048a-8192-4114-be25-68a81a62ea7e',
    'e7e6b73c-5ab7-452d-92df-c3a49625306c',
    'e86dce3b-0b50-4b41-983e-99d766c78da9',
    'e98008ac-1672-4635-990a-463ca766ecb7',
    'eafcac07-6c01-4a70-832d-987d657d3a41',
    'eb7f1e68-222f-4f27-bf82-878516231c69',
    'ed29fca7-10a4-4bdf-9aef-b94e4cd8afef',
    'ee5c6988-9017-4c44-994f-f3d918aec2c4',
    'eeb7f26c-1cbd-4018-b08e-1b948c202f89',
    'eecd7ef6-9494-47e6-afde-c99115fce20e',
    'f2cfa5b0-f35f-4a0a-831f-fd32cbba2ad1',
    'f430610f-0765-4563-b4a9-4b95c545046c',
    'f47269aa-6e24-4666-b261-b9decf002af2',
    'f4a350b3-21e2-4021-b56f-a62857c46004',
    'f501d1f5-046e-4c74-9736-2f8afabb3b6b',
    'f5150dd3-d301-4cf9-b320-e128bad44c6b',
    'f5b89e4f-1ecf-4416-bddf-61b433c39186',
    'f5d57916-306c-48c1-8d97-e71a80be9def',
    'f689bb13-b304-40b1-a438-4c9db467b818',
    'f8547d27-7cd6-44e2-b01b-2e13abcd5769',
    'f9602b95-63b1-49b4-9cb7-f9cbc6862ca7',
    'fcdb0a46-7d3f-4faa-a33b-94eb4aa87b68',
    'fdc08085-63e9-4eb9-bbab-00328398ba75',
    'fec7421f-4e0b-4517-a97b-9f57d018bc07',
  ],
  entities: {
    'b5b89e4f-1ecf-4416-bddf-01b433c39180': {
      id: 'b5b89e4f-1ecf-4416-bddf-01b433c39180',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3779021750854, 25.247063959114],
              [55.378455049925, 25.247063959114],
              [55.378455049925, 25.2475086516452],
              [55.3779021750854, 25.2475086516452],
              [55.3779021750854, 25.247063959114],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.1,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'ec46cc2b-8fdf-42ab-b29e-d2f3672d15fe',
          'Modified Time': '2025-02-25T17:32:13.378Z',
          'Created Time': '2025-02-26T06:30:46.533Z',
          Affiliation: 'p',
        },
        id: 'b5b89e4f-1ecf-4416-bddf-01b433c39180',
        bbox: null,
      },
    },
    'b5b89e4f-1ecf-4416-bddf-11b433c39181': {
      id: 'b5b89e4f-1ecf-4416-bddf-11b433c39181',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3554130088114, 25.2504767196982],
              [55.3558829572848, 25.2504767196982],
              [55.3558829572848, 25.2510767213694],
              [55.3554130088114, 25.2510767213694],
              [55.3554130088114, 25.2504767196982],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9306640625,
          classificationIndex: '0',
          classificationName: 'military aircraft',
          Symbology: 'a-.-A-M-F',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': 'f84a514b-f3cc-4cfb-9546-7cbd29eced8b',
          'Modified Time': '2025-02-26T14:59:03.000Z',
          'Created Time': '2025-02-26T03:45:27.666Z',
          Affiliation: 'p',
        },
        id: 'b5b89e4f-1ecf-4416-bddf-11b433c39181',
        bbox: null,
      },
    },
    'c5b89e4f-1ecf-4416-bddf-21b433c39182': {
      id: 'c5b89e4f-1ecf-4416-bddf-21b433c39182',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3418861369978, 25.2650543644429],
              [55.3424943051504, 25.2650543644429],
              [55.3424943051504, 25.2656501861453],
              [55.3418861369978, 25.2656501861453],
              [55.3418861369978, 25.2650543644429],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          Confidence: 3550.76,
          'classification Index': '0',
          'classification Name': 'freight car',
          Symbology: 'a-.-G-E-V-T',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1a1ee386-c439-4a8c-9339-32a7bc046b38',
          'Modified Time': '2025-02-26T06:29:39.500Z',
          'Created Time': '2025-02-25T23:20:26.248Z',
          Affiliation: 'p',
        },
        id: 'c5b89e4f-1ecf-4416-bddf-21b433c39182',
        bbox: null,
      },
    },
    'd5b89e4f-1ecf-4416-bddf-41b433c39184': {
      id: 'd5b89e4f-1ecf-4416-bddf-41b433c39184',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3799532585906, 25.2518520484748],
              [55.3805060022002, 25.2518520484748],
              [55.3805060022002, 25.252440331247],
              [55.3799532585906, 25.252440331247],
              [55.3799532585906, 25.2518520484748],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          Confidence: 0.9,
          'Classification Index': '0',
          'Classification Name': 'maritime vessel',
          Symbology: 'SUSPXR---------',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'a7d1980e-c435-475f-9947-e5d61d36afab',
          'Modified Time': '2025-02-25T18:04:35.982Z',
          'Created Time': '2025-02-26T09:20:08.359Z',
          Affiliation: 'p',
        },
        id: 'd5b89e4f-1ecf-4416-bddf-41b433c39184',
        bbox: null,
      },
    },
    'e5b89e4f-1ecf-4416-bddf-51b433c39185': {
      id: 'e5b89e4f-1ecf-4416-bddf-51b433c39185',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3707452077543, 25.2425003992719],
              [55.3712984143168, 25.2425003992719],
              [55.3712984143168, 25.2430886318924],
              [55.3707452077543, 25.2430886318924],
              [55.3707452077543, 25.2425003992719],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          Confidence: 90.3584378794184,
          ClassificationIndex: '0',
          ClassificationName: 'catamaran',
          Symbology: 'a-.-S-X-R',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': 'f21492e0-6c8d-4720-a573-e34078bf9de0',
          'Modified Time': '2025-02-25T17:46:08.710Z',
          'Created Time': '2025-02-26T10:02:45.886Z',
          Affiliation: 'p',
        },
        id: 'e5b89e4f-1ecf-4416-bddf-51b433c39185',
        bbox: null,
      },
    },
    'f5b89e4f-1ecf-4416-bddf-61b433c39186': {
      id: 'f5b89e4f-1ecf-4416-bddf-61b433c39186',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3571332430118, 25.2637230692996],
              [55.3574177126042, 25.2637230692996],
              [55.3574177126042, 25.2640140784225],
              [55.3571332430118, 25.2640140784225],
              [55.3571332430118, 25.2637230692996],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.4584378794184,
          classificationIndex: '0',
          classificationName: 'submarine',
          Symbology: 'a-.-U-S',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '948d526a-d4b1-4d96-8417-8a2c100a2391',
          'Modified Time': '2025-02-26T13:59:18.532Z',
          'Created Time': '2025-02-26T14:26:02.377Z',
          Affiliation: 'p',
        },
        id: 'f5b89e4f-1ecf-4416-bddf-61b433c39186',
        bbox: null,
      },
    },
    '75b89e4f-1ecf-4416-bddf-71b433c39187': {
      id: '75b89e4f-1ecf-4416-bddf-71b433c39187',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3396269188994, 25.261718703093],
              [55.3402449045657, 25.261718703093],
              [55.3402449045657, 25.2622255167342],
              [55.3396269188994, 25.2622255167342],
              [55.3396269188994, 25.261718703093],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 1,
          classificationIndex: '0',
          classificationName: 'school bus',
          Symbology: 'a-.-G-E-V-U-B',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'ae4bf777-49a0-49fa-9210-005d0a3815d7',
          'Modified Time': '2025-02-25T19:00:06.951Z',
          'Created Time': '2025-02-25T18:58:20.629Z',
          Affiliation: 'p',
        },
        id: '75b89e4f-1ecf-4416-bddf-71b433c39187',
        bbox: null,
      },
    },
    '85b89e4f-1ecf-4416-bddf-81b433c39188': {
      id: '85b89e4f-1ecf-4416-bddf-81b433c39188',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3613185427622, 25.2622614841538],
              [55.3616880262558, 25.2622614841538],
              [55.3616880262558, 25.2626702048322],
              [55.3613185427622, 25.2626702048322],
              [55.3613185427622, 25.2622614841538],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.3584378794184,
          classificationIndex: '0',
          classificationName: 'passenger car',
          Symbology: 'a-.-G-E-V-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': 'ba84a1bd-4f8b-4fc7-8c53-08fe15d4a0ec',
          'Modified Time': '2025-02-26T13:07:13.581Z',
          'Created Time': '2025-02-26T08:19:16.088Z',
          Affiliation: 'p',
        },
        id: '85b89e4f-1ecf-4416-bddf-81b433c39188',
        bbox: null,
      },
    },
    '95b89e4f-1ecf-4416-bddf-91b433c39189': {
      id: '95b89e4f-1ecf-4416-bddf-91b433c39189',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3588171722083, 25.2636707530527],
              [55.3591081813315, 25.2636707530527],
              [55.3591081813315, 25.2640009993608],
              [55.3588171722083, 25.2640009993608],
              [55.3588171722083, 25.2636707530527],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9609375,
          classificationIndex: '0',
          classificationName: 'tank',
          Symbology: 'a-.-G-E-V-A-T',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '47839613-5ec2-46aa-8484-5f7599383737',
          'Modified Time': '2025-02-26T03:12:46.589Z',
          'Created Time': '2025-02-26T02:55:26.257Z',
          Affiliation: 'p',
        },
        id: '95b89e4f-1ecf-4416-bddf-91b433c39189',
        bbox: null,
      },
    },
    '10b89e4f-1ecf-4416-bddf-10b433c39110': {
      id: '10b89e4f-1ecf-4416-bddf-10b433c39110',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.343341372428, 25.2663127235175],
              [55.3436389210821, 25.2663127235175],
              [55.3436389210821, 25.2666429698256],
              [55.343341372428, 25.2666429698256],
              [55.343341372428, 25.2663127235175],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95703125,
          classificationIndex: '0',
          classificationName: 'water tower',
          Symbology: 'a-.-G-U-S-S-W',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': '3fb411f4-a347-4f1e-bcba-97995beb52a6',
          'Modified Time': '2025-02-26T05:26:31.314Z',
          'Created Time': '2025-02-25T17:33:24.301Z',
          Affiliation: 'p',
        },
        id: '10b89e4f-1ecf-4416-bddf-10b433c39110',
        bbox: null,
      },
    },
    '11b89e4f-1ecf-4416-bddf-11b433c39111': {
      id: '11b89e4f-1ecf-4416-bddf-11b433c39111',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3437043163907, 25.2661034585302],
              [55.343995325514, 25.2661034585302],
              [55.343995325514, 25.2664337048383],
              [55.3437043163907, 25.2664337048383],
              [55.3437043163907, 25.2661034585302],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.75263671875,
          classificationIndex: '0',
          classificationName: 'barn',
          Symbology: 'a-.-G-I',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': '8875af4c-deae-4454-b263-a8bc8f11bbd0',
          'Modified Time': '2025-02-26T03:04:19.288Z',
          'Created Time': '2025-02-25T19:22:04.898Z',
          Affiliation: 'p',
        },
        id: '11b89e4f-1ecf-4416-bddf-11b433c39111',
        bbox: null,
      },
    },
    'b5b89e4f-1ecf-4416-bddf-21b433c3918a': {
      id: 'b5b89e4f-1ecf-4416-bddf-21b433c3918a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3535626591622, 25.2665906535787],
              [55.3538536682855, 25.2665906535787],
              [55.3538536682855, 25.2669208998868],
              [55.3535626591622, 25.2669208998868],
              [55.3535626591622, 25.2665906535787],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.4501953125,
          classificationIndex: '0',
          classificationName: 'missile',
          Symbology: 'a-.-G-U-S-T-I',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2ac5300d-7f11-47bb-82a3-b9bb750359e4',
          'Modified Time': '2025-02-26T07:45:15.983Z',
          'Created Time': '2025-02-26T03:11:09.326Z',
          Affiliation: 'p',
        },
        id: 'b5b89e4f-1ecf-4416-bddf-21b433c3918a',
        bbox: null,
      },
    },
    '13b89e4f-1ecf-4416-bddf-13b433c39113': {
      id: '13b89e4f-1ecf-4416-bddf-13b433c39113',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3375604271477, 25.273712202678],
              [55.3378906734561, 25.273712202678],
              [55.3378906734561, 25.2739770536776],
              [55.3375604271477, 25.2739770536776],
              [55.3375604271477, 25.273712202678],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.8423828125,
          classificationIndex: '0',
          classificationName: 'aircraft carrier',
          Symbology: 'a-.-S-C-L-C-V',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'ea010956-c548-45e9-96aa-1c599953578a',
          'Modified Time': '2025-02-26T00:29:18.373Z',
          'Created Time': '2025-02-26T13:30:22.886Z',
          Affiliation: 'p',
        },
        id: '13b89e4f-1ecf-4416-bddf-13b433c39113',
        bbox: null,
      },
    },
    '14b89e4f-1ecf-4416-bddf-14b433c39114': {
      id: '14b89e4f-1ecf-4416-bddf-14b433c39114',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3409871413183, 25.2654233473214],
              [55.3417162990092, 25.2654233473214],
              [55.3417162990092, 25.265733975037],
              [55.3409871413183, 25.265733975037],
              [55.3409871413183, 25.2654233473214],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.3384765625,
          classificationIndex: '0',
          classificationName: 'ambulance',
          Symbology: 'a-.-G-E-V-me',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': '7f6ff563-11bb-4004-8feb-4d07c53f555f',
          'Modified Time': '2025-02-26T00:12:36.062Z',
          'Created Time': '2025-02-26T08:22:45.376Z',
          Affiliation: 'p',
        },
        id: '14b89e4f-1ecf-4416-bddf-14b433c39114',
        bbox: null,
      },
    },
    '8d59f37b-f59c-4287-b12b-5de02f5f05ca': {
      id: '8d59f37b-f59c-4287-b12b-5de02f5f05ca',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3780784735007, 25.2529241088045],
              [55.3786419193414, 25.2529241088045],
              [55.3786419193414, 25.2534223534422],
              [55.3780784735007, 25.2534223534422],
              [55.3780784735007, 25.2529241088045],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.962890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '48fbc627-ff8e-42de-a3eb-9672e4cf4695',
          'Modified Time': '2025-02-25T21:57:40.749Z',
          'Created Time': '2025-02-26T01:33:26.962Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8d59f37b-f59c-4287-b12b-5de02f5f05ca',
        bbox: null,
      },
    },
    'ada7ebfa-6949-4139-a6ba-7fc12e5dbbb5': {
      id: 'ada7ebfa-6949-4139-a6ba-7fc12e5dbbb5',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.370324633257, 25.2461089418671],
              [55.3710571143177, 25.2461089418671],
              [55.3710571143177, 25.2467005357712],
              [55.370324633257, 25.2467005357712],
              [55.370324633257, 25.2461089418671],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96240234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '222c0553-2d3a-4d38-b9b2-0adaedcc82e3',
          'Modified Time': '2025-02-26T07:28:59.672Z',
          'Created Time': '2025-02-26T04:36:28.107Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ada7ebfa-6949-4139-a6ba-7fc12e5dbbb5',
        bbox: null,
      },
    },
    'fdc08085-63e9-4eb9-bbab-00328398ba75': {
      id: 'fdc08085-63e9-4eb9-bbab-00328398ba75',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3627740623011, 25.2485663392449],
              [55.3634649838232, 25.2485663392449],
              [55.3634649838232, 25.2491593004392],
              [55.3627740623011, 25.2491593004392],
              [55.3627740623011, 25.2485663392449],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96240234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'cec31848-1ebb-427c-a34b-9b7b182df6ff',
          'Modified Time': '2025-02-25T22:37:16.005Z',
          'Created Time': '2025-02-25T22:16:55.254Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'fdc08085-63e9-4eb9-bbab-00328398ba75',
        bbox: null,
      },
    },
    'f689bb13-b304-40b1-a438-4c9db467b818': {
      id: 'f689bb13-b304-40b1-a438-4c9db467b818',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3946074608962, 25.239088530596],
              [55.3951486211412, 25.239088530596],
              [55.3951486211412, 25.2396769899176],
              [55.3946074608962, 25.2396769899176],
              [55.3946074608962, 25.239088530596],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '600b4092-db07-4ce0-b99d-0e36c9635e06',
          'Modified Time': '2025-02-26T08:40:49.037Z',
          'Created Time': '2025-02-25T15:30:42.523Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f689bb13-b304-40b1-a438-4c9db467b818',
        bbox: null,
      },
    },
    '004c598e-333c-4bc7-b26a-7a4f57540953': {
      id: '004c598e-333c-4bc7-b26a-7a4f57540953',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3985449613743, 25.2386564065497],
              [55.3990963952808, 25.2386564065497],
              [55.3990963952808, 25.2392355499343],
              [55.3985449613743, 25.2392355499343],
              [55.3985449613743, 25.2386564065497],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd1b0a9b2-f460-4483-9cff-2d3649a3145f',
          'Modified Time': '2025-02-25T16:42:37.391Z',
          'Created Time': '2025-02-26T09:08:48.830Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '004c598e-333c-4bc7-b26a-7a4f57540953',
        bbox: null,
      },
    },
    '5059b19a-23fd-48f2-9c2c-27d54b5a7d8d': {
      id: '5059b19a-23fd-48f2-9c2c-27d54b5a7d8d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3657814261217, 25.253762319256],
              [55.3664616723856, 25.253762319256],
              [55.3664616723856, 25.2542300253652],
              [55.3657814261217, 25.2542300253652],
              [55.3657814261217, 25.253762319256],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1e75e08f-bea3-4484-b2c2-06d2c9ac00fe',
          'Modified Time': '2025-02-25T19:52:09.910Z',
          'Created Time': '2025-02-26T14:03:06.810Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5059b19a-23fd-48f2-9c2c-27d54b5a7d8d',
        bbox: null,
      },
    },
    'dc4a5733-318b-40d0-8aef-fea7df5b7796': {
      id: 'dc4a5733-318b-40d0-8aef-fea7df5b7796',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3760693184496, 25.2482126592857],
              [55.3764643418442, 25.2482126592857],
              [55.3764643418442, 25.2484739058893],
              [55.3760693184496, 25.2484739058893],
              [55.3760693184496, 25.2482126592857],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a6fd2d76-0e5a-46ff-a019-3fb653c91609',
          'Modified Time': '2025-02-26T00:14:08.495Z',
          'Created Time': '2025-02-25T20:46:54.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dc4a5733-318b-40d0-8aef-fea7df5b7796',
        bbox: null,
      },
    },
    '829b7fc7-4d4b-4b97-8999-6f94083e1946': {
      id: '829b7fc7-4d4b-4b97-8999-6f94083e1946',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3733931408191, 25.2494664446815],
              [55.3741043512732, 25.2494664446815],
              [55.3741043512732, 25.250058733868],
              [55.3733931408191, 25.250058733868],
              [55.3733931408191, 25.2494664446815],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e2476eaf-c37a-4da3-8428-4a31b8025e0f',
          'Modified Time': '2025-02-26T09:47:10.093Z',
          'Created Time': '2025-02-26T12:23:43.789Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '829b7fc7-4d4b-4b97-8999-6f94083e1946',
        bbox: null,
      },
    },
    '5ddc7a87-9be7-4362-933a-a5fe34988f68': {
      id: '5ddc7a87-9be7-4362-933a-a5fe34988f68',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790388646336, 25.2568220938108],
              [55.3796338562675, 25.2568220938108],
              [55.3796338562675, 25.2574360190511],
              [55.3790388646336, 25.2574360190511],
              [55.3790388646336, 25.2568220938108],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8f23a98b-348e-4640-b921-8ee47648980f',
          'Modified Time': '2025-02-26T07:30:24.529Z',
          'Created Time': '2025-02-25T17:56:38.011Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5ddc7a87-9be7-4362-933a-a5fe34988f68',
        bbox: null,
      },
    },
    'aa86620e-7cb3-478f-a81c-489c71a845a3': {
      id: 'aa86620e-7cb3-478f-a81c-489c71a845a3',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3726099053522, 25.2563328867518],
              [55.3728891873027, 25.2563328867518],
              [55.3728891873027, 25.2565798667673],
              [55.3726099053522, 25.2565798667673],
              [55.3726099053522, 25.2563328867518],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '156d3c09-226a-48e4-92ac-d2772f64e0c2',
          'Modified Time': '2025-02-25T15:36:38.137Z',
          'Created Time': '2025-02-26T10:09:40.158Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aa86620e-7cb3-478f-a81c-489c71a845a3',
        bbox: null,
      },
    },
    'c44e300c-3b6e-43d3-995c-02debf89973f': {
      id: 'c44e300c-3b6e-43d3-995c-02debf89973f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3801665179132, 25.2460136112015],
              [55.3807191774769, 25.2460136112015],
              [55.3807191774769, 25.2466018443369],
              [55.3801665179132, 25.2466018443369],
              [55.3801665179132, 25.2460136112015],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '975212a5-dcef-426b-aeb1-7fa54d7d0b77',
          'Modified Time': '2025-02-25T19:52:58.688Z',
          'Created Time': '2025-02-25T23:44:54.254Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c44e300c-3b6e-43d3-995c-02debf89973f',
        bbox: null,
      },
    },
    'c477d578-7794-450b-9d7c-b6fb024790da': {
      id: 'c477d578-7794-450b-9d7c-b6fb024790da',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4012872039471, 25.2371775377836],
              [55.4019855763331, 25.2371775377836],
              [55.4019855763331, 25.2377610100943],
              [55.4012872039471, 25.2377610100943],
              [55.4012872039471, 25.2371775377836],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '77ece1d3-40a1-4ed3-a1c4-f2bde74707c5',
          'Modified Time': '2025-02-26T02:15:37.888Z',
          'Created Time': '2025-02-25T23:17:08.003Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c477d578-7794-450b-9d7c-b6fb024790da',
        bbox: null,
      },
    },
    '54fecec6-42d9-4e41-a8cb-0e1c94a3e1c8': {
      id: '54fecec6-42d9-4e41-a8cb-0e1c94a3e1c8',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3419087131288, 25.2601378106148],
              [55.3424428047064, 25.2601378106148],
              [55.3424428047064, 25.2607359216325],
              [55.3419087131288, 25.2607359216325],
              [55.3419087131288, 25.2601378106148],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e627668d-4299-4ca4-97b0-9d7c2acf2d15',
          'Modified Time': '2025-02-25T19:31:57.244Z',
          'Created Time': '2025-02-25T16:42:56.925Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '54fecec6-42d9-4e41-a8cb-0e1c94a3e1c8',
        bbox: null,
      },
    },
    'f5150dd3-d301-4cf9-b320-e128bad44c6b': {
      id: 'f5150dd3-d301-4cf9-b320-e128bad44c6b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3404854470778, 25.2639440494353],
              [55.3408715129184, 25.2639440494353],
              [55.3408715129184, 25.2641877606439],
              [55.3404854470778, 25.2641877606439],
              [55.3404854470778, 25.2639440494353],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0adb0127-8c67-4e6b-8851-3af46f07d29d',
          'Modified Time': '2025-02-26T10:59:38.281Z',
          'Created Time': '2025-02-26T05:48:54.683Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f5150dd3-d301-4cf9-b320-e128bad44c6b',
        bbox: null,
      },
    },
    'ae68bdaa-5119-48e3-b4a4-4be56e705e59': {
      id: 'ae68bdaa-5119-48e3-b4a4-4be56e705e59',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3689167713257, 25.244827614363],
              [55.3694068921442, 25.244827614363],
              [55.3694068921442, 25.2453101851324],
              [55.3689167713257, 25.2453101851324],
              [55.3689167713257, 25.244827614363],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7576d83f-4354-47f8-9ae0-780e00e490bc',
          'Modified Time': '2025-02-26T08:14:34.819Z',
          'Created Time': '2025-02-25T18:00:53.835Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ae68bdaa-5119-48e3-b4a4-4be56e705e59',
        bbox: null,
      },
    },
    '45277ac7-ab88-4945-a165-5701a6bb29c4': {
      id: '45277ac7-ab88-4945-a165-5701a6bb29c4',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3621671416802, 25.2466757385429],
              [55.3625626865364, 25.2466757385429],
              [55.3625626865364, 25.2472690259867],
              [55.3621671416802, 25.2472690259867],
              [55.3621671416802, 25.2466757385429],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0bc6e41b-0c0c-4f46-84c3-e62acf4f85db',
          'Modified Time': '2025-02-25T20:01:58.580Z',
          'Created Time': '2025-02-26T03:41:58.260Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '45277ac7-ab88-4945-a165-5701a6bb29c4',
        bbox: null,
      },
    },
    '25c14077-d6b5-4a2f-b8cf-06be50c9945a': {
      id: '25c14077-d6b5-4a2f-b8cf-06be50c9945a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3970565389944, 25.2377261873303],
              [55.3976080474368, 25.2377261873303],
              [55.3976080474368, 25.2383142969197],
              [55.3970565389944, 25.2383142969197],
              [55.3970565389944, 25.2377261873303],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e49c77a1-a8ef-42ef-a892-f57c3c3743b1',
          'Modified Time': '2025-02-26T14:04:52.764Z',
          'Created Time': '2025-02-25T22:59:59.426Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '25c14077-d6b5-4a2f-b8cf-06be50c9945a',
        bbox: null,
      },
    },
    '063ba487-94ea-445c-afb2-16c0b0d2283b': {
      id: '063ba487-94ea-445c-afb2-16c0b0d2283b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3640363744368, 25.2599140649079],
              [55.3644636506592, 25.2599140649079],
              [55.3644636506592, 25.2601654125656],
              [55.3640363744368, 25.2601654125656],
              [55.3640363744368, 25.2599140649079],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e13e975d-9801-46c9-9824-abd27cc36fa3',
          'Modified Time': '2025-02-25T22:46:11.078Z',
          'Created Time': '2025-02-25T20:24:39.404Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '063ba487-94ea-445c-afb2-16c0b0d2283b',
        bbox: null,
      },
    },
    'bb7b2397-b303-4dc3-b357-db8b8b753529': {
      id: 'bb7b2397-b303-4dc3-b357-db8b8b753529',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3468246014595, 25.2575043641642],
              [55.3473266381241, 25.2575043641642],
              [55.3473266381241, 25.2579867433464],
              [55.3468246014595, 25.2579867433464],
              [55.3468246014595, 25.2575043641642],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '45375b56-e1c9-4385-b1d7-819ea16378ac',
          'Modified Time': '2025-02-26T05:36:34.090Z',
          'Created Time': '2025-02-26T06:22:59.232Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bb7b2397-b303-4dc3-b357-db8b8b753529',
        bbox: null,
      },
    },
    '95c0ed4a-7559-4622-8a45-161cd2691446': {
      id: '95c0ed4a-7559-4622-8a45-161cd2691446',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3978184468427, 25.2372971734029],
              [55.3983699028925, 25.2372971734029],
              [55.3983699028925, 25.2378852769353],
              [55.3978184468427, 25.2378852769353],
              [55.3978184468427, 25.2372971734029],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9599609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e76b1a6b-255a-4987-898f-cc54ad7d53e0',
          'Modified Time': '2025-02-26T07:56:58.530Z',
          'Created Time': '2025-02-26T11:54:12.767Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '95c0ed4a-7559-4622-8a45-161cd2691446',
        bbox: null,
      },
    },
    '2532795c-aa4e-4a07-8d8d-05ae937c7101': {
      id: '2532795c-aa4e-4a07-8d8d-05ae937c7101',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3779042458055, 25.2522221872167],
              [55.3782886930312, 25.2522221872167],
              [55.3782886930312, 25.25247481571],
              [55.3779042458055, 25.25247481571],
              [55.3779042458055, 25.2522221872167],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd935b0d0-e0c1-42b4-817c-7d38e52b90cc',
          'Modified Time': '2025-02-26T08:36:39.677Z',
          'Created Time': '2025-02-25T17:21:26.720Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2532795c-aa4e-4a07-8d8d-05ae937c7101',
        bbox: null,
      },
    },
    'be9c40d8-809e-40bd-aabb-d465d3c8817d': {
      id: 'be9c40d8-809e-40bd-aabb-d465d3c8817d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.35112020074, 25.2528840475904],
              [55.3516746352281, 25.2528840475904],
              [55.3516746352281, 25.2534813842291],
              [55.35112020074, 25.2534813842291],
              [55.35112020074, 25.2528840475904],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0e4b72f3-cf5e-434f-a51c-f09e0cb156a7',
          'Modified Time': '2025-02-26T03:37:14.074Z',
          'Created Time': '2025-02-25T15:50:15.231Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'be9c40d8-809e-40bd-aabb-d465d3c8817d',
        bbox: null,
      },
    },
    '5eb77d69-3b39-47d0-a661-dc88629d5503': {
      id: '5eb77d69-3b39-47d0-a661-dc88629d5503',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3661808380069, 25.2467446332209],
              [55.3668820345835, 25.2467446332209],
              [55.3668820345835, 25.2472744199971],
              [55.3661808380069, 25.2472744199971],
              [55.3661808380069, 25.2467446332209],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '77fc1db3-2aa4-4e3a-b37f-0fd11eac02c1',
          'Modified Time': '2025-02-26T10:53:48.560Z',
          'Created Time': '2025-02-26T11:35:44.493Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5eb77d69-3b39-47d0-a661-dc88629d5503',
        bbox: null,
      },
    },
    'c56129d9-0092-4966-b96f-00ba1a7d867b': {
      id: 'c56129d9-0092-4966-b96f-00ba1a7d867b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3632926494913, 25.2609666140538],
              [55.3639731658458, 25.2609666140538],
              [55.3639731658458, 25.2615600308644],
              [55.3632926494913, 25.2615600308644],
              [55.3632926494913, 25.2609666140538],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6ace8087-d615-4c75-9016-2ebd6bad878c',
          'Modified Time': '2025-02-26T09:27:33.065Z',
          'Created Time': '2025-02-26T10:42:29.571Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c56129d9-0092-4966-b96f-00ba1a7d867b',
        bbox: null,
      },
    },
    'b9acbbbe-b480-49f7-a2a3-d876045d0fe7': {
      id: 'b9acbbbe-b480-49f7-a2a3-d876045d0fe7',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3745807127017, 25.2403855143638],
              [55.375365425696, 25.2403855143638],
              [55.375365425696, 25.2410381853131],
              [55.3745807127017, 25.2410381853131],
              [55.3745807127017, 25.2403855143638],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5496a43b-0c87-48d9-bdc0-162f3b7d0590',
          'Modified Time': '2025-02-26T13:02:44.462Z',
          'Created Time': '2025-02-26T04:14:46.114Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b9acbbbe-b480-49f7-a2a3-d876045d0fe7',
        bbox: null,
      },
    },
    'f430610f-0765-4563-b4a9-4b95c545046c': {
      id: 'f430610f-0765-4563-b4a9-4b95c545046c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790369936968, 25.2523699664181],
              [55.3795687361099, 25.2523699664181],
              [55.3795687361099, 25.2529499443304],
              [55.3790369936968, 25.2529499443304],
              [55.3790369936968, 25.2523699664181],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5657799c-0420-4f5e-bcd3-8c180427020a',
          'Modified Time': '2025-02-25T17:44:11.630Z',
          'Created Time': '2025-02-26T06:52:28.647Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f430610f-0765-4563-b4a9-4b95c545046c',
        bbox: null,
      },
    },
    'b3bc33f8-5db1-45e7-aaff-c66cadc517fb': {
      id: 'b3bc33f8-5db1-45e7-aaff-c66cadc517fb',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3574290613555, 25.2491781058732],
              [55.3579620731587, 25.2491781058732],
              [55.3579620731587, 25.2497581274564],
              [55.3574290613555, 25.2497581274564],
              [55.3574290613555, 25.2491781058732],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd35ef24e-bea7-4747-bb95-7d1067015572',
          'Modified Time': '2025-02-25T15:35:26.737Z',
          'Created Time': '2025-02-25T21:05:02.061Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b3bc33f8-5db1-45e7-aaff-c66cadc517fb',
        bbox: null,
      },
    },
    '8a8caddf-6336-40cd-bb48-628b81fd796a': {
      id: '8a8caddf-6336-40cd-bb48-628b81fd796a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3597353166635, 25.2502742710053],
              [55.3602787539289, 25.2502742710053],
              [55.3602787539289, 25.2508629362068],
              [55.3597353166635, 25.2508629362068],
              [55.3597353166635, 25.2502742710053],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8accaa2f-7e26-49d7-a3d5-dd135ed28335',
          'Modified Time': '2025-02-26T10:42:06.098Z',
          'Created Time': '2025-02-25T18:44:21.234Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8a8caddf-6336-40cd-bb48-628b81fd796a',
        bbox: null,
      },
    },
    'fcdb0a46-7d3f-4faa-a33b-94eb4aa87b68': {
      id: 'fcdb0a46-7d3f-4faa-a33b-94eb4aa87b68',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.381738095681, 25.2508884978591],
              [55.3823960637154, 25.2508884978591],
              [55.3823960637154, 25.2514016928114],
              [55.381738095681, 25.2514016928114],
              [55.381738095681, 25.2508884978591],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '50e3326e-2129-4a86-a951-05c768fb1fe5',
          'Modified Time': '2025-02-26T02:59:16.441Z',
          'Created Time': '2025-02-26T06:05:50.065Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'fcdb0a46-7d3f-4faa-a33b-94eb4aa87b68',
        bbox: null,
      },
    },
    '01fb0f4b-2c38-4254-a074-f2905623029b': {
      id: '01fb0f4b-2c38-4254-a074-f2905623029b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3798891919162, 25.2388676733685],
              [55.3805787511944, 25.2388676733685],
              [55.3805787511944, 25.2393169498346],
              [55.3798891919162, 25.2393169498346],
              [55.3798891919162, 25.2388676733685],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95849609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '116815f5-e2c2-4881-a281-f72d04bc4283',
          'Modified Time': '2025-02-25T18:49:07.790Z',
          'Created Time': '2025-02-26T04:42:56.103Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '01fb0f4b-2c38-4254-a074-f2905623029b',
        bbox: null,
      },
    },
    '1951a720-09db-4851-8e44-ff39c2d65850': {
      id: '1951a720-09db-4851-8e44-ff39c2d65850',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3660988974393, 25.2597769497039],
              [55.3663889375344, 25.2597769497039],
              [55.3663889375344, 25.2600954095568],
              [55.3660988974393, 25.2600954095568],
              [55.3660988974393, 25.2597769497039],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95849609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8cb9e3c6-7bf4-4895-b664-c464329c36d5',
          'Modified Time': '2025-02-26T10:19:17.302Z',
          'Created Time': '2025-02-26T02:26:14.841Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1951a720-09db-4851-8e44-ff39c2d65850',
        bbox: null,
      },
    },
    'd8152a32-69b5-44ef-bfb8-97b1a126a7c6': {
      id: 'd8152a32-69b5-44ef-bfb8-97b1a126a7c6',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3972603330761, 25.2362089337577],
              [55.3979905446036, 25.2362089337577],
              [55.3979905446036, 25.236782440758],
              [55.3972603330761, 25.236782440758],
              [55.3972603330761, 25.2362089337577],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e4ddc930-fcac-435e-9358-ebe9ef4a8144',
          'Modified Time': '2025-02-26T01:55:33.196Z',
          'Created Time': '2025-02-26T05:14:32.487Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd8152a32-69b5-44ef-bfb8-97b1a126a7c6',
        bbox: null,
      },
    },
    'cbffcb64-d47b-4a25-8bc5-d782a6692866': {
      id: 'cbffcb64-d47b-4a25-8bc5-d782a6692866',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4008294252565, 25.2365042860866],
              [55.4015383327001, 25.2365042860866],
              [55.4015383327001, 25.2370874218017],
              [55.4008294252565, 25.2370874218017],
              [55.4008294252565, 25.2365042860866],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c7d91a00-d8d2-46ed-9c0e-61b5ebdaf0ab',
          'Modified Time': '2025-02-26T05:33:53.558Z',
          'Created Time': '2025-02-26T08:20:07.474Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cbffcb64-d47b-4a25-8bc5-d782a6692866',
        bbox: null,
      },
    },
    'd073d8e3-7e2d-4b0a-b7bc-e23883d5045a': {
      id: 'd073d8e3-7e2d-4b0a-b7bc-e23883d5045a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3520335995444, 25.2546822133266],
              [55.3524824303581, 25.2546822133266],
              [55.3524824303581, 25.2552469792593],
              [55.3520335995444, 25.2552469792593],
              [55.3520335995444, 25.2546822133266],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '90bc77ca-f60e-4bd9-aeb9-b421ccb026f9',
          'Modified Time': '2025-02-26T07:24:44.247Z',
          'Created Time': '2025-02-25T19:59:08.813Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd073d8e3-7e2d-4b0a-b7bc-e23883d5045a',
        bbox: null,
      },
    },
    'dcd9f650-6d31-4cd4-83fd-f937b0bba606': {
      id: 'dcd9f650-6d31-4cd4-83fd-f937b0bba606',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3443606972056, 25.2630789162133],
              [55.3449052440367, 25.2630789162133],
              [55.3449052440367, 25.2636677316625],
              [55.3443606972056, 25.2636677316625],
              [55.3443606972056, 25.2630789162133],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '596bf77d-7849-4282-bdd7-bba4bf093005',
          'Modified Time': '2025-02-26T02:23:11.321Z',
          'Created Time': '2025-02-26T14:22:06.437Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dcd9f650-6d31-4cd4-83fd-f937b0bba606',
        bbox: null,
      },
    },
    '1f4833e1-3a0f-485e-a781-f016c3821268': {
      id: '1f4833e1-3a0f-485e-a781-f016c3821268',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3757238935495, 25.2540655558184],
              [55.3764139174286, 25.2540655558184],
              [55.3764139174286, 25.2546585504951],
              [55.3757238935495, 25.2546585504951],
              [55.3757238935495, 25.2540655558184],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd08c363-847b-4fb9-b3f1-185996be5400',
          'Modified Time': '2025-02-25T16:18:07.156Z',
          'Created Time': '2025-02-26T04:17:32.047Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1f4833e1-3a0f-485e-a781-f016c3821268',
        bbox: null,
      },
    },
    'c48a82c9-bffe-4532-a4d7-742371e46688': {
      id: 'c48a82c9-bffe-4532-a4d7-742371e46688',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3958688687386, 25.2384589303067],
              [55.3963784614454, 25.2384589303067],
              [55.3963784614454, 25.2389676426456],
              [55.3958688687386, 25.2389676426456],
              [55.3958688687386, 25.2384589303067],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '13a4aa2e-8d78-422a-8f63-32a6cb574987',
          'Modified Time': '2025-02-25T23:41:31.350Z',
          'Created Time': '2025-02-26T01:13:30.527Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c48a82c9-bffe-4532-a4d7-742371e46688',
        bbox: null,
      },
    },
    '93e7a366-3c38-4e6a-a9dc-f0057e699157': {
      id: '93e7a366-3c38-4e6a-a9dc-f0057e699157',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3605646659096, 25.2625198445929],
              [55.3611293155422, 25.2625198445929],
              [55.3611293155422, 25.2630361462562],
              [55.3605646659096, 25.2630361462562],
              [55.3605646659096, 25.2625198445929],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ecf0403b-2616-4171-ac2a-b9941c28e88a',
          'Modified Time': '2025-02-25T23:42:49.054Z',
          'Created Time': '2025-02-26T11:23:02.767Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '93e7a366-3c38-4e6a-a9dc-f0057e699157',
        bbox: null,
      },
    },
    '3a53fea4-54c4-417d-bcd7-27dd7e5f4a43': {
      id: '3a53fea4-54c4-417d-bcd7-27dd7e5f4a43',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.381276110949, 25.2430466250641],
              [55.3819445574612, 25.2430466250641],
              [55.3819445574612, 25.2435145519624],
              [55.381276110949, 25.2435145519624],
              [55.381276110949, 25.2430466250641],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0b60ffa8-3124-4499-9513-40c736cdfa3d',
          'Modified Time': '2025-02-26T10:41:26.426Z',
          'Created Time': '2025-02-26T12:02:31.142Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3a53fea4-54c4-417d-bcd7-27dd7e5f4a43',
        bbox: null,
      },
    },
    '969e8b70-a29a-4773-aa2f-c2aaee5dc17f': {
      id: '969e8b70-a29a-4773-aa2f-c2aaee5dc17f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3520321818765, 25.26757046597],
              [55.3523121759797, 25.26757046597],
              [55.3523121759797, 25.2678893125594],
              [55.3520321818765, 25.2678893125594],
              [55.3520321818765, 25.26757046597],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4ef33f78-83cd-40b8-b692-02540f7dd6a2',
          'Modified Time': '2025-02-25T18:43:58.325Z',
          'Created Time': '2025-02-25T22:35:38.025Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '969e8b70-a29a-4773-aa2f-c2aaee5dc17f',
        bbox: null,
      },
    },
    '9e2c86c3-d6d8-4aa7-b1b7-2daf39deab7f': {
      id: '9e2c86c3-d6d8-4aa7-b1b7-2daf39deab7f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.388472190992, 25.2357959711409],
              [55.3890031739385, 25.2357959711409],
              [55.3890031739385, 25.2363757834152],
              [55.388472190992, 25.2363757834152],
              [55.388472190992, 25.2357959711409],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95654296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'df82c672-4b87-4ef1-ba83-90ead5ebbbd3',
          'Modified Time': '2025-02-25T21:11:10.183Z',
          'Created Time': '2025-02-26T06:18:16.524Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9e2c86c3-d6d8-4aa7-b1b7-2daf39deab7f',
        bbox: null,
      },
    },
    '3ef63248-4ce2-4260-9b4f-cb09b0a8c704': {
      id: '3ef63248-4ce2-4260-9b4f-cb09b0a8c704',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3735069237831, 25.2432826991042],
              [55.3741969541962, 25.2432826991042],
              [55.3741969541962, 25.2438845583313],
              [55.3735069237831, 25.2438845583313],
              [55.3735069237831, 25.2432826991042],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ace09bf5-ced1-46a8-b5ed-c1c0f474aee6',
          'Modified Time': '2025-02-26T04:23:38.035Z',
          'Created Time': '2025-02-25T20:08:48.877Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3ef63248-4ce2-4260-9b4f-cb09b0a8c704',
        bbox: null,
      },
    },
    '78ba3b4c-3080-4794-8505-7bba48c0d027': {
      id: '78ba3b4c-3080-4794-8505-7bba48c0d027',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.372920771524, 25.2556062674805],
              [55.3736110301097, 25.2556062674805],
              [55.3736110301097, 25.2561992829152],
              [55.372920771524, 25.2561992829152],
              [55.372920771524, 25.2556062674805],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '51604926-3657-4158-b689-5b8cef34bcf5',
          'Modified Time': '2025-02-26T03:29:44.180Z',
          'Created Time': '2025-02-26T01:14:03.218Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '78ba3b4c-3080-4794-8505-7bba48c0d027',
        bbox: null,
      },
    },
    '70833d5a-1d02-48ed-86b3-5aab86ae51a4': {
      id: '70833d5a-1d02-48ed-86b3-5aab86ae51a4',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3541135608812, 25.2663030576886],
              [55.3543934182662, 25.2663030576886],
              [55.3543934182662, 25.2666218384448],
              [55.3541135608812, 25.2666218384448],
              [55.3541135608812, 25.2663030576886],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '511352be-1dee-4153-a52e-2f4a883516e5',
          'Modified Time': '2025-02-26T13:46:37.751Z',
          'Created Time': '2025-02-26T07:00:11.863Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '70833d5a-1d02-48ed-86b3-5aab86ae51a4',
        bbox: null,
      },
    },
    'af954ae7-24aa-4f8b-a5c4-4c9fa791a644': {
      id: 'af954ae7-24aa-4f8b-a5c4-4c9fa791a644',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3954225835855, 25.239240892951],
              [55.3958585648795, 25.239240892951],
              [55.3958585648795, 25.2397967742032],
              [55.3954225835855, 25.2397967742032],
              [55.3954225835855, 25.239240892951],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '38e9f653-1344-4828-af7f-6a9c415845ff',
          'Modified Time': '2025-02-26T04:26:25.139Z',
          'Created Time': '2025-02-25T18:47:32.095Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'af954ae7-24aa-4f8b-a5c4-4c9fa791a644',
        bbox: null,
      },
    },
    '025f8c1f-5f62-496b-a2eb-d121bef23967': {
      id: '025f8c1f-5f62-496b-a2eb-d121bef23967',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.380563518773, 25.2394049718601],
              [55.3811160751495, 25.2394049718601],
              [55.3811160751495, 25.239993147376],
              [55.380563518773, 25.239993147376],
              [55.380563518773, 25.2394049718601],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '257c1327-bbc4-4cee-a9d7-6ed3e062d759',
          'Modified Time': '2025-02-26T02:10:41.562Z',
          'Created Time': '2025-02-26T02:56:02.536Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '025f8c1f-5f62-496b-a2eb-d121bef23967',
        bbox: null,
      },
    },
    'a02ad6f4-b1e8-4354-a3de-99208f698f09': {
      id: 'a02ad6f4-b1e8-4354-a3de-99208f698f09',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3432873422555, 25.2642389280071],
              [55.3439694057678, 25.2642389280071],
              [55.3439694057678, 25.2648324139259],
              [55.3432873422555, 25.2648324139259],
              [55.3432873422555, 25.2642389280071],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0d21cb37-0919-43af-b3ab-7801864bee59',
          'Modified Time': '2025-02-26T10:22:06.130Z',
          'Created Time': '2025-02-26T12:00:46.502Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a02ad6f4-b1e8-4354-a3de-99208f698f09',
        bbox: null,
      },
    },
    '5dc10694-8791-4f5c-98a4-0fdea00ec46f': {
      id: '5dc10694-8791-4f5c-98a4-0fdea00ec46f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3906859428011, 25.2344175058053],
              [55.391374587497, 25.2344175058053],
              [55.391374587497, 25.2349654146616],
              [55.3906859428011, 25.2349654146616],
              [55.3906859428011, 25.2344175058053],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '52a3ad63-2344-44d7-b1b0-433d3ee23cbb',
          'Modified Time': '2025-02-26T11:53:20.838Z',
          'Created Time': '2025-02-25T20:21:45.509Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5dc10694-8791-4f5c-98a4-0fdea00ec46f',
        bbox: null,
      },
    },
    '432163ce-bf37-4c4c-9086-71239c7b863d': {
      id: '432163ce-bf37-4c4c-9086-71239c7b863d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3480347565722, 25.256329842481],
              [55.3483676242488, 25.256329842481],
              [55.3483676242488, 25.2565841541312],
              [55.3480347565722, 25.2565841541312],
              [55.3480347565722, 25.256329842481],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '72021921-919f-4e4f-81e2-fcf618608971',
          'Modified Time': '2025-02-26T00:08:18.193Z',
          'Created Time': '2025-02-25T17:11:39.218Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '432163ce-bf37-4c4c-9086-71239c7b863d',
        bbox: null,
      },
    },
    '428c41e6-83ee-427b-be58-395346bbaddb': {
      id: '428c41e6-83ee-427b-be58-395346bbaddb',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3811297225094, 25.2451593208357],
              [55.3817982081398, 25.2451593208357],
              [55.3817982081398, 25.2456272692182],
              [55.3811297225094, 25.2456272692182],
              [55.3811297225094, 25.2451593208357],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9ce44f24-a4e4-47da-a244-0445399d110c',
          'Modified Time': '2025-02-26T14:06:29.727Z',
          'Created Time': '2025-02-25T16:45:22.560Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '428c41e6-83ee-427b-be58-395346bbaddb',
        bbox: null,
      },
    },
    '7399737e-fad1-4f20-9d6c-d09c37ff326d': {
      id: '7399737e-fad1-4f20-9d6c-d09c37ff326d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.347596329262, 25.2556260259354],
              [55.3481088589901, 25.2556260259354],
              [55.3481088589901, 25.2560631746323],
              [55.347596329262, 25.2560631746323],
              [55.347596329262, 25.2556260259354],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4947051f-c61d-468e-9560-d841944b7c68',
          'Modified Time': '2025-02-25T18:27:08.230Z',
          'Created Time': '2025-02-25T23:29:20.498Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7399737e-fad1-4f20-9d6c-d09c37ff326d',
        bbox: null,
      },
    },
    'b34aea30-579a-46f0-922a-e57593a96d6a': {
      id: 'b34aea30-579a-46f0-922a-e57593a96d6a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3420353579151, 25.2613159546494],
              [55.3423684781046, 25.2613159546494],
              [55.3423684781046, 25.2615702929939],
              [55.3420353579151, 25.2615702929939],
              [55.3420353579151, 25.2613159546494],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b53ec33f-b77a-403a-8cf6-7408dd006225',
          'Modified Time': '2025-02-25T20:18:36.218Z',
          'Created Time': '2025-02-25T23:23:59.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b34aea30-579a-46f0-922a-e57593a96d6a',
        bbox: null,
      },
    },
    'd9f66c4c-a9af-4e6d-8cb2-fc98a1ad2827': {
      id: 'd9f66c4c-a9af-4e6d-8cb2-fc98a1ad2827',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3526355522306, 25.2541435611035],
              [55.3531899863988, 25.2541435611035],
              [55.3531899863988, 25.2547319221541],
              [55.3526355522306, 25.2547319221541],
              [55.3526355522306, 25.2541435611035],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '918f7575-c33a-4570-a126-3a01df241fc7',
          'Modified Time': '2025-02-26T11:24:47.381Z',
          'Created Time': '2025-02-25T17:23:13.770Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd9f66c4c-a9af-4e6d-8cb2-fc98a1ad2827',
        bbox: null,
      },
    },
    '41a12e40-d289-4cec-a315-e5191a755fcd': {
      id: '41a12e40-d289-4cec-a315-e5191a755fcd',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3392811329712, 25.259686287252],
              [55.339794200543, 25.259686287252],
              [55.339794200543, 25.2601952965436],
              [55.3392811329712, 25.2601952965436],
              [55.3392811329712, 25.259686287252],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '54c6cdb8-e11e-45a6-9c50-2739b8f53a70',
          'Modified Time': '2025-02-26T07:22:16.679Z',
          'Created Time': '2025-02-25T16:35:18.922Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '41a12e40-d289-4cec-a315-e5191a755fcd',
        bbox: null,
      },
    },
    '4bc09d6f-33ca-4f9e-84ff-b5fff0aa6313': {
      id: '4bc09d6f-33ca-4f9e-84ff-b5fff0aa6313',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3999740956501, 25.2380235774687],
              [55.4006515578898, 25.2380235774687],
              [55.4006515578898, 25.2386077208499],
              [55.3999740956501, 25.2386077208499],
              [55.3999740956501, 25.2380235774687],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c1b3bf6d-4fe0-40e4-b189-d243a9ae7ce7',
          'Modified Time': '2025-02-25T19:31:41.347Z',
          'Created Time': '2025-02-26T01:58:15.696Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4bc09d6f-33ca-4f9e-84ff-b5fff0aa6313',
        bbox: null,
      },
    },
    '48dcf0ec-b2e9-4973-9654-324d40609da2': {
      id: '48dcf0ec-b2e9-4973-9654-324d40609da2',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3634118926448, 25.2459075047345],
              [55.3639550521938, 25.2459075047345],
              [55.3639550521938, 25.2464871478062],
              [55.3634118926448, 25.2464871478062],
              [55.3634118926448, 25.2459075047345],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4c1dd771-f32c-4246-8615-0058e3430558',
          'Modified Time': '2025-02-26T01:58:00.813Z',
          'Created Time': '2025-02-25T22:44:38.554Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '48dcf0ec-b2e9-4973-9654-324d40609da2',
        bbox: null,
      },
    },
    'e2fe2709-bceb-4108-ab52-70e45808af18': {
      id: 'e2fe2709-bceb-4108-ab52-70e45808af18',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3700376738982, 25.2575655514006],
              [55.3702748689493, 25.2575655514006],
              [55.3702748689493, 25.2577959130681],
              [55.3700376738982, 25.2577959130681],
              [55.3700376738982, 25.2575655514006],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '09f50eba-1818-423d-85c4-57a37aafcbb1',
          'Modified Time': '2025-02-26T14:09:17.834Z',
          'Created Time': '2025-02-25T15:25:06.996Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e2fe2709-bceb-4108-ab52-70e45808af18',
        bbox: null,
      },
    },
    '09434267-15ab-4953-8160-7e17a5bfcb3b': {
      id: '09434267-15ab-4953-8160-7e17a5bfcb3b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3965468222954, 25.2317360466122],
              [55.3969408398411, 25.2317360466122],
              [55.3969408398411, 25.2320241060444],
              [55.3965468222954, 25.2320241060444],
              [55.3965468222954, 25.2317360466122],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6439ff37-b300-4760-b5a7-debe3c68274c',
          'Modified Time': '2025-02-26T14:31:05.007Z',
          'Created Time': '2025-02-26T02:35:28.124Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '09434267-15ab-4953-8160-7e17a5bfcb3b',
        bbox: null,
      },
    },
    '9c2ea151-f2e1-46f2-86e2-0ed8d7bc6c05': {
      id: '9c2ea151-f2e1-46f2-86e2-0ed8d7bc6c05',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3595407940313, 25.2633586969943],
              [55.3598310749379, 25.2633586969943],
              [55.3598310749379, 25.2636771830727],
              [55.3595407940313, 25.2636771830727],
              [55.3595407940313, 25.2633586969943],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9541015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fb31ac40-6eac-4fd5-b1b7-34501a64dc61',
          'Modified Time': '2025-02-26T07:00:34.112Z',
          'Created Time': '2025-02-26T09:47:22.566Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9c2ea151-f2e1-46f2-86e2-0ed8d7bc6c05',
        bbox: null,
      },
    },
    'aa889fac-c859-4faf-9d12-5e3287ebba0c': {
      id: 'aa889fac-c859-4faf-9d12-5e3287ebba0c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3660276456264, 25.2454770049712],
              [55.3666655552233, 25.2454770049712],
              [55.3666655552233, 25.2460356954068],
              [55.3660276456264, 25.2460356954068],
              [55.3660276456264, 25.2454770049712],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9541015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3ca926b1-91e9-42e4-abde-0a21097b1554',
          'Modified Time': '2025-02-25T15:56:52.430Z',
          'Created Time': '2025-02-26T08:06:18.359Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aa889fac-c859-4faf-9d12-5e3287ebba0c',
        bbox: null,
      },
    },
    'f9602b95-63b1-49b4-9cb7-f9cbc6862ca7': {
      id: 'f9602b95-63b1-49b4-9cb7-f9cbc6862ca7',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4004266545644, 25.2386966677438],
              [55.401093581978, 25.2386966677438],
              [55.401093581978, 25.2392811471261],
              [55.4004266545644, 25.2392811471261],
              [55.4004266545644, 25.2386966677438],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a2e701d3-86eb-4aba-b717-8abf30705faf',
          'Modified Time': '2025-02-26T10:26:04.370Z',
          'Created Time': '2025-02-25T22:46:41.673Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f9602b95-63b1-49b4-9cb7-f9cbc6862ca7',
        bbox: null,
      },
    },
    'd932f0c0-9cf9-4458-ad59-5216456c2bdf': {
      id: 'd932f0c0-9cf9-4458-ad59-5216456c2bdf',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3667106639449, 25.25943300359],
              [55.3670112280134, 25.25943300359],
              [55.3670112280134, 25.2597601043413],
              [55.3667106639449, 25.2597601043413],
              [55.3667106639449, 25.25943300359],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '093ccdfd-2c5c-4e15-a4ac-ee908b82c869',
          'Modified Time': '2025-02-25T23:57:28.346Z',
          'Created Time': '2025-02-25T23:26:12.404Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd932f0c0-9cf9-4458-ad59-5216456c2bdf',
        bbox: null,
      },
    },
    '052aa977-58e7-4ead-81bd-36fc587867d6': {
      id: '052aa977-58e7-4ead-81bd-36fc587867d6',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3717771706275, 25.2566436182783],
              [55.3720670025363, 25.2566436182783],
              [55.3720670025363, 25.2569620549123],
              [55.3717771706275, 25.2569620549123],
              [55.3717771706275, 25.2566436182783],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '91d7c147-e294-4cb6-a437-4c9035643d44',
          'Modified Time': '2025-02-25T16:51:12.174Z',
          'Created Time': '2025-02-25T18:14:47.265Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '052aa977-58e7-4ead-81bd-36fc587867d6',
        bbox: null,
      },
    },
    'b4318171-bd9e-4d3c-b254-5d2ca252497b': {
      id: 'b4318171-bd9e-4d3c-b254-5d2ca252497b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3743758813145, 25.2574233389593],
              [55.3747605135283, 25.2574233389593],
              [55.3747605135283, 25.2576759964931],
              [55.3743758813145, 25.2576759964931],
              [55.3743758813145, 25.2574233389593],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '14deb8ae-2e74-431a-8e7b-5ce142e34e45',
          'Modified Time': '2025-02-26T06:11:59.549Z',
          'Created Time': '2025-02-26T07:26:59.981Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b4318171-bd9e-4d3c-b254-5d2ca252497b',
        bbox: null,
      },
    },
    '3ad06ded-d977-4fda-9aa0-32aefe1083e7': {
      id: '3ad06ded-d977-4fda-9aa0-32aefe1083e7',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3399687705674, 25.2591736276816],
              [55.3403336698754, 25.2591736276816],
              [55.3403336698754, 25.2594179791742],
              [55.3399687705674, 25.2594179791742],
              [55.3399687705674, 25.2591736276816],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7cf5d512-1b52-4807-8f8d-0ccb49971f97',
          'Modified Time': '2025-02-26T06:28:32.819Z',
          'Created Time': '2025-02-26T05:00:31.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3ad06ded-d977-4fda-9aa0-32aefe1083e7',
        bbox: null,
      },
    },
    '7005a797-2fe9-4452-a435-dff6b0b127aa': {
      id: '7005a797-2fe9-4452-a435-dff6b0b127aa',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3756669425063, 25.2572778167594],
              [55.3759882915733, 25.2572778167594],
              [55.3759882915733, 25.2575234792586],
              [55.3756669425063, 25.2575234792586],
              [55.3756669425063, 25.2572778167594],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e2f365be-8b71-46fe-8008-f85e41f6ce1f',
          'Modified Time': '2025-02-26T06:02:08.362Z',
          'Created Time': '2025-02-26T03:05:34.619Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7005a797-2fe9-4452-a435-dff6b0b127aa',
        bbox: null,
      },
    },
    'd0687d54-ad19-4281-a561-4b5cc05ff4cd': {
      id: 'd0687d54-ad19-4281-a561-4b5cc05ff4cd',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3558242695822, 25.2654004605785],
              [55.3561041274047, 25.2654004605785],
              [55.3561041274047, 25.2657192919763],
              [55.3558242695822, 25.2657192919763],
              [55.3558242695822, 25.2654004605785],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '76bd0356-964c-4d0b-a345-c40d2893fbaf',
          'Modified Time': '2025-02-26T08:46:28.721Z',
          'Created Time': '2025-02-26T00:21:14.958Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd0687d54-ad19-4281-a561-4b5cc05ff4cd',
        bbox: null,
      },
    },
    'daa5fc4a-a68d-4cf3-8e42-1fd6ac87feb1': {
      id: 'daa5fc4a-a68d-4cf3-8e42-1fd6ac87feb1',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3490124168876, 25.2533958803791],
              [55.3495459890029, 25.2533958803791],
              [55.3495459890029, 25.2539759622347],
              [55.3490124168876, 25.2539759622347],
              [55.3490124168876, 25.2533958803791],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd6aab15-ff75-47be-bacf-e232debf790d',
          'Modified Time': '2025-02-26T12:13:32.432Z',
          'Created Time': '2025-02-25T20:28:45.986Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'daa5fc4a-a68d-4cf3-8e42-1fd6ac87feb1',
        bbox: null,
      },
    },
    'b336b876-8a0e-4d0d-937d-3e23f75e14f8': {
      id: 'b336b876-8a0e-4d0d-937d-3e23f75e14f8',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.35489544063, 25.2538990410795],
              [55.3553970713517, 25.2538990410795],
              [55.3553970713517, 25.2545249703511],
              [55.35489544063, 25.2545249703511],
              [55.35489544063, 25.2538990410795],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd956714a-ed43-4ab0-8e36-e6148aee9d46',
          'Modified Time': '2025-02-26T00:07:24.485Z',
          'Created Time': '2025-02-26T12:27:43.526Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b336b876-8a0e-4d0d-937d-3e23f75e14f8',
        bbox: null,
      },
    },
    'e1514645-040b-4e43-8df1-e9cd2b78852f': {
      id: 'e1514645-040b-4e43-8df1-e9cd2b78852f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3753822648967, 25.2569352796014],
              [55.3756509294161, 25.2569352796014],
              [55.3756509294161, 25.2571736169215],
              [55.3753822648967, 25.2571736169215],
              [55.3753822648967, 25.2569352796014],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'df36658e-2bc9-4dbb-8a29-56f69cbbbbc4',
          'Modified Time': '2025-02-25T16:15:08.885Z',
          'Created Time': '2025-02-25T21:57:34.351Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e1514645-040b-4e43-8df1-e9cd2b78852f',
        bbox: null,
      },
    },
    'df498090-317a-4d74-8906-9f3f87876112': {
      id: 'df498090-317a-4d74-8906-9f3f87876112',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3569647366166, 25.2647681873481],
              [55.3572656709814, 25.2647681873481],
              [55.3572656709814, 25.265086352979],
              [55.3569647366166, 25.265086352979],
              [55.3569647366166, 25.2647681873481],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a0e0e404-250a-47dc-8bee-51da3a8991f9',
          'Modified Time': '2025-02-25T22:59:45.509Z',
          'Created Time': '2025-02-25T17:05:02.468Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'df498090-317a-4d74-8906-9f3f87876112',
        bbox: null,
      },
    },
    'a4491235-dce4-4efb-81f5-d75f830fbff3': {
      id: 'a4491235-dce4-4efb-81f5-d75f830fbff3',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.36485941131, 25.2604608576452],
              [55.3651600469784, 25.2604608576452],
              [55.3651600469784, 25.260778992111],
              [55.36485941131, 25.260778992111],
              [55.36485941131, 25.2604608576452],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9521484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1776e4cd-0fee-4c5d-a4c1-3e91874ff489',
          'Modified Time': '2025-02-26T06:50:51.183Z',
          'Created Time': '2025-02-25T17:43:50.926Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a4491235-dce4-4efb-81f5-d75f830fbff3',
        bbox: null,
      },
    },
    'ed29fca7-10a4-4bdf-9aef-b94e4cd8afef': {
      id: 'ed29fca7-10a4-4bdf-9aef-b94e4cd8afef',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3621684856146, 25.2579765902589],
              [55.3624903065792, 25.2579765902589],
              [55.3624903065792, 25.2582312356583],
              [55.3621684856146, 25.2582312356583],
              [55.3621684856146, 25.2579765902589],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9521484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8b7bbf65-b3c5-4ee7-ae1a-fda0a44f1cff',
          'Modified Time': '2025-02-26T12:28:41.037Z',
          'Created Time': '2025-02-26T11:25:03.745Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ed29fca7-10a4-4bdf-9aef-b94e4cd8afef',
        bbox: null,
      },
    },
    'e0582775-8db1-4118-953b-36cc73db1267': {
      id: 'e0582775-8db1-4118-953b-36cc73db1267',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3583845287562, 25.2533258499447],
              [55.358717004668, 25.2533258499447],
              [55.358717004668, 25.2535801430748],
              [55.3583845287562, 25.2535801430748],
              [55.3583845287562, 25.2533258499447],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9521484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'af929b95-05ed-4d5c-a7fa-a3758ab8adb8',
          'Modified Time': '2025-02-26T00:04:18.051Z',
          'Created Time': '2025-02-26T07:39:05.099Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e0582775-8db1-4118-953b-36cc73db1267',
        bbox: null,
      },
    },
    '91e96022-d93d-444d-af4a-04b10b9d6ecb': {
      id: '91e96022-d93d-444d-af4a-04b10b9d6ecb',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3544884562368, 25.2660911605225],
              [55.3547894342002, 25.2660911605225],
              [55.3547894342002, 25.2664182505835],
              [55.3544884562368, 25.2664182505835],
              [55.3544884562368, 25.2660911605225],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7733e6ee-ebaf-4fc2-901b-00aecde00cd0',
          'Modified Time': '2025-02-26T09:42:52.228Z',
          'Created Time': '2025-02-26T14:09:57.052Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '91e96022-d93d-444d-af4a-04b10b9d6ecb',
        bbox: null,
      },
    },
    '16359a8f-2067-4dd6-ae36-b8cefdb45e50': {
      id: '16359a8f-2067-4dd6-ae36-b8cefdb45e50',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3474957171385, 25.2572050031155],
              [55.3477969178118, 25.2572050031155],
              [55.3477969178118, 25.2575051993924],
              [55.3474957171385, 25.2575051993924],
              [55.3474957171385, 25.2572050031155],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '479ea1c5-54e3-4ab0-b584-b77a618ef2ac',
          'Modified Time': '2025-02-25T19:57:35.044Z',
          'Created Time': '2025-02-26T02:34:01.797Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '16359a8f-2067-4dd6-ae36-b8cefdb45e50',
        bbox: null,
      },
    },
    '6bba42bf-038d-4170-93f3-8e2d9f3cc345': {
      id: '6bba42bf-038d-4170-93f3-8e2d9f3cc345',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4017344666747, 25.2378555931432],
              [55.4024433238292, 25.2378555931432],
              [55.4024433238292, 25.2384387422286],
              [55.4017344666747, 25.2384387422286],
              [55.4017344666747, 25.2378555931432],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6485d0f4-b322-4f6b-8cfd-10fa44573735',
          'Modified Time': '2025-02-26T07:41:24.087Z',
          'Created Time': '2025-02-25T17:12:24.039Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6bba42bf-038d-4170-93f3-8e2d9f3cc345',
        bbox: null,
      },
    },
    'cd71def1-b475-4227-bfca-284f5da6db44': {
      id: 'cd71def1-b475-4227-bfca-284f5da6db44',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3449695829082, 25.2584626026327],
              [55.3452603614709, 25.2584626026327],
              [55.3452603614709, 25.2590055110576],
              [55.3449695829082, 25.2590055110576],
              [55.3449695829082, 25.2584626026327],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e03f0ba8-3b15-48a3-b03f-ca96ad91a61a',
          'Modified Time': '2025-02-26T06:56:25.870Z',
          'Created Time': '2025-02-25T18:59:07.090Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cd71def1-b475-4227-bfca-284f5da6db44',
        bbox: null,
      },
    },
    '7fdda0eb-f739-48c2-93cd-3bd46b0a8c73': {
      id: '7fdda0eb-f739-48c2-93cd-3bd46b0a8c73',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3621688607028, 25.2618335704613],
              [55.3624273685061, 25.2618335704613],
              [55.3624273685061, 25.2623594475719],
              [55.3621688607028, 25.2623594475719],
              [55.3621688607028, 25.2618335704613],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.951171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e0fdc00f-5864-465b-a8bd-5b453af93664',
          'Modified Time': '2025-02-25T20:10:56.506Z',
          'Created Time': '2025-02-26T06:35:44.115Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7fdda0eb-f739-48c2-93cd-3bd46b0a8c73',
        bbox: null,
      },
    },
    '26b54553-461b-43b7-978b-9097fdcdd324': {
      id: '26b54553-461b-43b7-978b-9097fdcdd324',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.372416998463, 25.2462167116383],
              [55.3727699961704, 25.2462167116383],
              [55.3727699961704, 25.2464882460255],
              [55.372416998463, 25.2464882460255],
              [55.372416998463, 25.2462167116383],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.951171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'eb7ca64a-9ca2-4a02-ac30-459260e09d81',
          'Modified Time': '2025-02-25T16:25:06.339Z',
          'Created Time': '2025-02-26T07:27:03.420Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '26b54553-461b-43b7-978b-9097fdcdd324',
        bbox: null,
      },
    },
    'f5d57916-306c-48c1-8d97-e71a80be9def': {
      id: 'f5d57916-306c-48c1-8d97-e71a80be9def',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3691467371047, 25.2580283407929],
              [55.3694366649515, 25.2580283407929],
              [55.3694366649515, 25.258346787944],
              [55.3691467371047, 25.258346787944],
              [55.3691467371047, 25.2580283407929],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95068359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9fe86b55-2c96-4fba-a8b3-e7e81647715e',
          'Modified Time': '2025-02-25T19:12:18.547Z',
          'Created Time': '2025-02-25T17:26:33.632Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f5d57916-306c-48c1-8d97-e71a80be9def',
        bbox: null,
      },
    },
    'a3e60e03-8b6d-4cfa-a526-9f79fce3a025': {
      id: 'a3e60e03-8b6d-4cfa-a526-9f79fce3a025',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3422927442449, 25.2669647843738],
              [55.3425836211626, 25.2669647843738],
              [55.3425836211626, 25.2672833151196],
              [55.3422927442449, 25.2672833151196],
              [55.3422927442449, 25.2669647843738],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95068359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '01da893f-98b0-439e-a567-fbe30ec2a0c4',
          'Modified Time': '2025-02-26T04:57:38.380Z',
          'Created Time': '2025-02-26T03:26:59.495Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a3e60e03-8b6d-4cfa-a526-9f79fce3a025',
        bbox: null,
      },
    },
    '9f9eeec6-db36-48cc-aaa4-236dadd0e57c': {
      id: '9f9eeec6-db36-48cc-aaa4-236dadd0e57c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3473269784708, 25.2551816729212],
              [55.3476809943537, 25.2551816729212],
              [55.3476809943537, 25.2554263365844],
              [55.3473269784708, 25.2554263365844],
              [55.3473269784708, 25.2551816729212],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95068359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ca022188-2d57-492f-8461-b54f6f0d44bb',
          'Modified Time': '2025-02-25T19:04:13.459Z',
          'Created Time': '2025-02-26T02:17:52.031Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9f9eeec6-db36-48cc-aaa4-236dadd0e57c',
        bbox: null,
      },
    },
    'c866ae6a-a17a-4a90-a384-1e1f12eed262': {
      id: 'c866ae6a-a17a-4a90-a384-1e1f12eed262',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3148248274562, 25.2834392896937],
              [55.3152015105802, 25.2834392896937],
              [55.3152015105802, 25.2836924041632],
              [55.3148248274562, 25.2836924041632],
              [55.3148248274562, 25.2834392896937],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9501953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '180c9d9d-357b-4018-81e3-656fe4783b73',
          'Modified Time': '2025-02-26T12:08:36.181Z',
          'Created Time': '2025-02-26T11:22:16.778Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c866ae6a-a17a-4a90-a384-1e1f12eed262',
        bbox: null,
      },
    },
    'aec3fb69-b1d8-4311-a940-3af980866294': {
      id: 'aec3fb69-b1d8-4311-a940-3af980866294',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3746184202989, 25.2577798697799],
              [55.3749714288171, 25.2577798697799],
              [55.3749714288171, 25.2580245458268],
              [55.3746184202989, 25.2580245458268],
              [55.3746184202989, 25.2577798697799],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9501953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2dad31a3-c925-4094-a8fa-c3083b724341',
          'Modified Time': '2025-02-25T17:06:43.449Z',
          'Created Time': '2025-02-25T16:45:58.088Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aec3fb69-b1d8-4311-a940-3af980866294',
        bbox: null,
      },
    },
    'e98008ac-1672-4635-990a-463ca766ecb7': {
      id: 'e98008ac-1672-4635-990a-463ca766ecb7',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3429344114322, 25.2598617227177],
              [55.3432252116993, 25.2598617227177],
              [55.3432252116993, 25.2601532932619],
              [55.3429344114322, 25.2601532932619],
              [55.3429344114322, 25.2598617227177],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94970703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '43c4eb6c-5be9-4dd6-8712-2f72eaf7243e',
          'Modified Time': '2025-02-26T01:24:04.995Z',
          'Created Time': '2025-02-26T04:29:47.413Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e98008ac-1672-4635-990a-463ca766ecb7',
        bbox: null,
      },
    },
    '92c379dc-be87-4400-95c6-dbf4c130aa88': {
      id: '92c379dc-be87-4400-95c6-dbf4c130aa88',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3536013779243, 25.266601712509],
              [55.3539023569336, 25.266601712509],
              [55.3539023569336, 25.2669467537747],
              [55.3536013779243, 25.2669467537747],
              [55.3536013779243, 25.266601712509],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94970703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c87b6eb4-1967-494d-897f-3c46ca7c1a0f',
          'Modified Time': '2025-02-26T00:24:06.865Z',
          'Created Time': '2025-02-26T04:35:30.313Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '92c379dc-be87-4400-95c6-dbf4c130aa88',
        bbox: null,
      },
    },
    'f47269aa-6e24-4666-b261-b9decf002af2': {
      id: 'f47269aa-6e24-4666-b261-b9decf002af2',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3722890931192, 25.2419741684296],
              [55.3728212234672, 25.2419741684296],
              [55.3728212234672, 25.242257974449],
              [55.3722890931192, 25.242257974449],
              [55.3722890931192, 25.2419741684296],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'eeb6858f-2397-415e-baa6-13bfcc1768af',
          'Modified Time': '2025-02-26T06:15:53.619Z',
          'Created Time': '2025-02-26T01:31:04.717Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f47269aa-6e24-4666-b261-b9decf002af2',
        bbox: null,
      },
    },
    '92d2eb68-c84d-41e9-a982-7adf2b976839': {
      id: '92d2eb68-c84d-41e9-a982-7adf2b976839',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3424111005509, 25.2597962001998],
              [55.342712489577, 25.2597962001998],
              [55.342712489577, 25.2600874381769],
              [55.3424111005509, 25.2600874381769],
              [55.3424111005509, 25.2597962001998],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0c3b5801-0d48-4ee0-8210-0178e33b0d21',
          'Modified Time': '2025-02-26T12:58:55.625Z',
          'Created Time': '2025-02-25T16:50:18.207Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '92d2eb68-c84d-41e9-a982-7adf2b976839',
        bbox: null,
      },
    },
    'a6122658-6efa-4381-9513-da30f7251cc6': {
      id: 'a6122658-6efa-4381-9513-da30f7251cc6',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3397775500315, 25.2619140120987],
              [55.3401213279424, 25.2619140120987],
              [55.3401213279424, 25.2621590438566],
              [55.3397775500315, 25.2621590438566],
              [55.3397775500315, 25.2619140120987],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9e439f19-3815-4c82-bdd4-23e7fff5e0a7',
          'Modified Time': '2025-02-26T05:02:35.637Z',
          'Created Time': '2025-02-25T23:27:38.964Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a6122658-6efa-4381-9513-da30f7251cc6',
        bbox: null,
      },
    },
    'f501d1f5-046e-4c74-9736-2f8afabb3b6b': {
      id: 'f501d1f5-046e-4c74-9736-2f8afabb3b6b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3493767264722, 25.2550968514622],
              [55.3496884189022, 25.2550968514622],
              [55.3496884189022, 25.2554326073473],
              [55.3493767264722, 25.2554326073473],
              [55.3493767264722, 25.2550968514622],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7f8d6189-3fe4-40c0-a91c-55fb15222466',
          'Modified Time': '2025-02-25T16:36:24.454Z',
          'Created Time': '2025-02-26T14:40:34.975Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f501d1f5-046e-4c74-9736-2f8afabb3b6b',
        bbox: null,
      },
    },
    '7f2a29ca-8648-42d0-9e79-59f19d6ac435': {
      id: '7f2a29ca-8648-42d0-9e79-59f19d6ac435',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3679340577518, 25.2587575878424],
              [55.3682240376662, 25.2587575878424],
              [55.3682240376662, 25.2590401457381],
              [55.3679340577518, 25.2590401457381],
              [55.3679340577518, 25.2587575878424],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6c370d77-becc-48b9-ac79-c414b3eac73b',
          'Modified Time': '2025-02-25T17:42:52.794Z',
          'Created Time': '2025-02-26T01:58:04.476Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7f2a29ca-8648-42d0-9e79-59f19d6ac435',
        bbox: null,
      },
    },
    'dfa839a5-90cf-4737-b9cc-a8a3aa9f8519': {
      id: 'dfa839a5-90cf-4737-b9cc-a8a3aa9f8519',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3673223856587, 25.2590973728071],
              [55.3676123808249, 25.2590973728071],
              [55.3676123808249, 25.2594158276666],
              [55.3673223856587, 25.2594158276666],
              [55.3673223856587, 25.2590973728071],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '590493f0-95d1-4445-8dda-7c291891e418',
          'Modified Time': '2025-02-26T05:56:02.694Z',
          'Created Time': '2025-02-25T18:57:19.837Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dfa839a5-90cf-4737-b9cc-a8a3aa9f8519',
        bbox: null,
      },
    },
    '6691f26f-270d-4eff-adcc-49b1611d6351': {
      id: '6691f26f-270d-4eff-adcc-49b1611d6351',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3514088124262, 25.2679058217033],
              [55.351699393575, 25.2679058217033],
              [55.351699393575, 25.2682243399453],
              [55.3514088124262, 25.2682243399453],
              [55.3514088124262, 25.2679058217033],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9482421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b7caf64d-b62e-46f2-9bc1-12228605d3a6',
          'Modified Time': '2025-02-26T14:27:33.220Z',
          'Created Time': '2025-02-25T18:10:22.948Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6691f26f-270d-4eff-adcc-49b1611d6351',
        bbox: null,
      },
    },
    'b38d4c87-0e1b-431b-9ffb-a63c4363842c': {
      id: 'b38d4c87-0e1b-431b-9ffb-a63c4363842c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3601424672397, 25.2630192138797],
              [55.3604538363442, 25.2630192138797],
              [55.3604538363442, 25.2633370364601],
              [55.3601424672397, 25.2633370364601],
              [55.3601424672397, 25.2630192138797],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9482421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '55e1b424-fc98-4ee7-9d77-b411ac133798',
          'Modified Time': '2025-02-26T02:01:14.284Z',
          'Created Time': '2025-02-25T21:00:07.083Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b38d4c87-0e1b-431b-9ffb-a63c4363842c',
        bbox: null,
      },
    },
    '767400ba-4633-4898-8e11-db07a3641b1d': {
      id: '767400ba-4633-4898-8e11-db07a3641b1d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3436684491291, 25.262591691607],
              [55.3439698241759, 25.262591691607],
              [55.3439698241759, 25.2629188471047],
              [55.3436684491291, 25.2629188471047],
              [55.3436684491291, 25.262591691607],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9482421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '41e39e24-72e6-4ea6-bc30-3784ff452b4d',
          'Modified Time': '2025-02-26T02:10:13.752Z',
          'Created Time': '2025-02-26T03:28:15.837Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '767400ba-4633-4898-8e11-db07a3641b1d',
        bbox: null,
      },
    },
    'bf466764-91bf-4d0d-bfe6-b53e368f08bb': {
      id: 'bf466764-91bf-4d0d-bfe6-b53e368f08bb',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.345518586141, 25.2615723328692],
              [55.345809319244, 25.2615723328692],
              [55.345809319244, 25.2618908366051],
              [55.345518586141, 25.2618908366051],
              [55.345518586141, 25.2615723328692],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ea111f71-f7bc-4f84-b873-d5208fb96912',
          'Modified Time': '2025-02-26T09:49:12.922Z',
          'Created Time': '2025-02-25T23:13:30.287Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bf466764-91bf-4d0d-bfe6-b53e368f08bb',
        bbox: null,
      },
    },
    'eb7f1e68-222f-4f27-bf82-878516231c69': {
      id: 'eb7f1e68-222f-4f27-bf82-878516231c69',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.365550387385, 25.2601212285756],
              [55.3657771517674, 25.2601212285756],
              [55.3657771517674, 25.2604416731654],
              [55.365550387385, 25.2604416731654],
              [55.365550387385, 25.2601212285756],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '81618e6a-54d0-455f-a640-26969ee805b1',
          'Modified Time': '2025-02-25T18:00:59.864Z',
          'Created Time': '2025-02-25T20:35:00.705Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eb7f1e68-222f-4f27-bf82-878516231c69',
        bbox: null,
      },
    },
    '5b062927-6f2f-4d73-9700-fec4f1447d9f': {
      id: '5b062927-6f2f-4d73-9700-fec4f1447d9f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3526607879612, 25.2672583779687],
              [55.352951321096, 25.2672583779687],
              [55.352951321096, 25.2675589403818],
              [55.3526607879612, 25.2675589403818],
              [55.3526607879612, 25.2672583779687],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9d1f984c-a291-4501-a181-977013ff5a85',
          'Modified Time': '2025-02-25T20:24:09.655Z',
          'Created Time': '2025-02-25T19:31:55.322Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5b062927-6f2f-4d73-9700-fec4f1447d9f',
        bbox: null,
      },
    },
    '952fcd90-7a7b-4c47-b7ee-977269c06e70': {
      id: '952fcd90-7a7b-4c47-b7ee-977269c06e70',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3722889866408, 25.2416729102725],
              [55.3728000389795, 25.2416729102725],
              [55.3728000389795, 25.2419484058374],
              [55.3722889866408, 25.2419484058374],
              [55.3722889866408, 25.2416729102725],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8cfbbc96-d749-4853-987b-fff9a68300a7',
          'Modified Time': '2025-02-26T01:25:22.627Z',
          'Created Time': '2025-02-26T03:27:05.677Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '952fcd90-7a7b-4c47-b7ee-977269c06e70',
        bbox: null,
      },
    },
    '76996b2b-8016-4208-b92b-bb6447da181f': {
      id: '76996b2b-8016-4208-b92b-bb6447da181f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3414116727091, 25.2607480488964],
              [55.3417236777201, 25.2607480488964],
              [55.3417236777201, 25.2610479363466],
              [55.3414116727091, 25.2610479363466],
              [55.3414116727091, 25.2607480488964],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '04826152-0492-47e4-ac96-760e9b15a405',
          'Modified Time': '2025-02-25T23:23:05.308Z',
          'Created Time': '2025-02-26T12:17:24.579Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '76996b2b-8016-4208-b92b-bb6447da181f',
        bbox: null,
      },
    },
    '654d9914-3b57-4369-8f0a-d6830bebf4ae': {
      id: '654d9914-3b57-4369-8f0a-d6830bebf4ae',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3698071577111, 25.2430271420959],
              [55.3703604292389, 25.2430271420959],
              [55.3703604292389, 25.2436153821373],
              [55.3698071577111, 25.2436153821373],
              [55.3698071577111, 25.2430271420959],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94677734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '491ed934-7ffe-4e90-9fea-b8c1291d5768',
          'Modified Time': '2025-02-25T17:39:31.490Z',
          'Created Time': '2025-02-26T04:46:20.856Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '654d9914-3b57-4369-8f0a-d6830bebf4ae',
        bbox: null,
      },
    },
    '80c41bae-4fcc-49b0-b482-a291b23227e7': {
      id: '80c41bae-4fcc-49b0-b482-a291b23227e7',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3827418324637, 25.2370280791976],
              [55.383410093773, 25.2370280791976],
              [55.383410093773, 25.23749594448],
              [55.3827418324637, 25.23749594448],
              [55.3827418324637, 25.2370280791976],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94677734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c91af078-ad5d-4ced-9597-b9c460bd4c21',
          'Modified Time': '2025-02-25T17:43:51.397Z',
          'Created Time': '2025-02-25T19:08:33.072Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '80c41bae-4fcc-49b0-b482-a291b23227e7',
        bbox: null,
      },
    },
    '9f5f7695-c486-4f7c-b062-ec4c8e9a6969': {
      id: '9f5f7695-c486-4f7c-b062-ec4c8e9a6969',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3480557937416, 25.2569322473678],
              [55.3483992362409, 25.2569322473678],
              [55.3483992362409, 25.2572221340812],
              [55.3480557937416, 25.2572221340812],
              [55.3480557937416, 25.2569322473678],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'f9b4e4de-12e9-4fa7-bc99-65dc0895a3de',
          'Modified Time': '2025-02-25T18:33:04.526Z',
          'Created Time': '2025-02-26T01:54:40.533Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9f5f7695-c486-4f7c-b062-ec4c8e9a6969',
        bbox: null,
      },
    },
    'b3c2ac5a-a671-4d89-96bc-df456e27cd86': {
      id: 'b3c2ac5a-a671-4d89-96bc-df456e27cd86',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3707440761198, 25.2572172712625],
              [55.3710444895422, 25.2572172712625],
              [55.3710444895422, 25.2575353818982],
              [55.3707440761198, 25.2575353818982],
              [55.3707440761198, 25.2572172712625],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ff61aa25-2809-40e6-bf88-4b50f0fc9fb7',
          'Modified Time': '2025-02-26T07:12:15.397Z',
          'Created Time': '2025-02-26T03:22:58.381Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b3c2ac5a-a671-4d89-96bc-df456e27cd86',
        bbox: null,
      },
    },
    '337d1258-e42c-475c-a6a2-1c20a6c9a359': {
      id: '337d1258-e42c-475c-a6a2-1c20a6c9a359',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3439970611927, 25.2589215332092],
              [55.3442983993166, 25.2589215332092],
              [55.3442983993166, 25.2592486725736],
              [55.3439970611927, 25.2592486725736],
              [55.3439970611927, 25.2589215332092],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94384765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e358563e-3ec7-4043-9904-61836b6344be',
          'Modified Time': '2025-02-26T12:37:22.681Z',
          'Created Time': '2025-02-25T19:12:43.630Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '337d1258-e42c-475c-a6a2-1c20a6c9a359',
        bbox: null,
      },
    },
    'c10b7c02-a254-4e6d-b842-41428ae83c4f': {
      id: 'c10b7c02-a254-4e6d-b842-41428ae83c4f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3544888307351, 25.255487179945],
              [55.3548109256919, 25.255487179945],
              [55.3548109256919, 25.2557417985331],
              [55.3544888307351, 25.2557417985331],
              [55.3544888307351, 25.255487179945],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94384765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3f976111-fa5e-4a10-ae10-6feadd3e1538',
          'Modified Time': '2025-02-26T08:07:03.779Z',
          'Created Time': '2025-02-25T23:48:14.616Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c10b7c02-a254-4e6d-b842-41428ae83c4f',
        bbox: null,
      },
    },
    'fec7421f-4e0b-4517-a97b-9f57d018bc07': {
      id: 'fec7421f-4e0b-4517-a97b-9f57d018bc07',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3437115356896, 25.2594242193141],
              [55.3440128762618, 25.2594242193141],
              [55.3440128762618, 25.2597064772307],
              [55.3437115356896, 25.2597064772307],
              [55.3437115356896, 25.2594242193141],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.943359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '559fd276-dfc3-4271-8c7f-0937c28ade9b',
          'Modified Time': '2025-02-25T20:27:46.371Z',
          'Created Time': '2025-02-26T02:25:21.616Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'fec7421f-4e0b-4517-a97b-9f57d018bc07',
        bbox: null,
      },
    },
    '9e545541-6ea7-41c8-993e-2da7333e3fa3': {
      id: '9e545541-6ea7-41c8-993e-2da7333e3fa3',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3455774084064, 25.2583607331026],
              [55.3460160660045, 25.2583607331026],
              [55.3460160660045, 25.2586117271694],
              [55.3455774084064, 25.2586117271694],
              [55.3455774084064, 25.2583607331026],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.943359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '409dc384-9ed7-4db7-9671-d4d866bb9e52',
          'Modified Time': '2025-02-26T06:50:00.490Z',
          'Created Time': '2025-02-25T16:58:10.631Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9e545541-6ea7-41c8-993e-2da7333e3fa3',
        bbox: null,
      },
    },
    '4d7dfb60-eb23-4d69-91df-d809b0e29383': {
      id: '4d7dfb60-eb23-4d69-91df-d809b0e29383',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3555713485531, 25.2525665594065],
              [55.3562630519872, 25.2525665594065],
              [55.3562630519872, 25.2529082488696],
              [55.3555713485531, 25.2529082488696],
              [55.3555713485531, 25.2525665594065],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94287109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '353cb720-0ba4-42c6-874f-5a3b59ee6bfa',
          'Modified Time': '2025-02-26T11:00:05.996Z',
          'Created Time': '2025-02-26T03:59:20.317Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4d7dfb60-eb23-4d69-91df-d809b0e29383',
        bbox: null,
      },
    },
    'a427f497-fa3a-4e56-9a35-2374c256e36e': {
      id: 'a427f497-fa3a-4e56-9a35-2374c256e36e',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3445361424766, 25.2590189703708],
              [55.344826886888, 25.2590189703708],
              [55.344826886888, 25.2593195119393],
              [55.3445361424766, 25.2593195119393],
              [55.3445361424766, 25.2590189703708],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9423828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7302a7cc-8e8a-4e22-9beb-0df376cb9761',
          'Modified Time': '2025-02-26T01:57:59.781Z',
          'Created Time': '2025-02-26T04:00:52.163Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a427f497-fa3a-4e56-9a35-2374c256e36e',
        bbox: null,
      },
    },
    '388d334d-140c-403d-b3e0-94ebc76281a5': {
      id: '388d334d-140c-403d-b3e0-94ebc76281a5',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3401422235743, 25.262468954464],
              [55.3404965669779, 25.262468954464],
              [55.3404965669779, 25.2627136560283],
              [55.3401422235743, 25.2627136560283],
              [55.3401422235743, 25.262468954464],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94091796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '65fa7b95-7f9f-4053-a135-d7816bbf288c',
          'Modified Time': '2025-02-26T06:46:32.057Z',
          'Created Time': '2025-02-25T22:41:34.333Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '388d334d-140c-403d-b3e0-94ebc76281a5',
        bbox: null,
      },
    },
    '11d24104-bd22-4c2e-be58-b852af1b328c': {
      id: '11d24104-bd22-4c2e-be58-b852af1b328c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3819106015753, 25.2484959148821],
              [55.3822000512773, 25.2484959148821],
              [55.3822000512773, 25.248805326397],
              [55.3819106015753, 25.248805326397],
              [55.3819106015753, 25.2484959148821],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94091796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'aac24f49-b24c-4677-bbc2-5918c5e3a445',
          'Modified Time': '2025-02-25T18:20:19.993Z',
          'Created Time': '2025-02-25T20:52:56.240Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '11d24104-bd22-4c2e-be58-b852af1b328c',
        bbox: null,
      },
    },
    'c05f2b9c-3ddc-4fcc-a815-0587b1c2023a': {
      id: 'c05f2b9c-3ddc-4fcc-a815-0587b1c2023a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3833987991355, 25.2351927446354],
              [55.3840985693091, 25.2351927446354],
              [55.3840985693091, 25.2356595943547],
              [55.3833987991355, 25.2356595943547],
              [55.3833987991355, 25.2351927446354],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9404296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2dd8687f-415c-4c3c-a593-46d2a1574bf8',
          'Modified Time': '2025-02-26T12:20:17.615Z',
          'Created Time': '2025-02-25T19:36:03.894Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c05f2b9c-3ddc-4fcc-a815-0587b1c2023a',
        bbox: null,
      },
    },
    'ace81492-c715-4447-96ce-98d34c9d8153': {
      id: 'ace81492-c715-4447-96ce-98d34c9d8153',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3531842411778, 25.2668322271185],
              [55.3535274603288, 25.2668322271185],
              [55.3535274603288, 25.2671490091476],
              [55.3531842411778, 25.2671490091476],
              [55.3531842411778, 25.2668322271185],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9404296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd0dc23d3-2696-4ee4-b94c-662758d8018a',
          'Modified Time': '2025-02-26T06:20:18.259Z',
          'Created Time': '2025-02-26T03:26:20.124Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ace81492-c715-4447-96ce-98d34c9d8153',
        bbox: null,
      },
    },
    '412a9d2c-d7fd-4dc2-b2a1-9389b1fc396d': {
      id: '412a9d2c-d7fd-4dc2-b2a1-9389b1fc396d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3712360113376, 25.2448166214366],
              [55.3719052451323, 25.2448166214366],
              [55.3719052451323, 25.2451320243283],
              [55.3712360113376, 25.2451320243283],
              [55.3712360113376, 25.2448166214366],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9384765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4b48bdc1-3442-4026-bae8-fbebcfacd6fd',
          'Modified Time': '2025-02-26T08:31:14.435Z',
          'Created Time': '2025-02-25T23:51:18.400Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '412a9d2c-d7fd-4dc2-b2a1-9389b1fc396d',
        bbox: null,
      },
    },
    'e86dce3b-0b50-4b41-983e-99d766c78da9': {
      id: 'e86dce3b-0b50-4b41-983e-99d766c78da9',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3712464434845, 25.2445346340719],
              [55.3719262330982, 25.2445346340719],
              [55.3719262330982, 25.2447868899106],
              [55.3712464434845, 25.2447868899106],
              [55.3712464434845, 25.2445346340719],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93798828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ed0d48fc-5218-42d5-b23e-f735292b02a8',
          'Modified Time': '2025-02-26T08:34:50.133Z',
          'Created Time': '2025-02-25T15:35:09.394Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e86dce3b-0b50-4b41-983e-99d766c78da9',
        bbox: null,
      },
    },
    '47a41f9f-c113-4a6c-8eea-78d046c15329': {
      id: '47a41f9f-c113-4a6c-8eea-78d046c15329',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3785274969704, 25.2561257558183],
              [55.379027791229, 25.2561257558183],
              [55.379027791229, 25.25650039259],
              [55.3785274969704, 25.25650039259],
              [55.3785274969704, 25.2561257558183],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e0a5744f-4dbc-4bae-9aca-a8e6fb89326e',
          'Modified Time': '2025-02-25T19:43:50.146Z',
          'Created Time': '2025-02-25T20:09:59.105Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '47a41f9f-c113-4a6c-8eea-78d046c15329',
        bbox: null,
      },
    },
    'eeb7f26c-1cbd-4018-b08e-1b948c202f89': {
      id: 'eeb7f26c-1cbd-4018-b08e-1b948c202f89',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3625434632229, 25.2617729339831],
              [55.3632135306917, 25.2617729339831],
              [55.3632135306917, 25.2619718206003],
              [55.3625434632229, 25.2619718206003],
              [55.3625434632229, 25.2617729339831],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9365234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fac3be81-2f0b-44f0-af5d-4e779ef38a78',
          'Modified Time': '2025-02-25T21:15:57.023Z',
          'Created Time': '2025-02-25T17:17:23.695Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eeb7f26c-1cbd-4018-b08e-1b948c202f89',
        bbox: null,
      },
    },
    'b5f1a027-bdb9-4fc8-8001-d9b33f6c5e4b': {
      id: 'b5f1a027-bdb9-4fc8-8001-d9b33f6c5e4b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3941184774717, 25.2395178913218],
              [55.3943970012976, 25.2395178913218],
              [55.3943970012976, 25.2397647823794],
              [55.3941184774717, 25.2397647823794],
              [55.3941184774717, 25.2395178913218],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93603515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd093ce60-2f0d-4ab1-86c2-f8004874ea52',
          'Modified Time': '2025-02-25T21:02:54.720Z',
          'Created Time': '2025-02-26T00:35:31.687Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b5f1a027-bdb9-4fc8-8001-d9b33f6c5e4b',
        bbox: null,
      },
    },
    '75e9bb32-76a2-4434-9f08-157a1f0f4e03': {
      id: '75e9bb32-76a2-4434-9f08-157a1f0f4e03',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3625645482332, 25.2613739112704],
              [55.3632240412885, 25.2613739112704],
              [55.3632240412885, 25.2617436365344],
              [55.3625645482332, 25.2617436365344],
              [55.3625645482332, 25.2613739112704],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93603515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '986a0205-37c7-41a8-b6d2-14012393a6b0',
          'Modified Time': '2025-02-26T04:12:05.469Z',
          'Created Time': '2025-02-26T00:51:01.535Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '75e9bb32-76a2-4434-9f08-157a1f0f4e03',
        bbox: null,
      },
    },
    'e19df4fe-65ca-4abf-ac0f-e242e20de698': {
      id: 'e19df4fe-65ca-4abf-ac0f-e242e20de698',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3452708146443, 25.2584828924584],
              [55.3454770277045, 25.2584828924584],
              [55.3454770277045, 25.2590194860569],
              [55.3452708146443, 25.2590194860569],
              [55.3452708146443, 25.2584828924584],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93505859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fd4c9358-6c2b-485a-adcf-66ada46c4a40',
          'Modified Time': '2025-02-25T17:49:46.979Z',
          'Created Time': '2025-02-26T00:02:49.057Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e19df4fe-65ca-4abf-ac0f-e242e20de698',
        bbox: null,
      },
    },
    '1604c841-7211-499a-aa69-63b32a2fa7ad': {
      id: '1604c841-7211-499a-aa69-63b32a2fa7ad',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3725993977971, 25.256578847149],
              [55.3727205942859, 25.256578847149],
              [55.3727205942859, 25.2566961856169],
              [55.3725993977971, 25.2566961856169],
              [55.3725993977971, 25.256578847149],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93408203125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd3d7c0de-9c0c-4253-ae6c-8685e6367cda',
          'Modified Time': '2025-02-26T05:53:52.750Z',
          'Created Time': '2025-02-25T20:25:51.566Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1604c841-7211-499a-aa69-63b32a2fa7ad',
        bbox: null,
      },
    },
    '20bdfccf-25a5-45e4-8883-26947cfb7691': {
      id: '20bdfccf-25a5-45e4-8883-26947cfb7691',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3389942867767, 25.2638938174676],
              [55.3392746132769, 25.2638938174676],
              [55.3392746132769, 25.2640331264464],
              [55.3389942867767, 25.2640331264464],
              [55.3389942867767, 25.2638938174676],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '13c23dae-4b7f-41ff-94af-5eed592894f3',
          'Modified Time': '2025-02-26T02:45:11.932Z',
          'Created Time': '2025-02-26T03:33:22.647Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '20bdfccf-25a5-45e4-8883-26947cfb7691',
        bbox: null,
      },
    },
    '44284456-c6b9-4cc3-b0f6-5fe4cf0cde67': {
      id: '44284456-c6b9-4cc3-b0f6-5fe4cf0cde67',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.378474907902, 25.2565250165203],
              [55.3790279558364, 25.2565250165203],
              [55.3790279558364, 25.2567275365759],
              [55.378474907902, 25.2567275365759],
              [55.378474907902, 25.2565250165203],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8411af6b-6083-41ba-bd6c-fdc2dea1a1bf',
          'Modified Time': '2025-02-26T02:45:53.230Z',
          'Created Time': '2025-02-26T12:41:42.681Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '44284456-c6b9-4cc3-b0f6-5fe4cf0cde67',
        bbox: null,
      },
    },
    'e60b75ff-4349-4bf1-a7aa-2984e9098a8e': {
      id: 'e60b75ff-4349-4bf1-a7aa-2984e9098a8e',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3492337139852, 25.2580843785013],
              [55.3499257717881, 25.2580843785013],
              [55.3499257717881, 25.2584530580158],
              [55.3492337139852, 25.2584530580158],
              [55.3492337139852, 25.2580843785013],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '941d11c0-f814-4c08-8021-5f3a9e12c030',
          'Modified Time': '2025-02-26T07:51:38.445Z',
          'Created Time': '2025-02-26T13:01:37.375Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e60b75ff-4349-4bf1-a7aa-2984e9098a8e',
        bbox: null,
      },
    },
    'e3c8f72e-cd47-4247-a0de-7f10065528d1': {
      id: 'e3c8f72e-cd47-4247-a0de-7f10065528d1',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3430013010792, 25.2665526391689],
              [55.3433238700362, 25.2665526391689],
              [55.3433238700362, 25.2668701714059],
              [55.3430013010792, 25.2668701714059],
              [55.3430013010792, 25.2665526391689],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93310546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '981658bc-2ec9-40f1-a4dc-ba3f3e41d9e8',
          'Modified Time': '2025-02-26T08:53:26.320Z',
          'Created Time': '2025-02-26T09:11:34.144Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e3c8f72e-cd47-4247-a0de-7f10065528d1',
        bbox: null,
      },
    },
    '90317da6-4a27-493b-9e2d-b4b53b7067ca': {
      id: '90317da6-4a27-493b-9e2d-b4b53b7067ca',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3555766201424, 25.2529382129811],
              [55.356236638816, 25.2529382129811],
              [55.356236638816, 25.2531552438471],
              [55.3555766201424, 25.2531552438471],
              [55.3555766201424, 25.2529382129811],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9326171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '02453f30-0aa8-4578-b916-7de78338b712',
          'Modified Time': '2025-02-26T05:39:13.197Z',
          'Created Time': '2025-02-25T16:16:20.654Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '90317da6-4a27-493b-9e2d-b4b53b7067ca',
        bbox: null,
      },
    },
    '9e937ccd-68ee-48fc-a581-61571ced8b9d': {
      id: '9e937ccd-68ee-48fc-a581-61571ced8b9d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3698268842522, 25.2581123200979],
              [55.3699692099614, 25.2581123200979],
              [55.3699692099614, 25.2582379755751],
              [55.3698268842522, 25.2582379755751],
              [55.3698268842522, 25.2581123200979],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93212890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c7faffc8-d5dc-4285-a1aa-2d6e478fa16e',
          'Modified Time': '2025-02-26T05:10:11.832Z',
          'Created Time': '2025-02-26T08:53:39.911Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9e937ccd-68ee-48fc-a581-61571ced8b9d',
        bbox: null,
      },
    },
    'bf6c8191-6748-4dc7-856c-7587de63bd89': {
      id: 'bf6c8191-6748-4dc7-856c-7587de63bd89',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3705648847511, 25.2575976992756],
              [55.3707282762795, 25.2575976992756],
              [55.3707282762795, 25.2577585859839],
              [55.3705648847511, 25.2577585859839],
              [55.3705648847511, 25.2575976992756],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93212890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8b3ac1fb-464d-4c4c-99f4-5b8c4a1f6451',
          'Modified Time': '2025-02-26T04:06:55.679Z',
          'Created Time': '2025-02-26T06:34:41.525Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bf6c8191-6748-4dc7-856c-7587de63bd89',
        bbox: null,
      },
    },
    '4d4c4645-ca19-4b12-9341-8635c29b3c5c': {
      id: '4d4c4645-ca19-4b12-9341-8635c29b3c5c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3618786367011, 25.2618296295535],
              [55.3621582636158, 25.2618296295535],
              [55.3621582636158, 25.2623189494817],
              [55.3618786367011, 25.2623189494817],
              [55.3618786367011, 25.2618296295535],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93115234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1080ec87-7762-434e-9eb6-4a298503c4ba',
          'Modified Time': '2025-02-26T11:18:56.539Z',
          'Created Time': '2025-02-25T23:13:17.158Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4d4c4645-ca19-4b12-9341-8635c29b3c5c',
        bbox: null,
      },
    },
    'caee8bb1-1e0e-42de-8e6d-8b641119233f': {
      id: 'caee8bb1-1e0e-42de-8e6d-8b641119233f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3470305271005, 25.2585419643972],
              [55.3473528820191, 25.2585419643972],
              [55.3473528820191, 25.258805596594],
              [55.3470305271005, 25.258805596594],
              [55.3470305271005, 25.2585419643972],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93115234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '080c88e1-846f-4983-9324-d41e5c8c033f',
          'Modified Time': '2025-02-25T16:58:50.627Z',
          'Created Time': '2025-02-25T20:33:57.379Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'caee8bb1-1e0e-42de-8e6d-8b641119233f',
        bbox: null,
      },
    },
    '775f83c2-b8e5-422a-bf53-b802bdf1193b': {
      id: '775f83c2-b8e5-422a-bf53-b802bdf1193b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3560043965353, 25.2500801939611],
              [55.3566848739364, 25.2500801939611],
              [55.3566848739364, 25.2505568433992],
              [55.3560043965353, 25.2505568433992],
              [55.3560043965353, 25.2500801939611],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93017578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ecec8b1b-f684-4240-baa5-d90169558438',
          'Modified Time': '2025-02-26T08:58:01.626Z',
          'Created Time': '2025-02-26T01:19:41.473Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '775f83c2-b8e5-422a-bf53-b802bdf1193b',
        bbox: null,
      },
    },
    '3538a9d9-d81f-46ce-b2f8-b80dd6407836': {
      id: '3538a9d9-d81f-46ce-b2f8-b80dd6407836',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3798987420636, 25.2480476563663],
              [55.3802093247558, 25.2480476563663],
              [55.3802093247558, 25.2483474358703],
              [55.3798987420636, 25.2483474358703],
              [55.3798987420636, 25.2480476563663],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6edb4469-ec8f-4119-852f-d2185dcac28f',
          'Modified Time': '2025-02-26T03:18:30.366Z',
          'Created Time': '2025-02-26T01:47:06.174Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3538a9d9-d81f-46ce-b2f8-b80dd6407836',
        bbox: null,
      },
    },
    '6f4f37c0-fa8c-43d0-a523-17bd82636e4a': {
      id: '6f4f37c0-fa8c-43d0-a523-17bd82636e4a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3852694808439, 25.2392013497855],
              [55.3857587648415, 25.2392013497855],
              [55.3857587648415, 25.2394864743942],
              [55.3852694808439, 25.2394864743942],
              [55.3852694808439, 25.2392013497855],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.92724609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '41d8f813-035e-43a8-81b7-1da2ec95a402',
          'Modified Time': '2025-02-26T07:01:57.802Z',
          'Created Time': '2025-02-25T15:20:35.906Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6f4f37c0-fa8c-43d0-a523-17bd82636e4a',
        bbox: null,
      },
    },
    'a4a804e9-bb04-4460-9c0d-31250ee20beb': {
      id: 'a4a804e9-bb04-4460-9c0d-31250ee20beb',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3781144763155, 25.2389805136126],
              [55.3787515813372, 25.2389805136126],
              [55.3787515813372, 25.2392609798485],
              [55.3781144763155, 25.2392609798485],
              [55.3781144763155, 25.2389805136126],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.92724609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a6141549-f982-4bd3-b1e7-bd240239dfce',
          'Modified Time': '2025-02-26T00:14:25.182Z',
          'Created Time': '2025-02-26T09:41:21.115Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a4a804e9-bb04-4460-9c0d-31250ee20beb',
        bbox: null,
      },
    },
    '9d81dda3-91c9-4c72-b448-ecd3004e4129': {
      id: '9d81dda3-91c9-4c72-b448-ecd3004e4129',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3577355225496, 25.2644823545622],
              [55.3580258694477, 25.2644823545622],
              [55.3580258694477, 25.2646662243546],
              [55.3577355225496, 25.2646662243546],
              [55.3577355225496, 25.2644823545622],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.92431640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '07870785-c083-4f18-b8e4-8ba9e0962747',
          'Modified Time': '2025-02-26T02:07:27.997Z',
          'Created Time': '2025-02-26T07:51:20.533Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9d81dda3-91c9-4c72-b448-ecd3004e4129',
        bbox: null,
      },
    },
    'aef8f4ef-b89f-4550-926c-1047c8b01dac': {
      id: 'aef8f4ef-b89f-4550-926c-1047c8b01dac',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3780829603353, 25.2392917129767],
              [55.3788043898626, 25.2392917129767],
              [55.3788043898626, 25.2394439135029],
              [55.3780829603353, 25.2394439135029],
              [55.3780829603353, 25.2392917129767],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.923828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9488b261-517b-4886-96fa-cadb246be6b1',
          'Modified Time': '2025-02-26T13:19:35.598Z',
          'Created Time': '2025-02-26T14:51:48.657Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aef8f4ef-b89f-4550-926c-1047c8b01dac',
        bbox: null,
      },
    },
    '359734bf-3092-42c1-9222-06d5dcd610a2': {
      id: '359734bf-3092-42c1-9222-06d5dcd610a2',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3694683073242, 25.2578601308089],
              [55.3696528017827, 25.2578601308089],
              [55.3696528017827, 25.2580562519682],
              [55.3694683073242, 25.2580562519682],
              [55.3694683073242, 25.2578601308089],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91943359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd8788988-9734-4575-8006-662cdb453638',
          'Modified Time': '2025-02-26T03:57:44.358Z',
          'Created Time': '2025-02-25T17:24:36.026Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '359734bf-3092-42c1-9222-06d5dcd610a2',
        bbox: null,
      },
    },
    '3bde004a-5fd2-4fb1-8320-464577457fbd': {
      id: '3bde004a-5fd2-4fb1-8320-464577457fbd',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3455774636906, 25.2581697352092],
              [55.3460795141995, 25.2581697352092],
              [55.3460795141995, 25.2583379414453],
              [55.3455774636906, 25.2583379414453],
              [55.3455774636906, 25.2581697352092],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91650390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ed719f58-7dc6-4835-b9e3-729599d104e0',
          'Modified Time': '2025-02-26T10:12:36.466Z',
          'Created Time': '2025-02-26T12:15:55.796Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3bde004a-5fd2-4fb1-8320-464577457fbd',
        bbox: null,
      },
    },
    '50bccf14-1b6c-41ca-b0da-d93b92107f63': {
      id: '50bccf14-1b6c-41ca-b0da-d93b92107f63',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3554128899294, 25.2539450947326],
              [55.3555765929335, 25.2539450947326],
              [55.3555765929335, 25.2545278313001],
              [55.3554128899294, 25.2545278313001],
              [55.3554128899294, 25.2539450947326],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91259765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd29be7a5-989c-46dc-b46d-0c018cc9e412',
          'Modified Time': '2025-02-26T13:13:57.007Z',
          'Created Time': '2025-02-25T21:11:57.713Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '50bccf14-1b6c-41ca-b0da-d93b92107f63',
        bbox: null,
      },
    },
    'e3b18db7-de66-4c6d-b764-2e62e62d72c9': {
      id: 'e3b18db7-de66-4c6d-b764-2e62e62d72c9',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.338978476495, 25.2637195904385],
              [55.3393011136334, 25.2637195904385],
              [55.3393011136334, 25.263875522421],
              [55.338978476495, 25.263875522421],
              [55.338978476495, 25.2637195904385],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.912109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5758fae7-efe6-48d0-94f1-c9d4bcda1406',
          'Modified Time': '2025-02-26T08:37:58.881Z',
          'Created Time': '2025-02-25T20:42:12.657Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e3b18db7-de66-4c6d-b764-2e62e62d72c9',
        bbox: null,
      },
    },
    '6e0de562-96ee-433f-9d73-efe761f7af9b': {
      id: '6e0de562-96ee-433f-9d73-efe761f7af9b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3852696616676, 25.2395108561568],
              [55.3857589486385, 25.2395108561568],
              [55.3857589486385, 25.2397959830652],
              [55.3852696616676, 25.2397959830652],
              [55.3852696616676, 25.2395108561568],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91064453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4cb60ad3-ddc9-403e-8f55-b7fa7dce3d2d',
          'Modified Time': '2025-02-26T11:55:13.931Z',
          'Created Time': '2025-02-25T21:59:21.671Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6e0de562-96ee-433f-9d73-efe761f7af9b',
        bbox: null,
      },
    },
    '9d6f5f05-d961-49ce-85d8-15bde77eea7d': {
      id: '9d6f5f05-d961-49ce-85d8-15bde77eea7d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3576932917454, 25.2643435742835],
              [55.3580364277243, 25.2643435742835],
              [55.3580364277243, 25.2644629659353],
              [55.3576932917454, 25.2644629659353],
              [55.3576932917454, 25.2643435742835],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1becb85f-d2b1-4921-9b76-e468766908ff',
          'Modified Time': '2025-02-26T15:00:08.760Z',
          'Created Time': '2025-02-25T15:25:00.999Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9d6f5f05-d961-49ce-85d8-15bde77eea7d',
        bbox: null,
      },
    },
    '2ce00b06-5ea6-421c-b44e-57c976415f14': {
      id: '2ce00b06-5ea6-421c-b44e-57c976415f14',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3620194264952, 25.2466762181532],
              [55.3621512301782, 25.2466762181532],
              [55.3621512301782, 25.2472150075376],
              [55.3620194264952, 25.2472150075376],
              [55.3620194264952, 25.2466762181532],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.87841796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '98182afe-e8f0-45d8-a42c-435fcde8cc71',
          'Modified Time': '2025-02-25T23:41:36.502Z',
          'Created Time': '2025-02-25T21:25:23.920Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2ce00b06-5ea6-421c-b44e-57c976415f14',
        bbox: null,
      },
    },
    '851da353-6086-489a-9c1f-60394b8c4e62': {
      id: '851da353-6086-489a-9c1f-60394b8c4e62',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4000326829845, 25.2393597592969],
              [55.4002164971448, 25.2393597592969],
              [55.4002164971448, 25.2395199197858],
              [55.4000326829845, 25.2395199197858],
              [55.4000326829845, 25.2393597592969],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.87744140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'cc1ed1b3-c8c7-4b1e-a816-e7d66ba78836',
          'Modified Time': '2025-02-26T04:59:23.545Z',
          'Created Time': '2025-02-25T15:14:02.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '851da353-6086-489a-9c1f-60394b8c4e62',
        bbox: null,
      },
    },
    '785019ff-302e-4bae-8e28-72252e81d2a1': {
      id: '785019ff-302e-4bae-8e28-72252e81d2a1',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3147602850173, 25.2900918095464],
              [55.3149992349516, 25.2900918095464],
              [55.3149992349516, 25.2904300783408],
              [55.3147602850173, 25.2904300783408],
              [55.3147602850173, 25.2900918095464],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.8759765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8d1f1b5e-5da3-4f1f-a87b-0af2d2494ad7',
          'Modified Time': '2025-02-26T02:07:48.187Z',
          'Created Time': '2025-02-26T03:15:53.069Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '785019ff-302e-4bae-8e28-72252e81d2a1',
        bbox: null,
      },
    },
    '19bec521-6106-4203-aa4c-ba1f4fde7558': {
      id: '19bec521-6106-4203-aa4c-ba1f4fde7558',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3688113140034, 25.2448299384546],
              [55.3689113684062, 25.2448299384546],
              [55.3689113684062, 25.2452261002223],
              [55.3688113140034, 25.2452261002223],
              [55.3688113140034, 25.2448299384546],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.87451171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7f5a6d01-d440-4649-9117-b9e5c2e2d5b5',
          'Modified Time': '2025-02-25T15:47:00.958Z',
          'Created Time': '2025-02-26T09:55:48.125Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '19bec521-6106-4203-aa4c-ba1f4fde7558',
        bbox: null,
      },
    },
    'cf2974f7-65c5-418a-a8fb-7d5408f9ea9a': {
      id: 'cf2974f7-65c5-418a-a8fb-7d5408f9ea9a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3135618755645, 25.2900225964987],
              [55.3137901835416, 25.2900225964987],
              [55.3137901835416, 25.2902893596076],
              [55.3135618755645, 25.2902893596076],
              [55.3135618755645, 25.2900225964987],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.8720703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c85c5e54-0516-4e38-bd7c-11ea4c33fb64',
          'Modified Time': '2025-02-26T11:04:40.848Z',
          'Created Time': '2025-02-26T00:11:09.008Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cf2974f7-65c5-418a-a8fb-7d5408f9ea9a',
        bbox: null,
      },
    },
    'ab5f5ba2-5d4f-40cd-adb4-c6816d978435': {
      id: 'ab5f5ba2-5d4f-40cd-adb4-c6816d978435',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3150888664251, 25.2903599927705],
              [55.3153913884768, 25.2903599927705],
              [55.3153913884768, 25.2906423946767],
              [55.3150888664251, 25.2906423946767],
              [55.3150888664251, 25.2903599927705],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86767578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '67f0d43f-277a-4bb1-998b-1597c5982f7e',
          'Modified Time': '2025-02-26T09:19:57.802Z',
          'Created Time': '2025-02-26T06:59:19.436Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ab5f5ba2-5d4f-40cd-adb4-c6816d978435',
        bbox: null,
      },
    },
    '2d3a1051-b848-493f-9e68-3de2e482e8cd': {
      id: '2d3a1051-b848-493f-9e68-3de2e482e8cd',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790384840992, 25.2561298750098],
              [55.3791594851761, 25.2561298750098],
              [55.3791594851761, 25.2565074017025],
              [55.3790384840992, 25.2565074017025],
              [55.3790384840992, 25.2561298750098],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86474609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9e09d26e-b286-4ab9-a0fe-3e65df700fd6',
          'Modified Time': '2025-02-26T10:51:01.095Z',
          'Created Time': '2025-02-26T14:01:47.614Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2d3a1051-b848-493f-9e68-3de2e482e8cd',
        bbox: null,
      },
    },
    '6aebf6ec-feec-4fbe-9657-d9be2bee1b77': {
      id: '6aebf6ec-feec-4fbe-9657-d9be2bee1b77',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3143151703077, 25.2898194627726],
              [55.3146176802842, 25.2898194627726],
              [55.3146176802842, 25.2900659443053],
              [55.3143151703077, 25.2900659443053],
              [55.3143151703077, 25.2898194627726],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86474609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b18afdb3-4f5d-496a-8147-31417fb820e5',
          'Modified Time': '2025-02-26T06:26:41.476Z',
          'Created Time': '2025-02-26T05:41:59.569Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6aebf6ec-feec-4fbe-9657-d9be2bee1b77',
        bbox: null,
      },
    },
    'cc4190a9-000b-4a1b-a302-5d264785cff9': {
      id: 'cc4190a9-000b-4a1b-a302-5d264785cff9',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3138957143235, 25.2903884090885],
              [55.3141451591498, 25.2903884090885],
              [55.3141451591498, 25.2905826698327],
              [55.3138957143235, 25.2905826698327],
              [55.3138957143235, 25.2903884090885],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86279296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '68be4b6b-3523-4e08-a206-6f16a11a5a1b',
          'Modified Time': '2025-02-26T02:09:55.966Z',
          'Created Time': '2025-02-26T11:05:25.990Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cc4190a9-000b-4a1b-a302-5d264785cff9',
        bbox: null,
      },
    },
    'cc07f245-ab0f-4189-90d9-84874c10fceb': {
      id: 'cc07f245-ab0f-4189-90d9-84874c10fceb',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3139070056086, 25.2895421237839],
              [55.3142202104342, 25.2895421237839],
              [55.3142202104342, 25.2898690931501],
              [55.3139070056086, 25.2898690931501],
              [55.3139070056086, 25.2895421237839],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.853515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2235766f-c66d-4a0d-b990-9fd890e5d2b1',
          'Modified Time': '2025-02-26T01:24:12.212Z',
          'Created Time': '2025-02-26T11:40:54.565Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cc07f245-ab0f-4189-90d9-84874c10fceb',
        bbox: null,
      },
    },
    '5d3be3c0-349d-42da-a0ff-944b0be195af': {
      id: '5d3be3c0-349d-42da-a0ff-944b0be195af',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3667134416663, 25.2833330775868],
              [55.3668347925012, 25.2833330775868],
              [55.3668347925012, 25.2834504610952],
              [55.3667134416663, 25.2834504610952],
              [55.3667134416663, 25.2833330775868],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.84765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c5a0ec01-edd4-4692-ac65-54b9e2a45d9d',
          'Modified Time': '2025-02-25T19:42:15.157Z',
          'Created Time': '2025-02-26T06:22:45.369Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5d3be3c0-349d-42da-a0ff-944b0be195af',
        bbox: null,
      },
    },
    '4e10f766-39cd-4138-b2b7-00d88a83cd33': {
      id: '4e10f766-39cd-4138-b2b7-00d88a83cd33',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3258718103796, 25.1809996412517],
              [55.3259723016968, 25.1809996412517],
              [55.3259723016968, 25.1810996104089],
              [55.3258718103796, 25.1810996104089],
              [55.3258718103796, 25.1809996412517],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.84619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4448085f-f477-4948-ba95-974662c9eca9',
          'Modified Time': '2025-02-25T19:23:16.166Z',
          'Created Time': '2025-02-25T17:08:56.432Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4e10f766-39cd-4138-b2b7-00d88a83cd33',
        bbox: null,
      },
    },
    'c97fb086-7b7b-45f0-aad9-39c9ed6c2712': {
      id: 'c97fb086-7b7b-45f0-aad9-39c9ed6c2712',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3756668271565, 25.2569292750933],
              [55.3757457773649, 25.2569292750933],
              [55.3757457773649, 25.2571914932534],
              [55.3756668271565, 25.2571914932534],
              [55.3756668271565, 25.2569292750933],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.845703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8483e75b-07a9-470b-9cab-66687b30d94e',
          'Modified Time': '2025-02-26T10:12:34.215Z',
          'Created Time': '2025-02-25T19:39:07.731Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c97fb086-7b7b-45f0-aad9-39c9ed6c2712',
        bbox: null,
      },
    },
    '2c4f22df-6350-4a33-bedf-16a2badbddeb': {
      id: '2c4f22df-6350-4a33-bedf-16a2badbddeb',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3786142738425, 25.2380859591421],
              [55.3786932321581, 25.2380859591421],
              [55.3786932321581, 25.2381597293899],
              [55.3786142738425, 25.2381597293899],
              [55.3786142738425, 25.2380859591421],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.83740234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'dbb97468-2673-4ab4-8573-294ff2d99579',
          'Modified Time': '2025-02-26T11:21:00.756Z',
          'Created Time': '2025-02-26T08:31:01.023Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2c4f22df-6350-4a33-bedf-16a2badbddeb',
        bbox: null,
      },
    },
    'c0977094-5e10-48c7-8914-26ea1249034c': {
      id: 'c0977094-5e10-48c7-8914-26ea1249034c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3958694486217, 25.2392511579387],
              [55.3960425945325, 25.2392511579387],
              [55.3960425945325, 25.2397166257308],
              [55.3958694486217, 25.2397166257308],
              [55.3958694486217, 25.2392511579387],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.82177734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd04c0aea-6732-4483-8373-28ffc23b511e',
          'Modified Time': '2025-02-26T00:55:26.592Z',
          'Created Time': '2025-02-25T16:28:07.013Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c0977094-5e10-48c7-8914-26ea1249034c',
        bbox: null,
      },
    },
    'eafcac07-6c01-4a70-832d-987d657d3a41': {
      id: 'eafcac07-6c01-4a70-832d-987d657d3a41',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3519332759897, 25.2546407939008],
              [55.3520230609012, 25.2546407939008],
              [55.3520230609012, 25.2552797165475],
              [55.3519332759897, 25.2552797165475],
              [55.3519332759897, 25.2546407939008],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7978515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2efa6a18-9640-4230-95ba-850e186ed319',
          'Modified Time': '2025-02-25T18:21:17.601Z',
          'Created Time': '2025-02-25T18:13:22.563Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eafcac07-6c01-4a70-832d-987d657d3a41',
        bbox: null,
      },
    },
    'd7332123-b0cb-4142-a0f7-aa1e41853fbc': {
      id: 'd7332123-b0cb-4142-a0f7-aa1e41853fbc',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3137371289882, 25.289830506527],
              [55.3139653793953, 25.289830506527],
              [55.3139653793953, 25.2900433889765],
              [55.3137371289882, 25.2900433889765],
              [55.3137371289882, 25.289830506527],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.79150390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b1b99bc5-22b7-4b00-bf3c-150e7298bde0',
          'Modified Time': '2025-02-26T07:34:50.099Z',
          'Created Time': '2025-02-26T06:38:59.285Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd7332123-b0cb-4142-a0f7-aa1e41853fbc',
        bbox: null,
      },
    },
    '8d67b1a0-2797-4a2c-a283-f55cdc698abf': {
      id: '8d67b1a0-2797-4a2c-a283-f55cdc698abf',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3980735318851, 25.2689551568496],
              [55.398247001349, 25.2689551568496],
              [55.398247001349, 25.2690888152225],
              [55.3980735318851, 25.2690888152225],
              [55.3980735318851, 25.2689551568496],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.787109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '31b72fcb-29d6-486b-abb0-d4d7c4322a98',
          'Modified Time': '2025-02-25T16:27:02.820Z',
          'Created Time': '2025-02-25T22:52:35.947Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8d67b1a0-2797-4a2c-a283-f55cdc698abf',
        bbox: null,
      },
    },
    'de877fbf-ce37-4675-b26b-7367384e0938': {
      id: 'de877fbf-ce37-4675-b26b-7367384e0938',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3828565879253, 25.2355059155238],
              [55.3829355049834, 25.2355059155238],
              [55.3829355049834, 25.235579681383],
              [55.3828565879253, 25.235579681383],
              [55.3828565879253, 25.2355059155238],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7822265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'dda458b5-cf46-4604-ad20-46d696bf484e',
          'Modified Time': '2025-02-25T19:04:27.957Z',
          'Created Time': '2025-02-25T17:47:11.221Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'de877fbf-ce37-4675-b26b-7367384e0938',
        bbox: null,
      },
    },
    '8c10b398-f8f6-422f-9264-6f68d292febc': {
      id: '8c10b398-f8f6-422f-9264-6f68d292febc',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.365481811602, 25.2601048205083],
              [55.3655397865566, 25.2601048205083],
              [55.3655397865566, 25.2604215778806],
              [55.365481811602, 25.2604215778806],
              [55.365481811602, 25.2601048205083],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7802734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8932a7f8-7929-467a-b345-7faf90361204',
          'Modified Time': '2025-02-26T00:50:40.600Z',
          'Created Time': '2025-02-25T16:46:52.921Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8c10b398-f8f6-422f-9264-6f68d292febc',
        bbox: null,
      },
    },
    'b5937c0c-9206-4a8a-ac04-53b67347cc1c': {
      id: 'b5937c0c-9206-4a8a-ac04-53b67347cc1c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3754668905823, 25.3166265522335],
              [55.3755882226201, 25.3166265522335],
              [55.3755882226201, 25.3166901398282],
              [55.3754668905823, 25.3166901398282],
              [55.3754668905823, 25.3166265522335],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.77978515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0e108126-d7ce-4337-9b59-94c176106559',
          'Modified Time': '2025-02-25T18:49:59.490Z',
          'Created Time': '2025-02-25T17:03:18.926Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b5937c0c-9206-4a8a-ac04-53b67347cc1c',
        bbox: null,
      },
    },
    '7d63b09e-0088-4f7a-a5e4-9124f5ac75dc': {
      id: '7d63b09e-0088-4f7a-a5e4-9124f5ac75dc',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3146063950952, 25.2901783108899],
              [55.3147499166863, 25.2901783108899],
              [55.3147499166863, 25.2905375222608],
              [55.3146063950952, 25.2905375222608],
              [55.3146063950952, 25.2901783108899],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.77880859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8d82483e-3ec4-4ec4-ac97-2ff3fa376291',
          'Modified Time': '2025-02-26T07:46:42.383Z',
          'Created Time': '2025-02-26T12:39:22.625Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7d63b09e-0088-4f7a-a5e4-9124f5ac75dc',
        bbox: null,
      },
    },
    'e6ef048a-8192-4114-be25-68a81a62ea7e': {
      id: 'e6ef048a-8192-4114-be25-68a81a62ea7e',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3718683798813, 25.2448181124379],
              [55.3720949702313, 25.2448181124379],
              [55.3720949702313, 25.2448962167583],
              [55.3718683798813, 25.2448962167583],
              [55.3718683798813, 25.2448181124379],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ad25ddf8-534b-4637-b083-29437fadc0a8',
          'Modified Time': '2025-02-26T07:39:40.010Z',
          'Created Time': '2025-02-25T19:16:34.523Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e6ef048a-8192-4114-be25-68a81a62ea7e',
        bbox: null,
      },
    },
    '1afdcb9f-01b6-4fce-b6cd-86aa2bd843d4': {
      id: '1afdcb9f-01b6-4fce-b6cd-86aa2bd843d4',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3565803096488, 25.2060929648545],
              [55.3566594104252, 25.2060929648545],
              [55.3566594104252, 25.2061667039316],
              [55.3565803096488, 25.2061667039316],
              [55.3565803096488, 25.2060929648545],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.76025390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '76a04d76-51c4-4987-8692-682c1d5278a8',
          'Modified Time': '2025-02-26T07:06:26.386Z',
          'Created Time': '2025-02-25T21:26:22.848Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1afdcb9f-01b6-4fce-b6cd-86aa2bd843d4',
        bbox: null,
      },
    },
    '1bcd2a95-7cd6-4647-ad9a-645ab022efc6': {
      id: '1bcd2a95-7cd6-4647-ad9a-645ab022efc6',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.314054988129, 25.290217277387],
              [55.3142726007023, 25.290217277387],
              [55.3142726007023, 25.2904035519664],
              [55.314054988129, 25.2904035519664],
              [55.314054988129, 25.290217277387],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7490234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7f8547cc-1ee9-4e5d-bb4e-89aea5443bec',
          'Modified Time': '2025-02-26T14:51:47.315Z',
          'Created Time': '2025-02-26T08:46:36.859Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1bcd2a95-7cd6-4647-ad9a-645ab022efc6',
        bbox: null,
      },
    },
    '2ccbfa3b-1e5f-4788-b3c1-3aabd72f2e87': {
      id: '2ccbfa3b-1e5f-4788-b3c1-3aabd72f2e87',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4062122552325, 25.2690759759009],
              [55.4063645733872, 25.2690759759009],
              [55.4063645733872, 25.2691833763084],
              [55.4062122552325, 25.2691833763084],
              [55.4062122552325, 25.2690759759009],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7431640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ac6f4fa9-a144-47c7-91cf-61dd28e12175',
          'Modified Time': '2025-02-25T15:27:41.173Z',
          'Created Time': '2025-02-26T07:06:25.732Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2ccbfa3b-1e5f-4788-b3c1-3aabd72f2e87',
        bbox: null,
      },
    },
    '829a524c-418b-4e91-b441-94ef042d514b': {
      id: '829a524c-418b-4e91-b441-94ef042d514b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2733353385437, 25.26182257934],
              [55.2739903804582, 25.26182257934],
              [55.2739903804582, 25.2622015251816],
              [55.2733353385437, 25.2622015251816],
              [55.2733353385437, 25.26182257934],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7412109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b0911666-b329-490c-89cd-222cc98e4af5',
          'Modified Time': '2025-02-26T04:57:57.854Z',
          'Created Time': '2025-02-25T17:54:29.443Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '829a524c-418b-4e91-b441-94ef042d514b',
        bbox: null,
      },
    },
    '62e738bc-6df4-43bf-ab3d-9f18e47b0a5d': {
      id: '62e738bc-6df4-43bf-ab3d-9f18e47b0a5d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.375720192608, 25.2589738322606],
              [55.375851877332, 25.2589738322606],
              [55.375851877332, 25.2591357068054],
              [55.375720192608, 25.2591357068054],
              [55.375720192608, 25.2589738322606],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7353515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a943d9c5-63e0-408b-8c31-33521027c494',
          'Modified Time': '2025-02-26T06:23:27.626Z',
          'Created Time': '2025-02-26T01:25:38.026Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '62e738bc-6df4-43bf-ab3d-9f18e47b0a5d',
        bbox: null,
      },
    },
    'dfef8b3e-e3ae-49d3-9001-a81ea7c4de43': {
      id: 'dfef8b3e-e3ae-49d3-9001-a81ea7c4de43',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3620682360018, 25.257898852354],
              [55.3621578996348, 25.257898852354],
              [55.3621578996348, 25.2582325774596],
              [55.3620682360018, 25.2582325774596],
              [55.3620682360018, 25.257898852354],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.728515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'de08d003-7a78-499f-8609-0a2b859120df',
          'Modified Time': '2025-02-26T04:33:55.276Z',
          'Created Time': '2025-02-26T04:29:45.544Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dfef8b3e-e3ae-49d3-9001-a81ea7c4de43',
        bbox: null,
      },
    },
    '53bc41a0-da38-4f6c-adb8-cfe8222093a9': {
      id: '53bc41a0-da38-4f6c-adb8-cfe8222093a9',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3393183296968, 25.2595663902434],
              [55.3394082419057, 25.2595663902434],
              [55.3394082419057, 25.2596308867028],
              [55.3393183296968, 25.2596308867028],
              [55.3393183296968, 25.2595663902434],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.72314453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '718d586f-424a-497d-aad8-f769a91144a7',
          'Modified Time': '2025-02-26T11:22:10.220Z',
          'Created Time': '2025-02-26T13:20:11.965Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '53bc41a0-da38-4f6c-adb8-cfe8222093a9',
        bbox: null,
      },
    },
    'babd2c84-6cca-451f-8a6a-e5db8261642f': {
      id: 'babd2c84-6cca-451f-8a6a-e5db8261642f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3818589506951, 25.2508031017156],
              [55.3823907559367, 25.2508031017156],
              [55.3823907559367, 25.2508626953039],
              [55.3818589506951, 25.2508626953039],
              [55.3818589506951, 25.2508031017156],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.71533203125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2947e9ce-6616-4457-9e01-238e2b5825a5',
          'Modified Time': '2025-02-25T21:24:57.100Z',
          'Created Time': '2025-02-26T13:47:53.095Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'babd2c84-6cca-451f-8a6a-e5db8261642f',
        bbox: null,
      },
    },
    '81893e3a-1e1b-41d1-8ebe-6503a557d8c3': {
      id: '81893e3a-1e1b-41d1-8ebe-6503a557d8c3',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3117768110816, 25.2580523946087],
              [55.3124184463931, 25.2580523946087],
              [55.3124184463931, 25.2582879838918],
              [55.3117768110816, 25.2582879838918],
              [55.3117768110816, 25.2580523946087],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.712890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b08c8fcc-11c2-485e-929d-780f7564f167',
          'Modified Time': '2025-02-26T02:55:11.593Z',
          'Created Time': '2025-02-25T21:44:04.603Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '81893e3a-1e1b-41d1-8ebe-6503a557d8c3',
        bbox: null,
      },
    },
    'e212689e-4b43-44ab-af31-1c5e48886a60': {
      id: 'e212689e-4b43-44ab-af31-1c5e48886a60',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3805357839225, 25.2895748592736],
              [55.3806991220574, 25.2895748592736],
              [55.3806991220574, 25.2897088894653],
              [55.3805357839225, 25.2897088894653],
              [55.3805357839225, 25.2895748592736],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.70361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fed92d1c-ec34-42f8-8be3-63addac5e9c7',
          'Modified Time': '2025-02-26T02:40:53.892Z',
          'Created Time': '2025-02-26T06:14:55.291Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e212689e-4b43-44ab-af31-1c5e48886a60',
        bbox: null,
      },
    },
    'bee8517a-2895-4859-ae84-06879858f56a': {
      id: 'bee8517a-2895-4859-ae84-06879858f56a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3319616770828, 25.244006373951],
              [55.3322844818389, 25.244006373951],
              [55.3322844818389, 25.2443238161122],
              [55.3319616770828, 25.2443238161122],
              [55.3319616770828, 25.244006373951],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a231da41-4bcc-42b7-a845-12caaaec42b6',
          'Modified Time': '2025-02-26T13:10:23.162Z',
          'Created Time': '2025-02-26T06:39:26.966Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bee8517a-2895-4859-ae84-06879858f56a',
        bbox: null,
      },
    },
    '0e5b8fd7-352c-426a-98fa-72a71c4bb878': {
      id: '0e5b8fd7-352c-426a-98fa-72a71c4bb878',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3775936398821, 25.2852907421823],
              [55.3777781052966, 25.2852907421823],
              [55.3777781052966, 25.2853972000588],
              [55.3775936398821, 25.2853972000588],
              [55.3775936398821, 25.2852907421823],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9b088918-804d-4b6f-a5ad-43ac262a3de7',
          'Modified Time': '2025-02-25T17:19:24.095Z',
          'Created Time': '2025-02-26T01:46:52.008Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0e5b8fd7-352c-426a-98fa-72a71c4bb878',
        bbox: null,
      },
    },
    '87d523ee-57d8-40e0-b67f-525b561f3845': {
      id: '87d523ee-57d8-40e0-b67f-525b561f3845',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2909783893992, 25.2882446997141],
              [55.2911758556487, 25.2882446997141],
              [55.2911758556487, 25.2888448719404],
              [55.2909783893992, 25.2888448719404],
              [55.2909783893992, 25.2882446997141],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69677734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0172336c-9558-4288-838d-11a91477844e',
          'Modified Time': '2025-02-26T00:54:06.736Z',
          'Created Time': '2025-02-25T16:24:10.203Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '87d523ee-57d8-40e0-b67f-525b561f3845',
        bbox: null,
      },
    },
    'f8547d27-7cd6-44e2-b01b-2e13abcd5769': {
      id: 'f8547d27-7cd6-44e2-b01b-2e13abcd5769',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3145214967108, 25.2903668880125],
              [55.3147497387065, 25.2903668880125],
              [55.3147497387065, 25.290588750817],
              [55.3145214967108, 25.290588750817],
              [55.3145214967108, 25.2903668880125],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6962890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7cf0b2fa-ed61-44ae-b8c3-828b0ddb114f',
          'Modified Time': '2025-02-25T18:22:19.077Z',
          'Created Time': '2025-02-26T04:53:04.906Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f8547d27-7cd6-44e2-b01b-2e13abcd5769',
        bbox: null,
      },
    },
    '3dabd63e-2fde-42d6-b05b-89513de479be': {
      id: '3dabd63e-2fde-42d6-b05b-89513de479be',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2886737216421, 25.2133166132564],
              [55.288785299885, 25.2133166132564],
              [55.288785299885, 25.2134073081883],
              [55.2886737216421, 25.2134073081883],
              [55.2886737216421, 25.2133166132564],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69091796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b8adde6f-f249-4128-8fc1-a92ea7a7bd0f',
          'Modified Time': '2025-02-26T14:39:19.960Z',
          'Created Time': '2025-02-26T02:25:45.179Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3dabd63e-2fde-42d6-b05b-89513de479be',
        bbox: null,
      },
    },
    'be4dc8c6-1eb7-4a09-9c2d-0b21f9c4c705': {
      id: 'be4dc8c6-1eb7-4a09-9c2d-0b21f9c4c705',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3946073166476, 25.2615313436233],
              [55.394728253563, 25.2615313436233],
              [55.394728253563, 25.2616127991442],
              [55.3946073166476, 25.2616127991442],
              [55.3946073166476, 25.2615313436233],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.68505859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0bb88625-b8bf-4036-954a-541acc3d06ca',
          'Modified Time': '2025-02-25T15:08:10.686Z',
          'Created Time': '2025-02-26T11:22:18.011Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'be4dc8c6-1eb7-4a09-9c2d-0b21f9c4c705',
        bbox: null,
      },
    },
    '47736295-894d-49d7-aba4-11c497637ade': {
      id: '47736295-894d-49d7-aba4-11c497637ade',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3394481610877, 25.2275044972817],
              [55.3395802924834, 25.2275044972817],
              [55.3395802924834, 25.2275855512195],
              [55.3394481610877, 25.2275855512195],
              [55.3394481610877, 25.2275044972817],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ee938170-5f2c-47d1-828a-002fcb7684ee',
          'Modified Time': '2025-02-26T09:26:41.473Z',
          'Created Time': '2025-02-26T06:56:36.295Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '47736295-894d-49d7-aba4-11c497637ade',
        bbox: null,
      },
    },
    'd4f0fb57-9c59-4869-9717-d711c3c698a8': {
      id: 'd4f0fb57-9c59-4869-9717-d711c3c698a8',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.355036191834, 25.2184470276193],
              [55.3554002257283, 25.2184470276193],
              [55.3554002257283, 25.2185116534122],
              [55.355036191834, 25.2185116534122],
              [55.355036191834, 25.2184470276193],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd441a821-e23d-4b8d-95a4-370b4e5ceea1',
          'Modified Time': '2025-02-26T07:26:55.804Z',
          'Created Time': '2025-02-26T13:38:47.900Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd4f0fb57-9c59-4869-9717-d711c3c698a8',
        bbox: null,
      },
    },
    '698d3670-6cce-4535-af1a-fa4d68ed63a0': {
      id: '698d3670-6cce-4535-af1a-fa4d68ed63a0',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3999644153014, 25.265793812453],
              [55.4002851096862, 25.265793812453],
              [55.4002851096862, 25.2658690871457],
              [55.3999644153014, 25.2658690871457],
              [55.3999644153014, 25.265793812453],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.67724609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '309feb36-0f61-4136-bd83-345b96d61e4c',
          'Modified Time': '2025-02-26T08:26:19.236Z',
          'Created Time': '2025-02-25T20:00:22.844Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '698d3670-6cce-4535-af1a-fa4d68ed63a0',
        bbox: null,
      },
    },
    '595f498a-146f-4f76-8666-83ad7ccf1043': {
      id: '595f498a-146f-4f76-8666-83ad7ccf1043',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3821746985913, 25.2895916328318],
              [55.3824117672535, 25.2895916328318],
              [55.3824117672535, 25.2897592789895],
              [55.3821746985913, 25.2897592789895],
              [55.3821746985913, 25.2895916328318],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.662109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '58e65e9b-158d-44a4-bcc8-d7f5a156d189',
          'Modified Time': '2025-02-26T01:41:06.014Z',
          'Created Time': '2025-02-25T20:13:51.915Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '595f498a-146f-4f76-8666-83ad7ccf1043',
        bbox: null,
      },
    },
    'd28721e2-2ff2-4b02-85d3-5c8e864664a4': {
      id: 'd28721e2-2ff2-4b02-85d3-5c8e864664a4',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2670689416447, 25.1867287515604],
              [55.2672551563556, 25.1867287515604],
              [55.2672551563556, 25.1868169405087],
              [55.2670689416447, 25.1868169405087],
              [55.2670689416447, 25.1867287515604],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.662109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c9014742-fb80-4b31-baeb-e3aede8ea08c',
          'Modified Time': '2025-02-26T08:38:50.991Z',
          'Created Time': '2025-02-25T20:14:17.818Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd28721e2-2ff2-4b02-85d3-5c8e864664a4',
        bbox: null,
      },
    },
    '83fb7d77-391e-4b2f-b085-07aa16fa9c5a': {
      id: '83fb7d77-391e-4b2f-b085-07aa16fa9c5a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.311283180068, 25.2084261149978],
              [55.3114158843927, 25.2084261149978],
              [55.3114158843927, 25.2088484051166],
              [55.311283180068, 25.2088484051166],
              [55.311283180068, 25.2084261149978],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.65673828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '780aea67-58f3-4f76-a046-f8d67334d39b',
          'Modified Time': '2025-02-25T17:26:38.301Z',
          'Created Time': '2025-02-26T05:27:12.948Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '83fb7d77-391e-4b2f-b085-07aa16fa9c5a',
        bbox: null,
      },
    },
    '346d1ccc-bf30-479a-a692-44d50ed15fa4': {
      id: '346d1ccc-bf30-479a-a692-44d50ed15fa4',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3371039306114, 25.2380031245905],
              [55.3380186641662, 25.2380031245905],
              [55.3380186641662, 25.238570988319],
              [55.3371039306114, 25.238570988319],
              [55.3371039306114, 25.2380031245905],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.65576171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4ebc83f0-6f4b-4807-894f-b82ec5426086',
          'Modified Time': '2025-02-25T16:49:00.384Z',
          'Created Time': '2025-02-26T01:28:58.435Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '346d1ccc-bf30-479a-a692-44d50ed15fa4',
        bbox: null,
      },
    },
    '1fd3133a-3e1a-40af-a69e-01b9b5598238': {
      id: '1fd3133a-3e1a-40af-a69e-01b9b5598238',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4352793583907, 25.2092086543497],
              [55.4360799449447, 25.2092086543497],
              [55.4360799449447, 25.2094926477997],
              [55.4352793583907, 25.2094926477997],
              [55.4352793583907, 25.2092086543497],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6552734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '220cd8da-5526-4ada-aff2-303b7cd33625',
          'Modified Time': '2025-02-25T16:51:31.926Z',
          'Created Time': '2025-02-25T17:58:44.938Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1fd3133a-3e1a-40af-a69e-01b9b5598238',
        bbox: null,
      },
    },
    '9ba93075-5c27-416e-be5c-fe1ea7084d5e': {
      id: '9ba93075-5c27-416e-be5c-fe1ea7084d5e',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3728265485277, 25.2419887944361],
              [55.3732849304381, 25.2419887944361],
              [55.3732849304381, 25.2421313609506],
              [55.3728265485277, 25.2421313609506],
              [55.3728265485277, 25.2419887944361],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.64990234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'afe35104-e485-4827-a658-2dfc8bc0d888',
          'Modified Time': '2025-02-26T13:04:56.356Z',
          'Created Time': '2025-02-26T04:59:37.785Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9ba93075-5c27-416e-be5c-fe1ea7084d5e',
        bbox: null,
      },
    },
    'cfbccb2a-3485-4fae-9dd5-c8797eac1c15': {
      id: 'cfbccb2a-3485-4fae-9dd5-c8797eac1c15',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3310313460884, 25.2284734108691],
              [55.33115302256, 25.2284734108691],
              [55.33115302256, 25.2285637831456],
              [55.3310313460884, 25.2285637831456],
              [55.3310313460884, 25.2284734108691],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6474609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd54703e-94eb-4dba-905f-422da3252fb9',
          'Modified Time': '2025-02-26T00:23:42.949Z',
          'Created Time': '2025-02-26T02:31:45.333Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cfbccb2a-3485-4fae-9dd5-c8797eac1c15',
        bbox: null,
      },
    },
    '27017e67-0bd0-47fa-a134-9607820bbd87': {
      id: '27017e67-0bd0-47fa-a134-9607820bbd87',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4452294105225, 25.2712951540267],
              [55.445349697504, 25.2712951540267],
              [55.445349697504, 25.2713945531077],
              [55.4452294105225, 25.2713945531077],
              [55.4452294105225, 25.2712951540267],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6435546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4228103d-6ef8-40f2-92f7-0488817f4926',
          'Modified Time': '2025-02-26T09:16:02.027Z',
          'Created Time': '2025-02-25T19:18:23.189Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '27017e67-0bd0-47fa-a134-9607820bbd87',
        bbox: null,
      },
    },
    'd39495e5-2164-4588-9e7f-55904965fe01': {
      id: 'd39495e5-2164-4588-9e7f-55904965fe01',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.26553123389, 25.2087657157464],
              [55.2656112214183, 25.2087657157464],
              [55.2656112214183, 25.2088934051008],
              [55.26553123389, 25.2088934051008],
              [55.26553123389, 25.2087657157464],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.63818359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '093c8bef-a2c1-42d9-9b9e-c6572014f1a3',
          'Modified Time': '2025-02-26T07:34:10.219Z',
          'Created Time': '2025-02-25T18:19:20.115Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd39495e5-2164-4588-9e7f-55904965fe01',
        bbox: null,
      },
    },
    'debf9cd0-57cf-4bdb-bea9-783782fc221c': {
      id: 'debf9cd0-57cf-4bdb-bea9-783782fc221c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3240870400644, 25.2232148255155],
              [55.3249604157446, 25.2232148255155],
              [55.3249604157446, 25.2237568971939],
              [55.3240870400644, 25.2237568971939],
              [55.3240870400644, 25.2232148255155],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.630859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bf1c87c0-6fb2-406d-95fd-480667ca072f',
          'Modified Time': '2025-02-26T00:16:35.184Z',
          'Created Time': '2025-02-25T23:18:09.686Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'debf9cd0-57cf-4bdb-bea9-783782fc221c',
        bbox: null,
      },
    },
    '88885baf-b783-40bd-88a1-f50191d09c37': {
      id: '88885baf-b783-40bd-88a1-f50191d09c37',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2910020874716, 25.1842380447499],
              [55.2910817408139, 25.1842380447499],
              [55.2910817408139, 25.1843207647219],
              [55.2910020874716, 25.1843207647219],
              [55.2910020874716, 25.1842380447499],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'db4001ba-9dbd-4311-a89c-5337294d9be5',
          'Modified Time': '2025-02-26T09:27:23.416Z',
          'Created Time': '2025-02-26T01:20:51.254Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '88885baf-b783-40bd-88a1-f50191d09c37',
        bbox: null,
      },
    },
    '537444e3-b45b-42f8-8f56-811f489a1de5': {
      id: '537444e3-b45b-42f8-8f56-811f489a1de5',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2608103871144, 25.2809620340992],
              [55.2609225035203, 25.2809620340992],
              [55.2609225035203, 25.2810708180891],
              [55.2608103871144, 25.2810708180891],
              [55.2608103871144, 25.2809620340992],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6220703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1fbd8fbc-c447-46ae-b331-65c325ccf596',
          'Modified Time': '2025-02-25T20:39:06.452Z',
          'Created Time': '2025-02-26T01:10:36.919Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '537444e3-b45b-42f8-8f56-811f489a1de5',
        bbox: null,
      },
    },
    '36d96b16-5e20-471f-8a51-125752ddaea0': {
      id: '36d96b16-5e20-471f-8a51-125752ddaea0',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2969946310718, 25.2603006050704],
              [55.2971062181699, 25.2603006050704],
              [55.2971062181699, 25.2603913772059],
              [55.2969946310718, 25.2603913772059],
              [55.2969946310718, 25.2603006050704],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.62158203125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'afe5e86c-f3a9-4a1f-b048-fba360298130',
          'Modified Time': '2025-02-26T11:03:28.302Z',
          'Created Time': '2025-02-25T23:31:39.619Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '36d96b16-5e20-471f-8a51-125752ddaea0',
        bbox: null,
      },
    },
    '6edeb8d6-3197-431a-bb82-48a78c2fffee': {
      id: '6edeb8d6-3197-431a-bb82-48a78c2fffee',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3862028202842, 25.24241324147],
              [55.3867658611672, 25.24241324147],
              [55.3867658611672, 25.2425345939628],
              [55.3862028202842, 25.2425345939628],
              [55.3862028202842, 25.24241324147],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.62109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7bf5d03e-4fce-4288-ba66-8e4e78eabbb5',
          'Modified Time': '2025-02-26T11:42:07.353Z',
          'Created Time': '2025-02-25T19:47:42.214Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6edeb8d6-3197-431a-bb82-48a78c2fffee',
        bbox: null,
      },
    },
    'ada2f0c2-5428-4115-a5c2-4a0218a6e7fd': {
      id: 'ada2f0c2-5428-4115-a5c2-4a0218a6e7fd',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3268783001382, 25.1888453195167],
              [55.3283642560896, 25.1888453195167],
              [55.3283642560896, 25.1897889506924],
              [55.3268783001382, 25.1897889506924],
              [55.3268783001382, 25.1888453195167],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.61865234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2578bc1b-dba3-41d2-870b-18a4e751d5ae',
          'Modified Time': '2025-02-26T01:28:06.924Z',
          'Created Time': '2025-02-25T22:56:02.984Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ada2f0c2-5428-4115-a5c2-4a0218a6e7fd',
        bbox: null,
      },
    },
    'f4a350b3-21e2-4021-b56f-a62857c46004': {
      id: 'f4a350b3-21e2-4021-b56f-a62857c46004',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3409561803691, 25.2151323732949],
              [55.3416270881522, 25.2151323732949],
              [55.3416270881522, 25.2153037806447],
              [55.3409561803691, 25.2153037806447],
              [55.3409561803691, 25.2151323732949],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6064453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e49047e5-2fff-4c2a-babd-3eb8a98031e6',
          'Modified Time': '2025-02-25T15:32:35.030Z',
          'Created Time': '2025-02-26T02:24:52.165Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f4a350b3-21e2-4021-b56f-a62857c46004',
        bbox: null,
      },
    },
    '348a548c-f9d5-4ce7-b7c5-e13bc89c2909': {
      id: '348a548c-f9d5-4ce7-b7c5-e13bc89c2909',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.334960632826, 25.2067125770502],
              [55.3351245971459, 25.2067125770502],
              [55.3351245971459, 25.2072594131099],
              [55.334960632826, 25.2072594131099],
              [55.334960632826, 25.2067125770502],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '05a397e8-5510-4842-9f09-cb0c3bda3ecb',
          'Modified Time': '2025-02-26T07:33:00.845Z',
          'Created Time': '2025-02-26T09:58:52.443Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '348a548c-f9d5-4ce7-b7c5-e13bc89c2909',
        bbox: null,
      },
    },
    '246f2226-d22b-44c2-b7c6-db05438cac1f': {
      id: '246f2226-d22b-44c2-b7c6-db05438cac1f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3215371980743, 25.3004318570045],
              [55.3217865435294, 25.3004318570045],
              [55.3217865435294, 25.300680007572],
              [55.3215371980743, 25.300680007572],
              [55.3215371980743, 25.3004318570045],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.603515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9d5d1456-ea73-4b0c-9492-7c27a3594381',
          'Modified Time': '2025-02-26T03:10:37.405Z',
          'Created Time': '2025-02-25T18:52:34.159Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '246f2226-d22b-44c2-b7c6-db05438cac1f',
        bbox: null,
      },
    },
    'd8c7e109-bd07-4adc-87c3-b66640931221': {
      id: 'd8c7e109-bd07-4adc-87c3-b66640931221',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3181565121384, 25.2929837098309],
              [55.3183210360689, 25.2929837098309],
              [55.3183210360689, 25.2931806197308],
              [55.3181565121384, 25.2931806197308],
              [55.3181565121384, 25.2929837098309],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6025390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a43c8801-0885-4447-8a9c-f03df0aa25a5',
          'Modified Time': '2025-02-26T00:17:23.248Z',
          'Created Time': '2025-02-25T19:38:57.973Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd8c7e109-bd07-4adc-87c3-b66640931221',
        bbox: null,
      },
    },
    '3b45460b-c616-4c25-baf0-42815c24fbd8': {
      id: '3b45460b-c616-4c25-baf0-42815c24fbd8',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3159012898554, 25.2948068934618],
              [55.3171794759359, 25.2948068934618],
              [55.3171794759359, 25.295094823734],
              [55.3159012898554, 25.295094823734],
              [55.3159012898554, 25.2948068934618],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5986328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ddce13e5-67f4-4106-b7dd-42ee2de1f7ed',
          'Modified Time': '2025-02-26T02:03:41.820Z',
          'Created Time': '2025-02-26T13:48:47.798Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3b45460b-c616-4c25-baf0-42815c24fbd8',
        bbox: null,
      },
    },
    '23cf6be1-de56-485c-9ce5-6a839b82e9d5': {
      id: '23cf6be1-de56-485c-9ce5-6a839b82e9d5',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.317553019607, 25.2515939829568],
              [55.3181729234102, 25.2515939829568],
              [55.3181729234102, 25.2517044784108],
              [55.317553019607, 25.2517044784108],
              [55.317553019607, 25.2515939829568],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.591796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd4f7eed-333a-47af-8912-cd74fac78d0b',
          'Modified Time': '2025-02-25T17:54:48.500Z',
          'Created Time': '2025-02-26T05:24:57.240Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '23cf6be1-de56-485c-9ce5-6a839b82e9d5',
        bbox: null,
      },
    },
    'b09284f1-7c04-4ad8-8633-6863265aae53': {
      id: 'b09284f1-7c04-4ad8-8633-6863265aae53',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3717893243058, 25.2448257536035],
              [55.3721951038463, 25.2448257536035],
              [55.3721951038463, 25.2448892395266],
              [55.3717893243058, 25.2448892395266],
              [55.3717893243058, 25.2448257536035],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5888671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b01345d1-8017-4aea-9291-0a228f7ed65f',
          'Modified Time': '2025-02-26T14:58:48.517Z',
          'Created Time': '2025-02-25T17:53:30.183Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b09284f1-7c04-4ad8-8633-6863265aae53',
        bbox: null,
      },
    },
    'e2b21dda-affd-4e88-831b-9b9c66d79d67': {
      id: 'e2b21dda-affd-4e88-831b-9b9c66d79d67',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2740545250954, 25.2246592781302],
              [55.2741347555321, 25.2246592781302],
              [55.2741347555321, 25.225002639854],
              [55.2740545250954, 25.225002639854],
              [55.2740545250954, 25.2246592781302],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5849609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8511c987-1f7a-46f7-abfc-fc3d17a8be07',
          'Modified Time': '2025-02-26T14:42:24.580Z',
          'Created Time': '2025-02-26T09:59:30.014Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e2b21dda-affd-4e88-831b-9b9c66d79d67',
        bbox: null,
      },
    },
    'b3367bd8-1fe5-46ea-bc31-eae6b84b7d45': {
      id: 'b3367bd8-1fe5-46ea-bc31-eae6b84b7d45',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3553338106232, 25.2506679993933],
              [55.3554024626732, 25.2506679993933],
              [55.3554024626732, 25.2509844669174],
              [55.3553338106232, 25.2509844669174],
              [55.3553338106232, 25.2506679993933],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.583984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '11d67cbc-2b4d-4682-be4b-5fc25d6ba97b',
          'Modified Time': '2025-02-25T22:19:36.974Z',
          'Created Time': '2025-02-26T10:50:14.477Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b3367bd8-1fe5-46ea-bc31-eae6b84b7d45',
        bbox: null,
      },
    },
    'ab4c8194-feaa-44c3-bbfe-d2dbb3fb1ec1': {
      id: 'ab4c8194-feaa-44c3-bbfe-d2dbb3fb1ec1',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.405999691655, 25.2859198418012],
              [55.4064254162643, 25.2859198418012],
              [55.4064254162643, 25.2860547780068],
              [55.405999691655, 25.2860547780068],
              [55.405999691655, 25.2859198418012],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.56982421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd3fa1cc-c434-4746-8084-9f72ce668fd8',
          'Modified Time': '2025-02-26T00:42:02.236Z',
          'Created Time': '2025-02-25T19:51:34.112Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ab4c8194-feaa-44c3-bbfe-d2dbb3fb1ec1',
        bbox: null,
      },
    },
    '248afddc-54c1-414e-bef8-f06cb53461c9': {
      id: '248afddc-54c1-414e-bef8-f06cb53461c9',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3567712555829, 25.2954212098674],
              [55.3569772995168, 25.2954212098674],
              [55.3569772995168, 25.2955539533685],
              [55.3567712555829, 25.2955539533685],
              [55.3567712555829, 25.2954212098674],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.564453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '362c1cb2-e4b5-4895-9af4-5aafe6d4a045',
          'Modified Time': '2025-02-26T08:45:12.666Z',
          'Created Time': '2025-02-25T15:51:01.370Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '248afddc-54c1-414e-bef8-f06cb53461c9',
        bbox: null,
      },
    },
    '1e040c1d-2b68-49d1-b490-82e5e9cc149a': {
      id: '1e040c1d-2b68-49d1-b490-82e5e9cc149a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3244048148033, 25.2227793356003],
              [55.3249606138669, 25.2227793356003],
              [55.3249606138669, 25.2231879569576],
              [55.3244048148033, 25.2231879569576],
              [55.3244048148033, 25.2227793356003],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5e33525a-7b41-4747-92d1-011af48657bd',
          'Modified Time': '2025-02-26T09:30:54.107Z',
          'Created Time': '2025-02-26T02:57:56.250Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1e040c1d-2b68-49d1-b490-82e5e9cc149a',
        bbox: null,
      },
    },
    'abfab2e1-fea0-4aae-b7b1-361e89471a5c': {
      id: 'abfab2e1-fea0-4aae-b7b1-361e89471a5c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3587910404612, 25.2558919843499],
              [55.3588385284771, 25.2558919843499],
              [55.3588385284771, 25.2561283242498],
              [55.3587910404612, 25.2561283242498],
              [55.3587910404612, 25.2558919843499],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9a43180f-32e2-49af-a7f9-375054577760',
          'Modified Time': '2025-02-25T16:51:23.107Z',
          'Created Time': '2025-02-26T02:30:50.028Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'abfab2e1-fea0-4aae-b7b1-361e89471a5c',
        bbox: null,
      },
    },
    'eecd7ef6-9494-47e6-afde-c99115fce20e': {
      id: 'eecd7ef6-9494-47e6-afde-c99115fce20e',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.314129344113, 25.2901031897533],
              [55.3143575603323, 25.2901031897533],
              [55.3143575603323, 25.290289132625],
              [55.314129344113, 25.290289132625],
              [55.314129344113, 25.2901031897533],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3fd02757-a0ce-49b7-9f1f-afb1c68edbf7',
          'Modified Time': '2025-02-25T22:00:40.581Z',
          'Created Time': '2025-02-25T18:00:02.750Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eecd7ef6-9494-47e6-afde-c99115fce20e',
        bbox: null,
      },
    },
    '6ff1838e-623c-4f56-b221-289c900c2545': {
      id: '6ff1838e-623c-4f56-b221-289c900c2545',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3565433734935, 25.2059706125703],
              [55.3566224744099, 25.2059706125703],
              [55.3566224744099, 25.2060443515266],
              [55.3565433734935, 25.2060443515266],
              [55.3565433734935, 25.2059706125703],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55517578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3896b2dd-9a05-4796-ba5b-f6f9cb564b76',
          'Modified Time': '2025-02-25T15:50:43.797Z',
          'Created Time': '2025-02-26T04:13:45.338Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6ff1838e-623c-4f56-b221-289c900c2545',
        bbox: null,
      },
    },
    '1040130f-62d9-406c-ac44-7f5536345217': {
      id: '1040130f-62d9-406c-ac44-7f5536345217',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3857696474011, 25.239513010102],
              [55.385827366364, 25.239513010102],
              [55.385827366364, 25.2398027515221],
              [55.3857696474011, 25.2398027515221],
              [55.3857696474011, 25.239513010102],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5537109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a6b7237c-6c77-41ba-89b1-e92bcd36b5dd',
          'Modified Time': '2025-02-26T13:48:21.734Z',
          'Created Time': '2025-02-26T12:20:21.026Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1040130f-62d9-406c-ac44-7f5536345217',
        bbox: null,
      },
    },
    '042e2607-8733-4992-85c2-b6ed1ae0c616': {
      id: '042e2607-8733-4992-85c2-b6ed1ae0c616',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3549958129658, 25.2529118940174],
              [55.355402385481, 25.2529118940174],
              [55.355402385481, 25.2530651158683],
              [55.3549958129658, 25.2530651158683],
              [55.3549958129658, 25.2529118940174],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55322265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd7fa03e-94fd-4218-9a8f-a9e3917dd925',
          'Modified Time': '2025-02-25T15:37:04.699Z',
          'Created Time': '2025-02-26T05:03:49.744Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '042e2607-8733-4992-85c2-b6ed1ae0c616',
        bbox: null,
      },
    },
    '7886c034-222e-4d68-9e23-6eb79321b56c': {
      id: '7886c034-222e-4d68-9e23-6eb79321b56c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3245174841237, 25.2510505137327],
              [55.3249465932592, 25.2510505137327],
              [55.3249465932592, 25.2515532042895],
              [55.3245174841237, 25.2515532042895],
              [55.3245174841237, 25.2510505137327],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55224609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fb203e7c-cc33-40ca-9b47-2e28af8e63cf',
          'Modified Time': '2025-02-25T15:35:26.306Z',
          'Created Time': '2025-02-25T19:13:24.425Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7886c034-222e-4d68-9e23-6eb79321b56c',
        bbox: null,
      },
    },
    '1def0704-8dca-448a-8618-53ebb88d6eb1': {
      id: '1def0704-8dca-448a-8618-53ebb88d6eb1',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3519111235364, 25.196887008167],
              [55.3520218980595, 25.196887008167],
              [55.3520218980595, 25.1970584475902],
              [55.3519111235364, 25.1970584475902],
              [55.3519111235364, 25.196887008167],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5517578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e988d9fd-e71d-4cd6-b177-8b35fc5c4abb',
          'Modified Time': '2025-02-26T03:52:38.970Z',
          'Created Time': '2025-02-26T01:22:47.621Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1def0704-8dca-448a-8618-53ebb88d6eb1',
        bbox: null,
      },
    },
    '4bc68825-18db-4470-8af4-0b4f011d9a9e': {
      id: '4bc68825-18db-4470-8af4-0b4f011d9a9e',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4456524689502, 25.2442647578651],
              [55.446128513585, 25.2442647578651],
              [55.446128513585, 25.2443979102198],
              [55.4456524689502, 25.2443979102198],
              [55.4456524689502, 25.2442647578651],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55126953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '12c8ade5-58e8-47c7-b2b7-cfacd61ad468',
          'Modified Time': '2025-02-26T06:35:01.427Z',
          'Created Time': '2025-02-26T08:32:50.879Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4bc68825-18db-4470-8af4-0b4f011d9a9e',
        bbox: null,
      },
    },
    '37c59752-c4cf-420a-9947-5bc1f53cf304': {
      id: '37c59752-c4cf-420a-9947-5bc1f53cf304',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3709028358969, 25.1988633232239],
              [55.3715294607404, 25.1988633232239],
              [55.3715294607404, 25.1989911927822],
              [55.3709028358969, 25.1989911927822],
              [55.3709028358969, 25.1988633232239],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5498046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6d0b62f0-4246-46fa-b6ee-5c4029d5f678',
          'Modified Time': '2025-02-26T09:15:55.677Z',
          'Created Time': '2025-02-25T19:44:19.023Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '37c59752-c4cf-420a-9947-5bc1f53cf304',
        bbox: null,
      },
    },
    'be9c709d-ce6f-47e6-bce6-98c832c3a19d': {
      id: 'be9c709d-ce6f-47e6-bce6-98c832c3a19d',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4459476366173, 25.2112260675063],
              [55.4460570930857, 25.2112260675063],
              [55.4460570930857, 25.2114511736045],
              [55.4459476366173, 25.2114511736045],
              [55.4459476366173, 25.2112260675063],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54833984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'cdbe40eb-20c1-4cf2-bbe1-c542fab27117',
          'Modified Time': '2025-02-26T14:17:16.467Z',
          'Created Time': '2025-02-26T01:35:30.050Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'be9c709d-ce6f-47e6-bce6-98c832c3a19d',
        bbox: null,
      },
    },
    '76f840f8-f496-4f1b-a312-1861f7d5bf7a': {
      id: '76f840f8-f496-4f1b-a312-1861f7d5bf7a',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3384945748761, 25.293510868187],
              [55.3385953974451, 25.293510868187],
              [55.3385953974451, 25.2940508319129],
              [55.3384945748761, 25.2940508319129],
              [55.3384945748761, 25.293510868187],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54833984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '20621018-3930-49e2-ab51-3ce9d525cc5e',
          'Modified Time': '2025-02-26T09:18:40.401Z',
          'Created Time': '2025-02-25T23:39:23.497Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '76f840f8-f496-4f1b-a312-1861f7d5bf7a',
        bbox: null,
      },
    },
    '0a8df166-03b3-43e1-8037-5a0a9957d595': {
      id: '0a8df166-03b3-43e1-8037-5a0a9957d595',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4170993214944, 25.2827087485953],
              [55.4172409961464, 25.2827087485953],
              [55.4172409961464, 25.2828254632975],
              [55.4170993214944, 25.2828254632975],
              [55.4170993214944, 25.2827087485953],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5478515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '00153f56-ee96-4e47-824b-dd95d0b907db',
          'Modified Time': '2025-02-26T08:32:56.981Z',
          'Created Time': '2025-02-26T00:10:38.627Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0a8df166-03b3-43e1-8037-5a0a9957d595',
        bbox: null,
      },
    },
    '1d86d20c-dac4-4097-b802-bf06466c9117': {
      id: '1d86d20c-dac4-4097-b802-bf06466c9117',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3417962559872, 25.2652728189876],
              [55.3418756768564, 25.2652728189876],
              [55.3418756768564, 25.2656518510092],
              [55.3417962559872, 25.2656518510092],
              [55.3417962559872, 25.2652728189876],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54736328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8912a9e7-efdc-434f-a175-57e17538cd83',
          'Modified Time': '2025-02-25T18:59:13.395Z',
          'Created Time': '2025-02-25T17:55:11.146Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1d86d20c-dac4-4097-b802-bf06466c9117',
        bbox: null,
      },
    },
    '0e9568a3-1050-48e6-b039-63790e1bb5b7': {
      id: '0e9568a3-1050-48e6-b039-63790e1bb5b7',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2755540657157, 25.2618955667581],
              [55.2762725705304, 25.2618955667581],
              [55.2762725705304, 25.2621287013319],
              [55.2755540657157, 25.2621287013319],
              [55.2755540657157, 25.2618955667581],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54736328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd9036a6c-bebf-47a8-a529-cc288aff6d50',
          'Modified Time': '2025-02-25T17:27:52.433Z',
          'Created Time': '2025-02-26T05:25:06.009Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0e9568a3-1050-48e6-b039-63790e1bb5b7',
        bbox: null,
      },
    },
    '28decc01-8517-4174-af43-893b34e337ee': {
      id: '28decc01-8517-4174-af43-893b34e337ee',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2740002008856, 25.26185201943],
              [55.2749107625348, 25.26185201943],
              [55.2749107625348, 25.2623575893921],
              [55.2740002008856, 25.2623575893921],
              [55.2740002008856, 25.26185201943],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3ffa34b7-8862-4a60-8c79-7beb04c2457a',
          'Modified Time': '2025-02-26T08:40:57.315Z',
          'Created Time': '2025-02-25T15:19:02.211Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '28decc01-8517-4174-af43-893b34e337ee',
        bbox: null,
      },
    },
    'ee5c6988-9017-4c44-994f-f3d918aec2c4': {
      id: 'ee5c6988-9017-4c44-994f-f3d918aec2c4',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3142196170035, 25.290016207723],
              [55.3144477962664, 25.290016207723],
              [55.3144477962664, 25.2901662306132],
              [55.3142196170035, 25.2901662306132],
              [55.3142196170035, 25.290016207723],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54248046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ba7b6217-e23f-4cda-bee2-74fb2696ea45',
          'Modified Time': '2025-02-26T13:09:55.316Z',
          'Created Time': '2025-02-25T21:51:22.276Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ee5c6988-9017-4c44-994f-f3d918aec2c4',
        bbox: null,
      },
    },
    '0a60ca22-7bf9-4011-840c-8ed265b1edf3': {
      id: '0a60ca22-7bf9-4011-840c-8ed265b1edf3',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3485735798433, 25.2565584146955],
              [55.3486423020921, 25.2565584146955],
              [55.3486423020921, 25.256883881874],
              [55.3485735798433, 25.256883881874],
              [55.3485735798433, 25.2565584146955],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54150390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6f22607c-1e1e-4cbf-87d2-b0bcc337e419',
          'Modified Time': '2025-02-26T07:30:40.461Z',
          'Created Time': '2025-02-25T22:12:26.010Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0a60ca22-7bf9-4011-840c-8ed265b1edf3',
        bbox: null,
      },
    },
    '075f5308-374e-4b33-957b-6f16d2da8c9b': {
      id: '075f5308-374e-4b33-957b-6f16d2da8c9b',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.274002609315, 25.2601989989716],
              [55.2740833101063, 25.2601989989716],
              [55.2740833101063, 25.2607850308362],
              [55.274002609315, 25.2607850308362],
              [55.274002609315, 25.2601989989716],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5400390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2d76766a-722f-4a71-adac-7b37149ef397',
          'Modified Time': '2025-02-25T22:05:34.669Z',
          'Created Time': '2025-02-26T01:25:31.959Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '075f5308-374e-4b33-957b-6f16d2da8c9b',
        bbox: null,
      },
    },
    'f2cfa5b0-f35f-4a0a-831f-fd32cbba2ad1': {
      id: 'f2cfa5b0-f35f-4a0a-831f-fd32cbba2ad1',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3142667277275, 25.2905854205467],
              [55.3145586217436, 25.2905854205467],
              [55.3145586217436, 25.2908142759127],
              [55.3142667277275, 25.2908142759127],
              [55.3142667277275, 25.2905854205467],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5380859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4a65f623-38a6-4027-ac0d-af6572edb71e',
          'Modified Time': '2025-02-26T08:12:50.477Z',
          'Created Time': '2025-02-26T05:19:53.915Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f2cfa5b0-f35f-4a0a-831f-fd32cbba2ad1',
        bbox: null,
      },
    },
    '3f960e77-bb26-4594-b5c7-2aae29445ffa': {
      id: '3f960e77-bb26-4594-b5c7-2aae29445ffa',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790385818728, 25.2565278170627],
              [55.3791175085341, 25.2565278170627],
              [55.3791175085341, 25.2567451636467],
              [55.3790385818728, 25.2567451636467],
              [55.3790385818728, 25.2565278170627],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.537109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2fd22252-9d02-4f1e-aa89-69b24a908244',
          'Modified Time': '2025-02-25T17:49:38.484Z',
          'Created Time': '2025-02-25T23:14:38.671Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3f960e77-bb26-4594-b5c7-2aae29445ffa',
        bbox: null,
      },
    },
    'c58501e9-f477-4646-bce7-d283a6b75093': {
      id: 'c58501e9-f477-4646-bce7-d283a6b75093',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3506824421683, 25.2393483045259],
              [55.350803889611, 25.2393483045259],
              [55.350803889611, 25.2394925700733],
              [55.3506824421683, 25.2394925700733],
              [55.3506824421683, 25.2393483045259],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.53662109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ee372971-3638-4bcf-9bb5-b231ea916cc8',
          'Modified Time': '2025-02-26T10:41:41.499Z',
          'Created Time': '2025-02-26T02:42:07.719Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c58501e9-f477-4646-bce7-d283a6b75093',
        bbox: null,
      },
    },
    '7080214a-dc8e-469d-9395-0909dfc66622': {
      id: '7080214a-dc8e-469d-9395-0909dfc66622',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3228880619238, 25.2490403788908],
              [55.3238570826914, 25.2490403788908],
              [55.3238570826914, 25.249202617485],
              [55.3228880619238, 25.249202617485],
              [55.3228880619238, 25.2490403788908],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.53271484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9c6a84f6-dc55-49f4-b265-da3e2253d3b2',
          'Modified Time': '2025-02-26T00:32:06.851Z',
          'Created Time': '2025-02-25T16:13:25.292Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7080214a-dc8e-469d-9395-0909dfc66622',
        bbox: null,
      },
    },
    '3815e766-c4e6-4757-9237-f4f0f91bd72e': {
      id: '3815e766-c4e6-4757-9237-f4f0f91bd72e',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.365543640357, 25.2506158640484],
              [55.3658545739856, 25.2506158640484],
              [55.3658545739856, 25.2517771721759],
              [55.365543640357, 25.2517771721759],
              [55.365543640357, 25.2506158640484],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.53173828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '354ec939-92e9-4da2-8520-b87984a10564',
          'Modified Time': '2025-02-26T07:52:00.213Z',
          'Created Time': '2025-02-26T10:21:51.586Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3815e766-c4e6-4757-9237-f4f0f91bd72e',
        bbox: null,
      },
    },
    '70f34e1c-88f4-41e5-8f1b-4f8dedd8a29f': {
      id: '70f34e1c-88f4-41e5-8f1b-4f8dedd8a29f',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3418677408702, 25.3163750139026],
              [55.3426356642151, 25.3163750139026],
              [55.3426356642151, 25.3173341710391],
              [55.3418677408702, 25.3173341710391],
              [55.3418677408702, 25.3163750139026],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.52978515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ecfbf18e-6493-435f-8d56-d08b0ad0b501',
          'Modified Time': '2025-02-26T08:43:01.534Z',
          'Created Time': '2025-02-25T18:07:17.014Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '70f34e1c-88f4-41e5-8f1b-4f8dedd8a29f',
        bbox: null,
      },
    },
    'd7d26829-d474-4911-8354-dc0e44865ff0': {
      id: 'd7d26829-d474-4911-8354-dc0e44865ff0',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3680395396134, 25.2590585329704],
              [55.3682346465229, 25.2590585329704],
              [55.3682346465229, 25.2591017772971],
              [55.3680395396134, 25.2591017772971],
              [55.3680395396134, 25.2590585329704],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.52685546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '245b0831-7bc7-4289-b5c1-b5853d1e6714',
          'Modified Time': '2025-02-25T17:18:29.078Z',
          'Created Time': '2025-02-26T08:31:23.637Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd7d26829-d474-4911-8354-dc0e44865ff0',
        bbox: null,
      },
    },
    'bc044053-86f2-4195-8078-49c910fc35a8': {
      id: 'bc044053-86f2-4195-8078-49c910fc35a8',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2771690829452, 25.2705522274061],
              [55.2773553978435, 25.2705522274061],
              [55.2773553978435, 25.2706316631502],
              [55.2771690829452, 25.2706316631502],
              [55.2771690829452, 25.2705522274061],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c9f2c107-1128-432e-8c40-6128372963a0',
          'Modified Time': '2025-02-26T07:41:47.921Z',
          'Created Time': '2025-02-26T12:03:22.045Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bc044053-86f2-4195-8078-49c910fc35a8',
        bbox: null,
      },
    },
    'e7e6b73c-5ab7-452d-92df-c3a49625306c': {
      id: 'e7e6b73c-5ab7-452d-92df-c3a49625306c',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3933130563939, 25.2110582590136],
              [55.3937387758593, 25.2110582590136],
              [55.3937387758593, 25.2111119703304],
              [55.3933130563939, 25.2111119703304],
              [55.3933130563939, 25.2110582590136],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5146484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '22b47bc3-51d0-4abd-b95e-ddb062131952',
          'Modified Time': '2025-02-26T04:38:30.086Z',
          'Created Time': '2025-02-25T20:49:50.627Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e7e6b73c-5ab7-452d-92df-c3a49625306c',
        bbox: null,
      },
    },
    'e18831d6-f6b2-4630-93c6-5265f6a8f2fd': {
      id: 'e18831d6-f6b2-4630-93c6-5265f6a8f2fd',
      data: {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4453162525327, 25.2152685489845],
              [55.4457815379147, 25.2152685489845],
              [55.4457815379147, 25.2154376769316],
              [55.4453162525327, 25.2154376769316],
              [55.4453162525327, 25.2152685489845],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.51416015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '809c7fde-1cd7-4f89-8e6d-3e1f9bb9a768',
          'Modified Time': '2025-02-26T07:37:51.543Z',
          'Created Time': '2025-02-26T05:42:01.261Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e18831d6-f6b2-4630-93c6-5265f6a8f2fd',
        bbox: null,
      },
    },
    'b18831d2-a6b2-4630-92c7-5265f6a8f2ab': {
      id: 'b18831d2-a6b2-4630-92c7-5265f6a8f2ab',
      data: {
        type: 'Feature',
        id: 'b18831d2-a6b2-4630-92c7-5265f6a8f2ab',
        bbox: null,
        properties: {
          confidence: 0.91416015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '24JAN12102356-P1BS-508132033010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c12419ac-b23d-45a4-af40-237f4b135310',
          'Modified Time': '2025-02-25T19:08:01.696Z',
          'Created Time': '2025-02-26T05:55:33.951Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JAN12102356-P1BS-508132033010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [51.61639675218592, 25.26910382748828, -30.041321927681565],
              [51.617096795242674, 25.269103365936374, -30.041913272812963],
              [51.61709697784822, 25.268567812938908, -30.042587460950017],
              [51.616396933993975, 25.268568275532708, -30.04199642688036],
              [51.61639675218592, 25.26910382748828, -30.041321927681565],
            ],
          ],
        },
      },
    },
    'b27831b5-a6b2-4630-23c8-2265f6a8f2bc': {
      id: 'b27831b5-a6b2-4630-23c8-2265f6a8f2bc',
      data: {
        type: 'Feature',
        id: 'b27831b5-a6b2-4630-23c8-2265f6a8f2bc',
        bbox: null,
        properties: {
          confidence: 0.93,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '24JAN12102356-P1BS-508132033010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8b1181db-b8ad-4705-ac21-d16d35639b69',
          'Modified Time': '2025-02-25T19:42:23.176Z',
          'Created Time': '2025-02-26T05:48:40.405Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JAN12102356-P1BS-508132033010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [51.61504616031048, 25.267036897981683, -30.042787085287273],
              [51.6154640569455, 25.26703662817989, -30.04313936829567],
              [51.615464182724025, 25.26671928057477, -30.043539312668145],
              [51.61504628573267, 25.26671955002236, -30.043187139555812],
              [51.61504616031048, 25.267036897981683, -30.042787085287273],
            ],
          ],
        },
      },
    },
  },
  status: 'SUCCESS',
  geojson: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3779021750854, 25.247063959114],
              [55.378455049925, 25.247063959114],
              [55.378455049925, 25.2475086516452],
              [55.3779021750854, 25.2475086516452],
              [55.3779021750854, 25.247063959114],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.1,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'ec46cc2b-8fdf-42ab-b29e-d2f3672d15fe',
          'Modified Time': '2025-02-25T17:32:13.378Z',
          'Created Time': '2025-02-26T06:30:46.533Z',
          Affiliation: 'p',
        },
        id: 'b5b89e4f-1ecf-4416-bddf-01b433c39180',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3554130088114, 25.2504767196982],
              [55.3558829572848, 25.2504767196982],
              [55.3558829572848, 25.2510767213694],
              [55.3554130088114, 25.2510767213694],
              [55.3554130088114, 25.2504767196982],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9306640625,
          classificationIndex: '0',
          classificationName: 'military aircraft',
          Symbology: 'a-.-A-M-F',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': 'f84a514b-f3cc-4cfb-9546-7cbd29eced8b',
          'Modified Time': '2025-02-26T14:59:03.000Z',
          'Created Time': '2025-02-26T03:45:27.666Z',
          Affiliation: 'p',
        },
        id: 'b5b89e4f-1ecf-4416-bddf-11b433c39181',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3418861369978, 25.2650543644429],
              [55.3424943051504, 25.2650543644429],
              [55.3424943051504, 25.2656501861453],
              [55.3418861369978, 25.2656501861453],
              [55.3418861369978, 25.2650543644429],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          Confidence: 3550.76,
          'classification Index': '0',
          'classification Name': 'freight car',
          Symbology: 'a-.-G-E-V-T',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1a1ee386-c439-4a8c-9339-32a7bc046b38',
          'Modified Time': '2025-02-26T06:29:39.500Z',
          'Created Time': '2025-02-25T23:20:26.248Z',
          Affiliation: 'p',
        },
        id: 'c5b89e4f-1ecf-4416-bddf-21b433c39182',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3799532585906, 25.2518520484748],
              [55.3805060022002, 25.2518520484748],
              [55.3805060022002, 25.252440331247],
              [55.3799532585906, 25.252440331247],
              [55.3799532585906, 25.2518520484748],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          Confidence: 0.9,
          'Classification Index': '0',
          'Classification Name': 'maritime vessel',
          Symbology: 'SUSPXR---------',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'a7d1980e-c435-475f-9947-e5d61d36afab',
          'Modified Time': '2025-02-25T18:04:35.982Z',
          'Created Time': '2025-02-26T09:20:08.359Z',
          Affiliation: 'p',
        },
        id: 'd5b89e4f-1ecf-4416-bddf-41b433c39184',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3707452077543, 25.2425003992719],
              [55.3712984143168, 25.2425003992719],
              [55.3712984143168, 25.2430886318924],
              [55.3707452077543, 25.2430886318924],
              [55.3707452077543, 25.2425003992719],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          Confidence: 90.3584378794184,
          ClassificationIndex: '0',
          ClassificationName: 'catamaran',
          Symbology: 'a-.-S-X-R',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': 'f21492e0-6c8d-4720-a573-e34078bf9de0',
          'Modified Time': '2025-02-25T17:46:08.710Z',
          'Created Time': '2025-02-26T10:02:45.886Z',
          Affiliation: 'p',
        },
        id: 'e5b89e4f-1ecf-4416-bddf-51b433c39185',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3571332430118, 25.2637230692996],
              [55.3574177126042, 25.2637230692996],
              [55.3574177126042, 25.2640140784225],
              [55.3571332430118, 25.2640140784225],
              [55.3571332430118, 25.2637230692996],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.4584378794184,
          classificationIndex: '0',
          classificationName: 'submarine',
          Symbology: 'a-.-U-S',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '948d526a-d4b1-4d96-8417-8a2c100a2391',
          'Modified Time': '2025-02-26T13:59:18.532Z',
          'Created Time': '2025-02-26T14:26:02.377Z',
          Affiliation: 'p',
        },
        id: 'f5b89e4f-1ecf-4416-bddf-61b433c39186',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3396269188994, 25.261718703093],
              [55.3402449045657, 25.261718703093],
              [55.3402449045657, 25.2622255167342],
              [55.3396269188994, 25.2622255167342],
              [55.3396269188994, 25.261718703093],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 1,
          classificationIndex: '0',
          classificationName: 'school bus',
          Symbology: 'a-.-G-E-V-U-B',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'ae4bf777-49a0-49fa-9210-005d0a3815d7',
          'Modified Time': '2025-02-25T19:00:06.951Z',
          'Created Time': '2025-02-25T18:58:20.629Z',
          Affiliation: 'p',
        },
        id: '75b89e4f-1ecf-4416-bddf-71b433c39187',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3613185427622, 25.2622614841538],
              [55.3616880262558, 25.2622614841538],
              [55.3616880262558, 25.2626702048322],
              [55.3613185427622, 25.2626702048322],
              [55.3613185427622, 25.2622614841538],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.3584378794184,
          classificationIndex: '0',
          classificationName: 'passenger car',
          Symbology: 'a-.-G-E-V-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': 'ba84a1bd-4f8b-4fc7-8c53-08fe15d4a0ec',
          'Modified Time': '2025-02-26T13:07:13.581Z',
          'Created Time': '2025-02-26T08:19:16.088Z',
          Affiliation: 'p',
        },
        id: '85b89e4f-1ecf-4416-bddf-81b433c39188',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3588171722083, 25.2636707530527],
              [55.3591081813315, 25.2636707530527],
              [55.3591081813315, 25.2640009993608],
              [55.3588171722083, 25.2640009993608],
              [55.3588171722083, 25.2636707530527],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9609375,
          classificationIndex: '0',
          classificationName: 'tank',
          Symbology: 'a-.-G-E-V-A-T',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '47839613-5ec2-46aa-8484-5f7599383737',
          'Modified Time': '2025-02-26T03:12:46.589Z',
          'Created Time': '2025-02-26T02:55:26.257Z',
          Affiliation: 'p',
        },
        id: '95b89e4f-1ecf-4416-bddf-91b433c39189',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.343341372428, 25.2663127235175],
              [55.3436389210821, 25.2663127235175],
              [55.3436389210821, 25.2666429698256],
              [55.343341372428, 25.2666429698256],
              [55.343341372428, 25.2663127235175],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95703125,
          classificationIndex: '0',
          classificationName: 'water tower',
          Symbology: 'a-.-G-U-S-S-W',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': '3fb411f4-a347-4f1e-bcba-97995beb52a6',
          'Modified Time': '2025-02-26T05:26:31.314Z',
          'Created Time': '2025-02-25T17:33:24.301Z',
          Affiliation: 'p',
        },
        id: '10b89e4f-1ecf-4416-bddf-10b433c39110',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3437043163907, 25.2661034585302],
              [55.343995325514, 25.2661034585302],
              [55.343995325514, 25.2664337048383],
              [55.3437043163907, 25.2664337048383],
              [55.3437043163907, 25.2661034585302],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.75263671875,
          classificationIndex: '0',
          classificationName: 'barn',
          Symbology: 'a-.-G-I',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': '8875af4c-deae-4454-b263-a8bc8f11bbd0',
          'Modified Time': '2025-02-26T03:04:19.288Z',
          'Created Time': '2025-02-25T19:22:04.898Z',
          Affiliation: 'p',
        },
        id: '11b89e4f-1ecf-4416-bddf-11b433c39111',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3535626591622, 25.2665906535787],
              [55.3538536682855, 25.2665906535787],
              [55.3538536682855, 25.2669208998868],
              [55.3535626591622, 25.2669208998868],
              [55.3535626591622, 25.2665906535787],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.4501953125,
          classificationIndex: '0',
          classificationName: 'missile',
          Symbology: 'a-.-G-U-S-T-I',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2ac5300d-7f11-47bb-82a3-b9bb750359e4',
          'Modified Time': '2025-02-26T07:45:15.983Z',
          'Created Time': '2025-02-26T03:11:09.326Z',
          Affiliation: 'p',
        },
        id: 'b5b89e4f-1ecf-4416-bddf-21b433c3918a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3375604271477, 25.273712202678],
              [55.3378906734561, 25.273712202678],
              [55.3378906734561, 25.2739770536776],
              [55.3375604271477, 25.2739770536776],
              [55.3375604271477, 25.273712202678],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.8423828125,
          classificationIndex: '0',
          classificationName: 'aircraft carrier',
          Symbology: 'a-.-S-C-L-C-V',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'DISMISSED',
          'ATR Id': 'ea010956-c548-45e9-96aa-1c599953578a',
          'Modified Time': '2025-02-26T00:29:18.373Z',
          'Created Time': '2025-02-26T13:30:22.886Z',
          Affiliation: 'p',
        },
        id: '13b89e4f-1ecf-4416-bddf-13b433c39113',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3409871413183, 25.2654233473214],
              [55.3417162990092, 25.2654233473214],
              [55.3417162990092, 25.265733975037],
              [55.3409871413183, 25.265733975037],
              [55.3409871413183, 25.2654233473214],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.3384765625,
          classificationIndex: '0',
          classificationName: 'ambulance',
          Symbology: 'a-.-G-E-V-me',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'VERIFIED',
          'ATR Id': '7f6ff563-11bb-4004-8feb-4d07c53f555f',
          'Modified Time': '2025-02-26T00:12:36.062Z',
          'Created Time': '2025-02-26T08:22:45.376Z',
          Affiliation: 'p',
        },
        id: '14b89e4f-1ecf-4416-bddf-14b433c39114',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3780784735007, 25.2529241088045],
              [55.3786419193414, 25.2529241088045],
              [55.3786419193414, 25.2534223534422],
              [55.3780784735007, 25.2534223534422],
              [55.3780784735007, 25.2529241088045],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.962890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '48fbc627-ff8e-42de-a3eb-9672e4cf4695',
          'Modified Time': '2025-02-25T21:57:40.749Z',
          'Created Time': '2025-02-26T01:33:26.962Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8d59f37b-f59c-4287-b12b-5de02f5f05ca',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.370324633257, 25.2461089418671],
              [55.3710571143177, 25.2461089418671],
              [55.3710571143177, 25.2467005357712],
              [55.370324633257, 25.2467005357712],
              [55.370324633257, 25.2461089418671],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96240234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '222c0553-2d3a-4d38-b9b2-0adaedcc82e3',
          'Modified Time': '2025-02-26T07:28:59.672Z',
          'Created Time': '2025-02-26T04:36:28.107Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ada7ebfa-6949-4139-a6ba-7fc12e5dbbb5',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3627740623011, 25.2485663392449],
              [55.3634649838232, 25.2485663392449],
              [55.3634649838232, 25.2491593004392],
              [55.3627740623011, 25.2491593004392],
              [55.3627740623011, 25.2485663392449],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96240234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'cec31848-1ebb-427c-a34b-9b7b182df6ff',
          'Modified Time': '2025-02-25T22:37:16.005Z',
          'Created Time': '2025-02-25T22:16:55.254Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'fdc08085-63e9-4eb9-bbab-00328398ba75',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3946074608962, 25.239088530596],
              [55.3951486211412, 25.239088530596],
              [55.3951486211412, 25.2396769899176],
              [55.3946074608962, 25.2396769899176],
              [55.3946074608962, 25.239088530596],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '600b4092-db07-4ce0-b99d-0e36c9635e06',
          'Modified Time': '2025-02-26T08:40:49.037Z',
          'Created Time': '2025-02-25T15:30:42.523Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f689bb13-b304-40b1-a438-4c9db467b818',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3985449613743, 25.2386564065497],
              [55.3990963952808, 25.2386564065497],
              [55.3990963952808, 25.2392355499343],
              [55.3985449613743, 25.2392355499343],
              [55.3985449613743, 25.2386564065497],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd1b0a9b2-f460-4483-9cff-2d3649a3145f',
          'Modified Time': '2025-02-25T16:42:37.391Z',
          'Created Time': '2025-02-26T09:08:48.830Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '004c598e-333c-4bc7-b26a-7a4f57540953',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3657814261217, 25.253762319256],
              [55.3664616723856, 25.253762319256],
              [55.3664616723856, 25.2542300253652],
              [55.3657814261217, 25.2542300253652],
              [55.3657814261217, 25.253762319256],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1e75e08f-bea3-4484-b2c2-06d2c9ac00fe',
          'Modified Time': '2025-02-25T19:52:09.910Z',
          'Created Time': '2025-02-26T14:03:06.810Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5059b19a-23fd-48f2-9c2c-27d54b5a7d8d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3760693184496, 25.2482126592857],
              [55.3764643418442, 25.2482126592857],
              [55.3764643418442, 25.2484739058893],
              [55.3760693184496, 25.2484739058893],
              [55.3760693184496, 25.2482126592857],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a6fd2d76-0e5a-46ff-a019-3fb653c91609',
          'Modified Time': '2025-02-26T00:14:08.495Z',
          'Created Time': '2025-02-25T20:46:54.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dc4a5733-318b-40d0-8aef-fea7df5b7796',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3733931408191, 25.2494664446815],
              [55.3741043512732, 25.2494664446815],
              [55.3741043512732, 25.250058733868],
              [55.3733931408191, 25.250058733868],
              [55.3733931408191, 25.2494664446815],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e2476eaf-c37a-4da3-8428-4a31b8025e0f',
          'Modified Time': '2025-02-26T09:47:10.093Z',
          'Created Time': '2025-02-26T12:23:43.789Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '829b7fc7-4d4b-4b97-8999-6f94083e1946',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790388646336, 25.2568220938108],
              [55.3796338562675, 25.2568220938108],
              [55.3796338562675, 25.2574360190511],
              [55.3790388646336, 25.2574360190511],
              [55.3790388646336, 25.2568220938108],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8f23a98b-348e-4640-b921-8ee47648980f',
          'Modified Time': '2025-02-26T07:30:24.529Z',
          'Created Time': '2025-02-25T17:56:38.011Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5ddc7a87-9be7-4362-933a-a5fe34988f68',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3726099053522, 25.2563328867518],
              [55.3728891873027, 25.2563328867518],
              [55.3728891873027, 25.2565798667673],
              [55.3726099053522, 25.2565798667673],
              [55.3726099053522, 25.2563328867518],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '156d3c09-226a-48e4-92ac-d2772f64e0c2',
          'Modified Time': '2025-02-25T15:36:38.137Z',
          'Created Time': '2025-02-26T10:09:40.158Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aa86620e-7cb3-478f-a81c-489c71a845a3',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3801665179132, 25.2460136112015],
              [55.3807191774769, 25.2460136112015],
              [55.3807191774769, 25.2466018443369],
              [55.3801665179132, 25.2466018443369],
              [55.3801665179132, 25.2460136112015],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96142578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '975212a5-dcef-426b-aeb1-7fa54d7d0b77',
          'Modified Time': '2025-02-25T19:52:58.688Z',
          'Created Time': '2025-02-25T23:44:54.254Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c44e300c-3b6e-43d3-995c-02debf89973f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4012872039471, 25.2371775377836],
              [55.4019855763331, 25.2371775377836],
              [55.4019855763331, 25.2377610100943],
              [55.4012872039471, 25.2377610100943],
              [55.4012872039471, 25.2371775377836],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '77ece1d3-40a1-4ed3-a1c4-f2bde74707c5',
          'Modified Time': '2025-02-26T02:15:37.888Z',
          'Created Time': '2025-02-25T23:17:08.003Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c477d578-7794-450b-9d7c-b6fb024790da',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3419087131288, 25.2601378106148],
              [55.3424428047064, 25.2601378106148],
              [55.3424428047064, 25.2607359216325],
              [55.3419087131288, 25.2607359216325],
              [55.3419087131288, 25.2601378106148],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e627668d-4299-4ca4-97b0-9d7c2acf2d15',
          'Modified Time': '2025-02-25T19:31:57.244Z',
          'Created Time': '2025-02-25T16:42:56.925Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '54fecec6-42d9-4e41-a8cb-0e1c94a3e1c8',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3404854470778, 25.2639440494353],
              [55.3408715129184, 25.2639440494353],
              [55.3408715129184, 25.2641877606439],
              [55.3404854470778, 25.2641877606439],
              [55.3404854470778, 25.2639440494353],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0adb0127-8c67-4e6b-8851-3af46f07d29d',
          'Modified Time': '2025-02-26T10:59:38.281Z',
          'Created Time': '2025-02-26T05:48:54.683Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f5150dd3-d301-4cf9-b320-e128bad44c6b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3689167713257, 25.244827614363],
              [55.3694068921442, 25.244827614363],
              [55.3694068921442, 25.2453101851324],
              [55.3689167713257, 25.2453101851324],
              [55.3689167713257, 25.244827614363],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7576d83f-4354-47f8-9ae0-780e00e490bc',
          'Modified Time': '2025-02-26T08:14:34.819Z',
          'Created Time': '2025-02-25T18:00:53.835Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ae68bdaa-5119-48e3-b4a4-4be56e705e59',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3621671416802, 25.2466757385429],
              [55.3625626865364, 25.2466757385429],
              [55.3625626865364, 25.2472690259867],
              [55.3621671416802, 25.2472690259867],
              [55.3621671416802, 25.2466757385429],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0bc6e41b-0c0c-4f46-84c3-e62acf4f85db',
          'Modified Time': '2025-02-25T20:01:58.580Z',
          'Created Time': '2025-02-26T03:41:58.260Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '45277ac7-ab88-4945-a165-5701a6bb29c4',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3970565389944, 25.2377261873303],
              [55.3976080474368, 25.2377261873303],
              [55.3976080474368, 25.2383142969197],
              [55.3970565389944, 25.2383142969197],
              [55.3970565389944, 25.2377261873303],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e49c77a1-a8ef-42ef-a892-f57c3c3743b1',
          'Modified Time': '2025-02-26T14:04:52.764Z',
          'Created Time': '2025-02-25T22:59:59.426Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '25c14077-d6b5-4a2f-b8cf-06be50c9945a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3640363744368, 25.2599140649079],
              [55.3644636506592, 25.2599140649079],
              [55.3644636506592, 25.2601654125656],
              [55.3640363744368, 25.2601654125656],
              [55.3640363744368, 25.2599140649079],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e13e975d-9801-46c9-9824-abd27cc36fa3',
          'Modified Time': '2025-02-25T22:46:11.078Z',
          'Created Time': '2025-02-25T20:24:39.404Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '063ba487-94ea-445c-afb2-16c0b0d2283b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3468246014595, 25.2575043641642],
              [55.3473266381241, 25.2575043641642],
              [55.3473266381241, 25.2579867433464],
              [55.3468246014595, 25.2579867433464],
              [55.3468246014595, 25.2575043641642],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.96044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '45375b56-e1c9-4385-b1d7-819ea16378ac',
          'Modified Time': '2025-02-26T05:36:34.090Z',
          'Created Time': '2025-02-26T06:22:59.232Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bb7b2397-b303-4dc3-b357-db8b8b753529',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3978184468427, 25.2372971734029],
              [55.3983699028925, 25.2372971734029],
              [55.3983699028925, 25.2378852769353],
              [55.3978184468427, 25.2378852769353],
              [55.3978184468427, 25.2372971734029],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9599609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e76b1a6b-255a-4987-898f-cc54ad7d53e0',
          'Modified Time': '2025-02-26T07:56:58.530Z',
          'Created Time': '2025-02-26T11:54:12.767Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '95c0ed4a-7559-4622-8a45-161cd2691446',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3779042458055, 25.2522221872167],
              [55.3782886930312, 25.2522221872167],
              [55.3782886930312, 25.25247481571],
              [55.3779042458055, 25.25247481571],
              [55.3779042458055, 25.2522221872167],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd935b0d0-e0c1-42b4-817c-7d38e52b90cc',
          'Modified Time': '2025-02-26T08:36:39.677Z',
          'Created Time': '2025-02-25T17:21:26.720Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2532795c-aa4e-4a07-8d8d-05ae937c7101',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.35112020074, 25.2528840475904],
              [55.3516746352281, 25.2528840475904],
              [55.3516746352281, 25.2534813842291],
              [55.35112020074, 25.2534813842291],
              [55.35112020074, 25.2528840475904],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0e4b72f3-cf5e-434f-a51c-f09e0cb156a7',
          'Modified Time': '2025-02-26T03:37:14.074Z',
          'Created Time': '2025-02-25T15:50:15.231Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'be9c40d8-809e-40bd-aabb-d465d3c8817d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3661808380069, 25.2467446332209],
              [55.3668820345835, 25.2467446332209],
              [55.3668820345835, 25.2472744199971],
              [55.3661808380069, 25.2472744199971],
              [55.3661808380069, 25.2467446332209],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '77fc1db3-2aa4-4e3a-b37f-0fd11eac02c1',
          'Modified Time': '2025-02-26T10:53:48.560Z',
          'Created Time': '2025-02-26T11:35:44.493Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5eb77d69-3b39-47d0-a661-dc88629d5503',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3632926494913, 25.2609666140538],
              [55.3639731658458, 25.2609666140538],
              [55.3639731658458, 25.2615600308644],
              [55.3632926494913, 25.2615600308644],
              [55.3632926494913, 25.2609666140538],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6ace8087-d615-4c75-9016-2ebd6bad878c',
          'Modified Time': '2025-02-26T09:27:33.065Z',
          'Created Time': '2025-02-26T10:42:29.571Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c56129d9-0092-4966-b96f-00ba1a7d867b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3745807127017, 25.2403855143638],
              [55.375365425696, 25.2403855143638],
              [55.375365425696, 25.2410381853131],
              [55.3745807127017, 25.2410381853131],
              [55.3745807127017, 25.2403855143638],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5496a43b-0c87-48d9-bdc0-162f3b7d0590',
          'Modified Time': '2025-02-26T13:02:44.462Z',
          'Created Time': '2025-02-26T04:14:46.114Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b9acbbbe-b480-49f7-a2a3-d876045d0fe7',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790369936968, 25.2523699664181],
              [55.3795687361099, 25.2523699664181],
              [55.3795687361099, 25.2529499443304],
              [55.3790369936968, 25.2529499443304],
              [55.3790369936968, 25.2523699664181],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5657799c-0420-4f5e-bcd3-8c180427020a',
          'Modified Time': '2025-02-25T17:44:11.630Z',
          'Created Time': '2025-02-26T06:52:28.647Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f430610f-0765-4563-b4a9-4b95c545046c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3574290613555, 25.2491781058732],
              [55.3579620731587, 25.2491781058732],
              [55.3579620731587, 25.2497581274564],
              [55.3574290613555, 25.2497581274564],
              [55.3574290613555, 25.2491781058732],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd35ef24e-bea7-4747-bb95-7d1067015572',
          'Modified Time': '2025-02-25T15:35:26.737Z',
          'Created Time': '2025-02-25T21:05:02.061Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b3bc33f8-5db1-45e7-aaff-c66cadc517fb',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3597353166635, 25.2502742710053],
              [55.3602787539289, 25.2502742710053],
              [55.3602787539289, 25.2508629362068],
              [55.3597353166635, 25.2508629362068],
              [55.3597353166635, 25.2502742710053],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8accaa2f-7e26-49d7-a3d5-dd135ed28335',
          'Modified Time': '2025-02-26T10:42:06.098Z',
          'Created Time': '2025-02-25T18:44:21.234Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8a8caddf-6336-40cd-bb48-628b81fd796a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.381738095681, 25.2508884978591],
              [55.3823960637154, 25.2508884978591],
              [55.3823960637154, 25.2514016928114],
              [55.381738095681, 25.2514016928114],
              [55.381738095681, 25.2508884978591],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.958984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '50e3326e-2129-4a86-a951-05c768fb1fe5',
          'Modified Time': '2025-02-26T02:59:16.441Z',
          'Created Time': '2025-02-26T06:05:50.065Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'fcdb0a46-7d3f-4faa-a33b-94eb4aa87b68',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3798891919162, 25.2388676733685],
              [55.3805787511944, 25.2388676733685],
              [55.3805787511944, 25.2393169498346],
              [55.3798891919162, 25.2393169498346],
              [55.3798891919162, 25.2388676733685],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95849609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '116815f5-e2c2-4881-a281-f72d04bc4283',
          'Modified Time': '2025-02-25T18:49:07.790Z',
          'Created Time': '2025-02-26T04:42:56.103Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '01fb0f4b-2c38-4254-a074-f2905623029b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3660988974393, 25.2597769497039],
              [55.3663889375344, 25.2597769497039],
              [55.3663889375344, 25.2600954095568],
              [55.3660988974393, 25.2600954095568],
              [55.3660988974393, 25.2597769497039],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95849609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8cb9e3c6-7bf4-4895-b664-c464329c36d5',
          'Modified Time': '2025-02-26T10:19:17.302Z',
          'Created Time': '2025-02-26T02:26:14.841Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1951a720-09db-4851-8e44-ff39c2d65850',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3972603330761, 25.2362089337577],
              [55.3979905446036, 25.2362089337577],
              [55.3979905446036, 25.236782440758],
              [55.3972603330761, 25.236782440758],
              [55.3972603330761, 25.2362089337577],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e4ddc930-fcac-435e-9358-ebe9ef4a8144',
          'Modified Time': '2025-02-26T01:55:33.196Z',
          'Created Time': '2025-02-26T05:14:32.487Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd8152a32-69b5-44ef-bfb8-97b1a126a7c6',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4008294252565, 25.2365042860866],
              [55.4015383327001, 25.2365042860866],
              [55.4015383327001, 25.2370874218017],
              [55.4008294252565, 25.2370874218017],
              [55.4008294252565, 25.2365042860866],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c7d91a00-d8d2-46ed-9c0e-61b5ebdaf0ab',
          'Modified Time': '2025-02-26T05:33:53.558Z',
          'Created Time': '2025-02-26T08:20:07.474Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cbffcb64-d47b-4a25-8bc5-d782a6692866',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3520335995444, 25.2546822133266],
              [55.3524824303581, 25.2546822133266],
              [55.3524824303581, 25.2552469792593],
              [55.3520335995444, 25.2552469792593],
              [55.3520335995444, 25.2546822133266],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '90bc77ca-f60e-4bd9-aeb9-b421ccb026f9',
          'Modified Time': '2025-02-26T07:24:44.247Z',
          'Created Time': '2025-02-25T19:59:08.813Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd073d8e3-7e2d-4b0a-b7bc-e23883d5045a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3443606972056, 25.2630789162133],
              [55.3449052440367, 25.2630789162133],
              [55.3449052440367, 25.2636677316625],
              [55.3443606972056, 25.2636677316625],
              [55.3443606972056, 25.2630789162133],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '596bf77d-7849-4282-bdd7-bba4bf093005',
          'Modified Time': '2025-02-26T02:23:11.321Z',
          'Created Time': '2025-02-26T14:22:06.437Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dcd9f650-6d31-4cd4-83fd-f937b0bba606',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3757238935495, 25.2540655558184],
              [55.3764139174286, 25.2540655558184],
              [55.3764139174286, 25.2546585504951],
              [55.3757238935495, 25.2546585504951],
              [55.3757238935495, 25.2540655558184],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd08c363-847b-4fb9-b3f1-185996be5400',
          'Modified Time': '2025-02-25T16:18:07.156Z',
          'Created Time': '2025-02-26T04:17:32.047Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1f4833e1-3a0f-485e-a781-f016c3821268',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3958688687386, 25.2384589303067],
              [55.3963784614454, 25.2384589303067],
              [55.3963784614454, 25.2389676426456],
              [55.3958688687386, 25.2389676426456],
              [55.3958688687386, 25.2384589303067],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '13a4aa2e-8d78-422a-8f63-32a6cb574987',
          'Modified Time': '2025-02-25T23:41:31.350Z',
          'Created Time': '2025-02-26T01:13:30.527Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c48a82c9-bffe-4532-a4d7-742371e46688',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3605646659096, 25.2625198445929],
              [55.3611293155422, 25.2625198445929],
              [55.3611293155422, 25.2630361462562],
              [55.3605646659096, 25.2630361462562],
              [55.3605646659096, 25.2625198445929],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ecf0403b-2616-4171-ac2a-b9941c28e88a',
          'Modified Time': '2025-02-25T23:42:49.054Z',
          'Created Time': '2025-02-26T11:23:02.767Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '93e7a366-3c38-4e6a-a9dc-f0057e699157',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.381276110949, 25.2430466250641],
              [55.3819445574612, 25.2430466250641],
              [55.3819445574612, 25.2435145519624],
              [55.381276110949, 25.2435145519624],
              [55.381276110949, 25.2430466250641],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95751953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0b60ffa8-3124-4499-9513-40c736cdfa3d',
          'Modified Time': '2025-02-26T10:41:26.426Z',
          'Created Time': '2025-02-26T12:02:31.142Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3a53fea4-54c4-417d-bcd7-27dd7e5f4a43',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3520321818765, 25.26757046597],
              [55.3523121759797, 25.26757046597],
              [55.3523121759797, 25.2678893125594],
              [55.3520321818765, 25.2678893125594],
              [55.3520321818765, 25.26757046597],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4ef33f78-83cd-40b8-b692-02540f7dd6a2',
          'Modified Time': '2025-02-25T18:43:58.325Z',
          'Created Time': '2025-02-25T22:35:38.025Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '969e8b70-a29a-4773-aa2f-c2aaee5dc17f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.388472190992, 25.2357959711409],
              [55.3890031739385, 25.2357959711409],
              [55.3890031739385, 25.2363757834152],
              [55.388472190992, 25.2363757834152],
              [55.388472190992, 25.2357959711409],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95654296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'df82c672-4b87-4ef1-ba83-90ead5ebbbd3',
          'Modified Time': '2025-02-25T21:11:10.183Z',
          'Created Time': '2025-02-26T06:18:16.524Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9e2c86c3-d6d8-4aa7-b1b7-2daf39deab7f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3735069237831, 25.2432826991042],
              [55.3741969541962, 25.2432826991042],
              [55.3741969541962, 25.2438845583313],
              [55.3735069237831, 25.2438845583313],
              [55.3735069237831, 25.2432826991042],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ace09bf5-ced1-46a8-b5ed-c1c0f474aee6',
          'Modified Time': '2025-02-26T04:23:38.035Z',
          'Created Time': '2025-02-25T20:08:48.877Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3ef63248-4ce2-4260-9b4f-cb09b0a8c704',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.372920771524, 25.2556062674805],
              [55.3736110301097, 25.2556062674805],
              [55.3736110301097, 25.2561992829152],
              [55.372920771524, 25.2561992829152],
              [55.372920771524, 25.2556062674805],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '51604926-3657-4158-b689-5b8cef34bcf5',
          'Modified Time': '2025-02-26T03:29:44.180Z',
          'Created Time': '2025-02-26T01:14:03.218Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '78ba3b4c-3080-4794-8505-7bba48c0d027',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3541135608812, 25.2663030576886],
              [55.3543934182662, 25.2663030576886],
              [55.3543934182662, 25.2666218384448],
              [55.3541135608812, 25.2666218384448],
              [55.3541135608812, 25.2663030576886],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '511352be-1dee-4153-a52e-2f4a883516e5',
          'Modified Time': '2025-02-26T13:46:37.751Z',
          'Created Time': '2025-02-26T07:00:11.863Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '70833d5a-1d02-48ed-86b3-5aab86ae51a4',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3954225835855, 25.239240892951],
              [55.3958585648795, 25.239240892951],
              [55.3958585648795, 25.2397967742032],
              [55.3954225835855, 25.2397967742032],
              [55.3954225835855, 25.239240892951],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '38e9f653-1344-4828-af7f-6a9c415845ff',
          'Modified Time': '2025-02-26T04:26:25.139Z',
          'Created Time': '2025-02-25T18:47:32.095Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'af954ae7-24aa-4f8b-a5c4-4c9fa791a644',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.380563518773, 25.2394049718601],
              [55.3811160751495, 25.2394049718601],
              [55.3811160751495, 25.239993147376],
              [55.380563518773, 25.239993147376],
              [55.380563518773, 25.2394049718601],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9560546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '257c1327-bbc4-4cee-a9d7-6ed3e062d759',
          'Modified Time': '2025-02-26T02:10:41.562Z',
          'Created Time': '2025-02-26T02:56:02.536Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '025f8c1f-5f62-496b-a2eb-d121bef23967',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3432873422555, 25.2642389280071],
              [55.3439694057678, 25.2642389280071],
              [55.3439694057678, 25.2648324139259],
              [55.3432873422555, 25.2648324139259],
              [55.3432873422555, 25.2642389280071],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0d21cb37-0919-43af-b3ab-7801864bee59',
          'Modified Time': '2025-02-26T10:22:06.130Z',
          'Created Time': '2025-02-26T12:00:46.502Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a02ad6f4-b1e8-4354-a3de-99208f698f09',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3906859428011, 25.2344175058053],
              [55.391374587497, 25.2344175058053],
              [55.391374587497, 25.2349654146616],
              [55.3906859428011, 25.2349654146616],
              [55.3906859428011, 25.2344175058053],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '52a3ad63-2344-44d7-b1b0-433d3ee23cbb',
          'Modified Time': '2025-02-26T11:53:20.838Z',
          'Created Time': '2025-02-25T20:21:45.509Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5dc10694-8791-4f5c-98a4-0fdea00ec46f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3480347565722, 25.256329842481],
              [55.3483676242488, 25.256329842481],
              [55.3483676242488, 25.2565841541312],
              [55.3480347565722, 25.2565841541312],
              [55.3480347565722, 25.256329842481],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '72021921-919f-4e4f-81e2-fcf618608971',
          'Modified Time': '2025-02-26T00:08:18.193Z',
          'Created Time': '2025-02-25T17:11:39.218Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '432163ce-bf37-4c4c-9086-71239c7b863d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3811297225094, 25.2451593208357],
              [55.3817982081398, 25.2451593208357],
              [55.3817982081398, 25.2456272692182],
              [55.3811297225094, 25.2456272692182],
              [55.3811297225094, 25.2451593208357],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9ce44f24-a4e4-47da-a244-0445399d110c',
          'Modified Time': '2025-02-26T14:06:29.727Z',
          'Created Time': '2025-02-25T16:45:22.560Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '428c41e6-83ee-427b-be58-395346bbaddb',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.347596329262, 25.2556260259354],
              [55.3481088589901, 25.2556260259354],
              [55.3481088589901, 25.2560631746323],
              [55.347596329262, 25.2560631746323],
              [55.347596329262, 25.2556260259354],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4947051f-c61d-468e-9560-d841944b7c68',
          'Modified Time': '2025-02-25T18:27:08.230Z',
          'Created Time': '2025-02-25T23:29:20.498Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7399737e-fad1-4f20-9d6c-d09c37ff326d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3420353579151, 25.2613159546494],
              [55.3423684781046, 25.2613159546494],
              [55.3423684781046, 25.2615702929939],
              [55.3420353579151, 25.2615702929939],
              [55.3420353579151, 25.2613159546494],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b53ec33f-b77a-403a-8cf6-7408dd006225',
          'Modified Time': '2025-02-25T20:18:36.218Z',
          'Created Time': '2025-02-25T23:23:59.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b34aea30-579a-46f0-922a-e57593a96d6a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3526355522306, 25.2541435611035],
              [55.3531899863988, 25.2541435611035],
              [55.3531899863988, 25.2547319221541],
              [55.3526355522306, 25.2547319221541],
              [55.3526355522306, 25.2541435611035],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '918f7575-c33a-4570-a126-3a01df241fc7',
          'Modified Time': '2025-02-26T11:24:47.381Z',
          'Created Time': '2025-02-25T17:23:13.770Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd9f66c4c-a9af-4e6d-8cb2-fc98a1ad2827',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3392811329712, 25.259686287252],
              [55.339794200543, 25.259686287252],
              [55.339794200543, 25.2601952965436],
              [55.3392811329712, 25.2601952965436],
              [55.3392811329712, 25.259686287252],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.955078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '54c6cdb8-e11e-45a6-9c50-2739b8f53a70',
          'Modified Time': '2025-02-26T07:22:16.679Z',
          'Created Time': '2025-02-25T16:35:18.922Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '41a12e40-d289-4cec-a315-e5191a755fcd',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3999740956501, 25.2380235774687],
              [55.4006515578898, 25.2380235774687],
              [55.4006515578898, 25.2386077208499],
              [55.3999740956501, 25.2386077208499],
              [55.3999740956501, 25.2380235774687],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c1b3bf6d-4fe0-40e4-b189-d243a9ae7ce7',
          'Modified Time': '2025-02-25T19:31:41.347Z',
          'Created Time': '2025-02-26T01:58:15.696Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4bc09d6f-33ca-4f9e-84ff-b5fff0aa6313',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3634118926448, 25.2459075047345],
              [55.3639550521938, 25.2459075047345],
              [55.3639550521938, 25.2464871478062],
              [55.3634118926448, 25.2464871478062],
              [55.3634118926448, 25.2459075047345],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4c1dd771-f32c-4246-8615-0058e3430558',
          'Modified Time': '2025-02-26T01:58:00.813Z',
          'Created Time': '2025-02-25T22:44:38.554Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '48dcf0ec-b2e9-4973-9654-324d40609da2',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3700376738982, 25.2575655514006],
              [55.3702748689493, 25.2575655514006],
              [55.3702748689493, 25.2577959130681],
              [55.3700376738982, 25.2577959130681],
              [55.3700376738982, 25.2575655514006],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '09f50eba-1818-423d-85c4-57a37aafcbb1',
          'Modified Time': '2025-02-26T14:09:17.834Z',
          'Created Time': '2025-02-25T15:25:06.996Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e2fe2709-bceb-4108-ab52-70e45808af18',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3965468222954, 25.2317360466122],
              [55.3969408398411, 25.2317360466122],
              [55.3969408398411, 25.2320241060444],
              [55.3965468222954, 25.2320241060444],
              [55.3965468222954, 25.2317360466122],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95458984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6439ff37-b300-4760-b5a7-debe3c68274c',
          'Modified Time': '2025-02-26T14:31:05.007Z',
          'Created Time': '2025-02-26T02:35:28.124Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '09434267-15ab-4953-8160-7e17a5bfcb3b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3595407940313, 25.2633586969943],
              [55.3598310749379, 25.2633586969943],
              [55.3598310749379, 25.2636771830727],
              [55.3595407940313, 25.2636771830727],
              [55.3595407940313, 25.2633586969943],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9541015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fb31ac40-6eac-4fd5-b1b7-34501a64dc61',
          'Modified Time': '2025-02-26T07:00:34.112Z',
          'Created Time': '2025-02-26T09:47:22.566Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9c2ea151-f2e1-46f2-86e2-0ed8d7bc6c05',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3660276456264, 25.2454770049712],
              [55.3666655552233, 25.2454770049712],
              [55.3666655552233, 25.2460356954068],
              [55.3660276456264, 25.2460356954068],
              [55.3660276456264, 25.2454770049712],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9541015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3ca926b1-91e9-42e4-abde-0a21097b1554',
          'Modified Time': '2025-02-25T15:56:52.430Z',
          'Created Time': '2025-02-26T08:06:18.359Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aa889fac-c859-4faf-9d12-5e3287ebba0c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4004266545644, 25.2386966677438],
              [55.401093581978, 25.2386966677438],
              [55.401093581978, 25.2392811471261],
              [55.4004266545644, 25.2392811471261],
              [55.4004266545644, 25.2386966677438],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a2e701d3-86eb-4aba-b717-8abf30705faf',
          'Modified Time': '2025-02-26T10:26:04.370Z',
          'Created Time': '2025-02-25T22:46:41.673Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f9602b95-63b1-49b4-9cb7-f9cbc6862ca7',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3667106639449, 25.25943300359],
              [55.3670112280134, 25.25943300359],
              [55.3670112280134, 25.2597601043413],
              [55.3667106639449, 25.2597601043413],
              [55.3667106639449, 25.25943300359],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '093ccdfd-2c5c-4e15-a4ac-ee908b82c869',
          'Modified Time': '2025-02-25T23:57:28.346Z',
          'Created Time': '2025-02-25T23:26:12.404Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd932f0c0-9cf9-4458-ad59-5216456c2bdf',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3717771706275, 25.2566436182783],
              [55.3720670025363, 25.2566436182783],
              [55.3720670025363, 25.2569620549123],
              [55.3717771706275, 25.2569620549123],
              [55.3717771706275, 25.2566436182783],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '91d7c147-e294-4cb6-a437-4c9035643d44',
          'Modified Time': '2025-02-25T16:51:12.174Z',
          'Created Time': '2025-02-25T18:14:47.265Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '052aa977-58e7-4ead-81bd-36fc587867d6',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3743758813145, 25.2574233389593],
              [55.3747605135283, 25.2574233389593],
              [55.3747605135283, 25.2576759964931],
              [55.3743758813145, 25.2576759964931],
              [55.3743758813145, 25.2574233389593],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '14deb8ae-2e74-431a-8e7b-5ce142e34e45',
          'Modified Time': '2025-02-26T06:11:59.549Z',
          'Created Time': '2025-02-26T07:26:59.981Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b4318171-bd9e-4d3c-b254-5d2ca252497b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3399687705674, 25.2591736276816],
              [55.3403336698754, 25.2591736276816],
              [55.3403336698754, 25.2594179791742],
              [55.3399687705674, 25.2594179791742],
              [55.3399687705674, 25.2591736276816],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7cf5d512-1b52-4807-8f8d-0ccb49971f97',
          'Modified Time': '2025-02-26T06:28:32.819Z',
          'Created Time': '2025-02-26T05:00:31.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3ad06ded-d977-4fda-9aa0-32aefe1083e7',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3756669425063, 25.2572778167594],
              [55.3759882915733, 25.2572778167594],
              [55.3759882915733, 25.2575234792586],
              [55.3756669425063, 25.2575234792586],
              [55.3756669425063, 25.2572778167594],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e2f365be-8b71-46fe-8008-f85e41f6ce1f',
          'Modified Time': '2025-02-26T06:02:08.362Z',
          'Created Time': '2025-02-26T03:05:34.619Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7005a797-2fe9-4452-a435-dff6b0b127aa',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3558242695822, 25.2654004605785],
              [55.3561041274047, 25.2654004605785],
              [55.3561041274047, 25.2657192919763],
              [55.3558242695822, 25.2657192919763],
              [55.3558242695822, 25.2654004605785],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '76bd0356-964c-4d0b-a345-c40d2893fbaf',
          'Modified Time': '2025-02-26T08:46:28.721Z',
          'Created Time': '2025-02-26T00:21:14.958Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd0687d54-ad19-4281-a561-4b5cc05ff4cd',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3490124168876, 25.2533958803791],
              [55.3495459890029, 25.2533958803791],
              [55.3495459890029, 25.2539759622347],
              [55.3490124168876, 25.2539759622347],
              [55.3490124168876, 25.2533958803791],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd6aab15-ff75-47be-bacf-e232debf790d',
          'Modified Time': '2025-02-26T12:13:32.432Z',
          'Created Time': '2025-02-25T20:28:45.986Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'daa5fc4a-a68d-4cf3-8e42-1fd6ac87feb1',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.35489544063, 25.2538990410795],
              [55.3553970713517, 25.2538990410795],
              [55.3553970713517, 25.2545249703511],
              [55.35489544063, 25.2545249703511],
              [55.35489544063, 25.2538990410795],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd956714a-ed43-4ab0-8e36-e6148aee9d46',
          'Modified Time': '2025-02-26T00:07:24.485Z',
          'Created Time': '2025-02-26T12:27:43.526Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b336b876-8a0e-4d0d-937d-3e23f75e14f8',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3753822648967, 25.2569352796014],
              [55.3756509294161, 25.2569352796014],
              [55.3756509294161, 25.2571736169215],
              [55.3753822648967, 25.2571736169215],
              [55.3753822648967, 25.2569352796014],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'df36658e-2bc9-4dbb-8a29-56f69cbbbbc4',
          'Modified Time': '2025-02-25T16:15:08.885Z',
          'Created Time': '2025-02-25T21:57:34.351Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e1514645-040b-4e43-8df1-e9cd2b78852f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3569647366166, 25.2647681873481],
              [55.3572656709814, 25.2647681873481],
              [55.3572656709814, 25.265086352979],
              [55.3569647366166, 25.265086352979],
              [55.3569647366166, 25.2647681873481],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95263671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a0e0e404-250a-47dc-8bee-51da3a8991f9',
          'Modified Time': '2025-02-25T22:59:45.509Z',
          'Created Time': '2025-02-25T17:05:02.468Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'df498090-317a-4d74-8906-9f3f87876112',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.36485941131, 25.2604608576452],
              [55.3651600469784, 25.2604608576452],
              [55.3651600469784, 25.260778992111],
              [55.36485941131, 25.260778992111],
              [55.36485941131, 25.2604608576452],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9521484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1776e4cd-0fee-4c5d-a4c1-3e91874ff489',
          'Modified Time': '2025-02-26T06:50:51.183Z',
          'Created Time': '2025-02-25T17:43:50.926Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a4491235-dce4-4efb-81f5-d75f830fbff3',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3621684856146, 25.2579765902589],
              [55.3624903065792, 25.2579765902589],
              [55.3624903065792, 25.2582312356583],
              [55.3621684856146, 25.2582312356583],
              [55.3621684856146, 25.2579765902589],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9521484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8b7bbf65-b3c5-4ee7-ae1a-fda0a44f1cff',
          'Modified Time': '2025-02-26T12:28:41.037Z',
          'Created Time': '2025-02-26T11:25:03.745Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ed29fca7-10a4-4bdf-9aef-b94e4cd8afef',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3583845287562, 25.2533258499447],
              [55.358717004668, 25.2533258499447],
              [55.358717004668, 25.2535801430748],
              [55.3583845287562, 25.2535801430748],
              [55.3583845287562, 25.2533258499447],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9521484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'af929b95-05ed-4d5c-a7fa-a3758ab8adb8',
          'Modified Time': '2025-02-26T00:04:18.051Z',
          'Created Time': '2025-02-26T07:39:05.099Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e0582775-8db1-4118-953b-36cc73db1267',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3544884562368, 25.2660911605225],
              [55.3547894342002, 25.2660911605225],
              [55.3547894342002, 25.2664182505835],
              [55.3544884562368, 25.2664182505835],
              [55.3544884562368, 25.2660911605225],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7733e6ee-ebaf-4fc2-901b-00aecde00cd0',
          'Modified Time': '2025-02-26T09:42:52.228Z',
          'Created Time': '2025-02-26T14:09:57.052Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '91e96022-d93d-444d-af4a-04b10b9d6ecb',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3474957171385, 25.2572050031155],
              [55.3477969178118, 25.2572050031155],
              [55.3477969178118, 25.2575051993924],
              [55.3474957171385, 25.2575051993924],
              [55.3474957171385, 25.2572050031155],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '479ea1c5-54e3-4ab0-b584-b77a618ef2ac',
          'Modified Time': '2025-02-25T19:57:35.044Z',
          'Created Time': '2025-02-26T02:34:01.797Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '16359a8f-2067-4dd6-ae36-b8cefdb45e50',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4017344666747, 25.2378555931432],
              [55.4024433238292, 25.2378555931432],
              [55.4024433238292, 25.2384387422286],
              [55.4017344666747, 25.2384387422286],
              [55.4017344666747, 25.2378555931432],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6485d0f4-b322-4f6b-8cfd-10fa44573735',
          'Modified Time': '2025-02-26T07:41:24.087Z',
          'Created Time': '2025-02-25T17:12:24.039Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6bba42bf-038d-4170-93f3-8e2d9f3cc345',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3449695829082, 25.2584626026327],
              [55.3452603614709, 25.2584626026327],
              [55.3452603614709, 25.2590055110576],
              [55.3449695829082, 25.2590055110576],
              [55.3449695829082, 25.2584626026327],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95166015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e03f0ba8-3b15-48a3-b03f-ca96ad91a61a',
          'Modified Time': '2025-02-26T06:56:25.870Z',
          'Created Time': '2025-02-25T18:59:07.090Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cd71def1-b475-4227-bfca-284f5da6db44',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3621688607028, 25.2618335704613],
              [55.3624273685061, 25.2618335704613],
              [55.3624273685061, 25.2623594475719],
              [55.3621688607028, 25.2623594475719],
              [55.3621688607028, 25.2618335704613],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.951171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e0fdc00f-5864-465b-a8bd-5b453af93664',
          'Modified Time': '2025-02-25T20:10:56.506Z',
          'Created Time': '2025-02-26T06:35:44.115Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7fdda0eb-f739-48c2-93cd-3bd46b0a8c73',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.372416998463, 25.2462167116383],
              [55.3727699961704, 25.2462167116383],
              [55.3727699961704, 25.2464882460255],
              [55.372416998463, 25.2464882460255],
              [55.372416998463, 25.2462167116383],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.951171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'eb7ca64a-9ca2-4a02-ac30-459260e09d81',
          'Modified Time': '2025-02-25T16:25:06.339Z',
          'Created Time': '2025-02-26T07:27:03.420Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '26b54553-461b-43b7-978b-9097fdcdd324',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3691467371047, 25.2580283407929],
              [55.3694366649515, 25.2580283407929],
              [55.3694366649515, 25.258346787944],
              [55.3691467371047, 25.258346787944],
              [55.3691467371047, 25.2580283407929],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95068359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9fe86b55-2c96-4fba-a8b3-e7e81647715e',
          'Modified Time': '2025-02-25T19:12:18.547Z',
          'Created Time': '2025-02-25T17:26:33.632Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f5d57916-306c-48c1-8d97-e71a80be9def',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3422927442449, 25.2669647843738],
              [55.3425836211626, 25.2669647843738],
              [55.3425836211626, 25.2672833151196],
              [55.3422927442449, 25.2672833151196],
              [55.3422927442449, 25.2669647843738],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95068359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '01da893f-98b0-439e-a567-fbe30ec2a0c4',
          'Modified Time': '2025-02-26T04:57:38.380Z',
          'Created Time': '2025-02-26T03:26:59.495Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a3e60e03-8b6d-4cfa-a526-9f79fce3a025',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3473269784708, 25.2551816729212],
              [55.3476809943537, 25.2551816729212],
              [55.3476809943537, 25.2554263365844],
              [55.3473269784708, 25.2554263365844],
              [55.3473269784708, 25.2551816729212],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.95068359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ca022188-2d57-492f-8461-b54f6f0d44bb',
          'Modified Time': '2025-02-25T19:04:13.459Z',
          'Created Time': '2025-02-26T02:17:52.031Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9f9eeec6-db36-48cc-aaa4-236dadd0e57c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3148248274562, 25.2834392896937],
              [55.3152015105802, 25.2834392896937],
              [55.3152015105802, 25.2836924041632],
              [55.3148248274562, 25.2836924041632],
              [55.3148248274562, 25.2834392896937],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9501953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '180c9d9d-357b-4018-81e3-656fe4783b73',
          'Modified Time': '2025-02-26T12:08:36.181Z',
          'Created Time': '2025-02-26T11:22:16.778Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c866ae6a-a17a-4a90-a384-1e1f12eed262',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3746184202989, 25.2577798697799],
              [55.3749714288171, 25.2577798697799],
              [55.3749714288171, 25.2580245458268],
              [55.3746184202989, 25.2580245458268],
              [55.3746184202989, 25.2577798697799],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9501953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2dad31a3-c925-4094-a8fa-c3083b724341',
          'Modified Time': '2025-02-25T17:06:43.449Z',
          'Created Time': '2025-02-25T16:45:58.088Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aec3fb69-b1d8-4311-a940-3af980866294',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3429344114322, 25.2598617227177],
              [55.3432252116993, 25.2598617227177],
              [55.3432252116993, 25.2601532932619],
              [55.3429344114322, 25.2601532932619],
              [55.3429344114322, 25.2598617227177],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94970703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '43c4eb6c-5be9-4dd6-8712-2f72eaf7243e',
          'Modified Time': '2025-02-26T01:24:04.995Z',
          'Created Time': '2025-02-26T04:29:47.413Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e98008ac-1672-4635-990a-463ca766ecb7',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3536013779243, 25.266601712509],
              [55.3539023569336, 25.266601712509],
              [55.3539023569336, 25.2669467537747],
              [55.3536013779243, 25.2669467537747],
              [55.3536013779243, 25.266601712509],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94970703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c87b6eb4-1967-494d-897f-3c46ca7c1a0f',
          'Modified Time': '2025-02-26T00:24:06.865Z',
          'Created Time': '2025-02-26T04:35:30.313Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '92c379dc-be87-4400-95c6-dbf4c130aa88',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3722890931192, 25.2419741684296],
              [55.3728212234672, 25.2419741684296],
              [55.3728212234672, 25.242257974449],
              [55.3722890931192, 25.242257974449],
              [55.3722890931192, 25.2419741684296],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'eeb6858f-2397-415e-baa6-13bfcc1768af',
          'Modified Time': '2025-02-26T06:15:53.619Z',
          'Created Time': '2025-02-26T01:31:04.717Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f47269aa-6e24-4666-b261-b9decf002af2',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3424111005509, 25.2597962001998],
              [55.342712489577, 25.2597962001998],
              [55.342712489577, 25.2600874381769],
              [55.3424111005509, 25.2600874381769],
              [55.3424111005509, 25.2597962001998],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0c3b5801-0d48-4ee0-8210-0178e33b0d21',
          'Modified Time': '2025-02-26T12:58:55.625Z',
          'Created Time': '2025-02-25T16:50:18.207Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '92d2eb68-c84d-41e9-a982-7adf2b976839',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3397775500315, 25.2619140120987],
              [55.3401213279424, 25.2619140120987],
              [55.3401213279424, 25.2621590438566],
              [55.3397775500315, 25.2621590438566],
              [55.3397775500315, 25.2619140120987],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9e439f19-3815-4c82-bdd4-23e7fff5e0a7',
          'Modified Time': '2025-02-26T05:02:35.637Z',
          'Created Time': '2025-02-25T23:27:38.964Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a6122658-6efa-4381-9513-da30f7251cc6',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3493767264722, 25.2550968514622],
              [55.3496884189022, 25.2550968514622],
              [55.3496884189022, 25.2554326073473],
              [55.3493767264722, 25.2554326073473],
              [55.3493767264722, 25.2550968514622],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7f8d6189-3fe4-40c0-a91c-55fb15222466',
          'Modified Time': '2025-02-25T16:36:24.454Z',
          'Created Time': '2025-02-26T14:40:34.975Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f501d1f5-046e-4c74-9736-2f8afabb3b6b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3679340577518, 25.2587575878424],
              [55.3682240376662, 25.2587575878424],
              [55.3682240376662, 25.2590401457381],
              [55.3679340577518, 25.2590401457381],
              [55.3679340577518, 25.2587575878424],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6c370d77-becc-48b9-ac79-c414b3eac73b',
          'Modified Time': '2025-02-25T17:42:52.794Z',
          'Created Time': '2025-02-26T01:58:04.476Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7f2a29ca-8648-42d0-9e79-59f19d6ac435',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3673223856587, 25.2590973728071],
              [55.3676123808249, 25.2590973728071],
              [55.3676123808249, 25.2594158276666],
              [55.3673223856587, 25.2594158276666],
              [55.3673223856587, 25.2590973728071],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '590493f0-95d1-4445-8dda-7c291891e418',
          'Modified Time': '2025-02-26T05:56:02.694Z',
          'Created Time': '2025-02-25T18:57:19.837Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dfa839a5-90cf-4737-b9cc-a8a3aa9f8519',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3514088124262, 25.2679058217033],
              [55.351699393575, 25.2679058217033],
              [55.351699393575, 25.2682243399453],
              [55.3514088124262, 25.2682243399453],
              [55.3514088124262, 25.2679058217033],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9482421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b7caf64d-b62e-46f2-9bc1-12228605d3a6',
          'Modified Time': '2025-02-26T14:27:33.220Z',
          'Created Time': '2025-02-25T18:10:22.948Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6691f26f-270d-4eff-adcc-49b1611d6351',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3601424672397, 25.2630192138797],
              [55.3604538363442, 25.2630192138797],
              [55.3604538363442, 25.2633370364601],
              [55.3601424672397, 25.2633370364601],
              [55.3601424672397, 25.2630192138797],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9482421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '55e1b424-fc98-4ee7-9d77-b411ac133798',
          'Modified Time': '2025-02-26T02:01:14.284Z',
          'Created Time': '2025-02-25T21:00:07.083Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b38d4c87-0e1b-431b-9ffb-a63c4363842c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3436684491291, 25.262591691607],
              [55.3439698241759, 25.262591691607],
              [55.3439698241759, 25.2629188471047],
              [55.3436684491291, 25.2629188471047],
              [55.3436684491291, 25.262591691607],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9482421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '41e39e24-72e6-4ea6-bc30-3784ff452b4d',
          'Modified Time': '2025-02-26T02:10:13.752Z',
          'Created Time': '2025-02-26T03:28:15.837Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '767400ba-4633-4898-8e11-db07a3641b1d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.345518586141, 25.2615723328692],
              [55.345809319244, 25.2615723328692],
              [55.345809319244, 25.2618908366051],
              [55.345518586141, 25.2618908366051],
              [55.345518586141, 25.2615723328692],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ea111f71-f7bc-4f84-b873-d5208fb96912',
          'Modified Time': '2025-02-26T09:49:12.922Z',
          'Created Time': '2025-02-25T23:13:30.287Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bf466764-91bf-4d0d-bfe6-b53e368f08bb',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.365550387385, 25.2601212285756],
              [55.3657771517674, 25.2601212285756],
              [55.3657771517674, 25.2604416731654],
              [55.365550387385, 25.2604416731654],
              [55.365550387385, 25.2601212285756],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '81618e6a-54d0-455f-a640-26969ee805b1',
          'Modified Time': '2025-02-25T18:00:59.864Z',
          'Created Time': '2025-02-25T20:35:00.705Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eb7f1e68-222f-4f27-bf82-878516231c69',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3526607879612, 25.2672583779687],
              [55.352951321096, 25.2672583779687],
              [55.352951321096, 25.2675589403818],
              [55.3526607879612, 25.2675589403818],
              [55.3526607879612, 25.2672583779687],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9d1f984c-a291-4501-a181-977013ff5a85',
          'Modified Time': '2025-02-25T20:24:09.655Z',
          'Created Time': '2025-02-25T19:31:55.322Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5b062927-6f2f-4d73-9700-fec4f1447d9f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3722889866408, 25.2416729102725],
              [55.3728000389795, 25.2416729102725],
              [55.3728000389795, 25.2419484058374],
              [55.3722889866408, 25.2419484058374],
              [55.3722889866408, 25.2416729102725],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8cfbbc96-d749-4853-987b-fff9a68300a7',
          'Modified Time': '2025-02-26T01:25:22.627Z',
          'Created Time': '2025-02-26T03:27:05.677Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '952fcd90-7a7b-4c47-b7ee-977269c06e70',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3414116727091, 25.2607480488964],
              [55.3417236777201, 25.2607480488964],
              [55.3417236777201, 25.2610479363466],
              [55.3414116727091, 25.2610479363466],
              [55.3414116727091, 25.2607480488964],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.947265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '04826152-0492-47e4-ac96-760e9b15a405',
          'Modified Time': '2025-02-25T23:23:05.308Z',
          'Created Time': '2025-02-26T12:17:24.579Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '76996b2b-8016-4208-b92b-bb6447da181f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3698071577111, 25.2430271420959],
              [55.3703604292389, 25.2430271420959],
              [55.3703604292389, 25.2436153821373],
              [55.3698071577111, 25.2436153821373],
              [55.3698071577111, 25.2430271420959],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94677734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '491ed934-7ffe-4e90-9fea-b8c1291d5768',
          'Modified Time': '2025-02-25T17:39:31.490Z',
          'Created Time': '2025-02-26T04:46:20.856Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '654d9914-3b57-4369-8f0a-d6830bebf4ae',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3827418324637, 25.2370280791976],
              [55.383410093773, 25.2370280791976],
              [55.383410093773, 25.23749594448],
              [55.3827418324637, 25.23749594448],
              [55.3827418324637, 25.2370280791976],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94677734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c91af078-ad5d-4ced-9597-b9c460bd4c21',
          'Modified Time': '2025-02-25T17:43:51.397Z',
          'Created Time': '2025-02-25T19:08:33.072Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '80c41bae-4fcc-49b0-b482-a291b23227e7',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3480557937416, 25.2569322473678],
              [55.3483992362409, 25.2569322473678],
              [55.3483992362409, 25.2572221340812],
              [55.3480557937416, 25.2572221340812],
              [55.3480557937416, 25.2569322473678],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'f9b4e4de-12e9-4fa7-bc99-65dc0895a3de',
          'Modified Time': '2025-02-25T18:33:04.526Z',
          'Created Time': '2025-02-26T01:54:40.533Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9f5f7695-c486-4f7c-b062-ec4c8e9a6969',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3707440761198, 25.2572172712625],
              [55.3710444895422, 25.2572172712625],
              [55.3710444895422, 25.2575353818982],
              [55.3707440761198, 25.2575353818982],
              [55.3707440761198, 25.2572172712625],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94580078125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ff61aa25-2809-40e6-bf88-4b50f0fc9fb7',
          'Modified Time': '2025-02-26T07:12:15.397Z',
          'Created Time': '2025-02-26T03:22:58.381Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b3c2ac5a-a671-4d89-96bc-df456e27cd86',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3439970611927, 25.2589215332092],
              [55.3442983993166, 25.2589215332092],
              [55.3442983993166, 25.2592486725736],
              [55.3439970611927, 25.2592486725736],
              [55.3439970611927, 25.2589215332092],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94384765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e358563e-3ec7-4043-9904-61836b6344be',
          'Modified Time': '2025-02-26T12:37:22.681Z',
          'Created Time': '2025-02-25T19:12:43.630Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '337d1258-e42c-475c-a6a2-1c20a6c9a359',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3544888307351, 25.255487179945],
              [55.3548109256919, 25.255487179945],
              [55.3548109256919, 25.2557417985331],
              [55.3544888307351, 25.2557417985331],
              [55.3544888307351, 25.255487179945],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94384765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3f976111-fa5e-4a10-ae10-6feadd3e1538',
          'Modified Time': '2025-02-26T08:07:03.779Z',
          'Created Time': '2025-02-25T23:48:14.616Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c10b7c02-a254-4e6d-b842-41428ae83c4f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3437115356896, 25.2594242193141],
              [55.3440128762618, 25.2594242193141],
              [55.3440128762618, 25.2597064772307],
              [55.3437115356896, 25.2597064772307],
              [55.3437115356896, 25.2594242193141],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.943359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '559fd276-dfc3-4271-8c7f-0937c28ade9b',
          'Modified Time': '2025-02-25T20:27:46.371Z',
          'Created Time': '2025-02-26T02:25:21.616Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'fec7421f-4e0b-4517-a97b-9f57d018bc07',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3455774084064, 25.2583607331026],
              [55.3460160660045, 25.2583607331026],
              [55.3460160660045, 25.2586117271694],
              [55.3455774084064, 25.2586117271694],
              [55.3455774084064, 25.2583607331026],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.943359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '409dc384-9ed7-4db7-9671-d4d866bb9e52',
          'Modified Time': '2025-02-26T06:50:00.490Z',
          'Created Time': '2025-02-25T16:58:10.631Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9e545541-6ea7-41c8-993e-2da7333e3fa3',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3555713485531, 25.2525665594065],
              [55.3562630519872, 25.2525665594065],
              [55.3562630519872, 25.2529082488696],
              [55.3555713485531, 25.2529082488696],
              [55.3555713485531, 25.2525665594065],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94287109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '353cb720-0ba4-42c6-874f-5a3b59ee6bfa',
          'Modified Time': '2025-02-26T11:00:05.996Z',
          'Created Time': '2025-02-26T03:59:20.317Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4d7dfb60-eb23-4d69-91df-d809b0e29383',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3445361424766, 25.2590189703708],
              [55.344826886888, 25.2590189703708],
              [55.344826886888, 25.2593195119393],
              [55.3445361424766, 25.2593195119393],
              [55.3445361424766, 25.2590189703708],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9423828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7302a7cc-8e8a-4e22-9beb-0df376cb9761',
          'Modified Time': '2025-02-26T01:57:59.781Z',
          'Created Time': '2025-02-26T04:00:52.163Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a427f497-fa3a-4e56-9a35-2374c256e36e',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3401422235743, 25.262468954464],
              [55.3404965669779, 25.262468954464],
              [55.3404965669779, 25.2627136560283],
              [55.3401422235743, 25.2627136560283],
              [55.3401422235743, 25.262468954464],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94091796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '65fa7b95-7f9f-4053-a135-d7816bbf288c',
          'Modified Time': '2025-02-26T06:46:32.057Z',
          'Created Time': '2025-02-25T22:41:34.333Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '388d334d-140c-403d-b3e0-94ebc76281a5',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3819106015753, 25.2484959148821],
              [55.3822000512773, 25.2484959148821],
              [55.3822000512773, 25.248805326397],
              [55.3819106015753, 25.248805326397],
              [55.3819106015753, 25.2484959148821],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.94091796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'aac24f49-b24c-4677-bbc2-5918c5e3a445',
          'Modified Time': '2025-02-25T18:20:19.993Z',
          'Created Time': '2025-02-25T20:52:56.240Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '11d24104-bd22-4c2e-be58-b852af1b328c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3833987991355, 25.2351927446354],
              [55.3840985693091, 25.2351927446354],
              [55.3840985693091, 25.2356595943547],
              [55.3833987991355, 25.2356595943547],
              [55.3833987991355, 25.2351927446354],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9404296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2dd8687f-415c-4c3c-a593-46d2a1574bf8',
          'Modified Time': '2025-02-26T12:20:17.615Z',
          'Created Time': '2025-02-25T19:36:03.894Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c05f2b9c-3ddc-4fcc-a815-0587b1c2023a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3531842411778, 25.2668322271185],
              [55.3535274603288, 25.2668322271185],
              [55.3535274603288, 25.2671490091476],
              [55.3531842411778, 25.2671490091476],
              [55.3531842411778, 25.2668322271185],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9404296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd0dc23d3-2696-4ee4-b94c-662758d8018a',
          'Modified Time': '2025-02-26T06:20:18.259Z',
          'Created Time': '2025-02-26T03:26:20.124Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ace81492-c715-4447-96ce-98d34c9d8153',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3712360113376, 25.2448166214366],
              [55.3719052451323, 25.2448166214366],
              [55.3719052451323, 25.2451320243283],
              [55.3712360113376, 25.2451320243283],
              [55.3712360113376, 25.2448166214366],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9384765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4b48bdc1-3442-4026-bae8-fbebcfacd6fd',
          'Modified Time': '2025-02-26T08:31:14.435Z',
          'Created Time': '2025-02-25T23:51:18.400Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '412a9d2c-d7fd-4dc2-b2a1-9389b1fc396d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3712464434845, 25.2445346340719],
              [55.3719262330982, 25.2445346340719],
              [55.3719262330982, 25.2447868899106],
              [55.3712464434845, 25.2447868899106],
              [55.3712464434845, 25.2445346340719],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93798828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ed0d48fc-5218-42d5-b23e-f735292b02a8',
          'Modified Time': '2025-02-26T08:34:50.133Z',
          'Created Time': '2025-02-25T15:35:09.394Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e86dce3b-0b50-4b41-983e-99d766c78da9',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3785274969704, 25.2561257558183],
              [55.379027791229, 25.2561257558183],
              [55.379027791229, 25.25650039259],
              [55.3785274969704, 25.25650039259],
              [55.3785274969704, 25.2561257558183],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e0a5744f-4dbc-4bae-9aca-a8e6fb89326e',
          'Modified Time': '2025-02-25T19:43:50.146Z',
          'Created Time': '2025-02-25T20:09:59.105Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '47a41f9f-c113-4a6c-8eea-78d046c15329',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3625434632229, 25.2617729339831],
              [55.3632135306917, 25.2617729339831],
              [55.3632135306917, 25.2619718206003],
              [55.3625434632229, 25.2619718206003],
              [55.3625434632229, 25.2617729339831],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9365234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fac3be81-2f0b-44f0-af5d-4e779ef38a78',
          'Modified Time': '2025-02-25T21:15:57.023Z',
          'Created Time': '2025-02-25T17:17:23.695Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eeb7f26c-1cbd-4018-b08e-1b948c202f89',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3941184774717, 25.2395178913218],
              [55.3943970012976, 25.2395178913218],
              [55.3943970012976, 25.2397647823794],
              [55.3941184774717, 25.2397647823794],
              [55.3941184774717, 25.2395178913218],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93603515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd093ce60-2f0d-4ab1-86c2-f8004874ea52',
          'Modified Time': '2025-02-25T21:02:54.720Z',
          'Created Time': '2025-02-26T00:35:31.687Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b5f1a027-bdb9-4fc8-8001-d9b33f6c5e4b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3625645482332, 25.2613739112704],
              [55.3632240412885, 25.2613739112704],
              [55.3632240412885, 25.2617436365344],
              [55.3625645482332, 25.2617436365344],
              [55.3625645482332, 25.2613739112704],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93603515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '986a0205-37c7-41a8-b6d2-14012393a6b0',
          'Modified Time': '2025-02-26T04:12:05.469Z',
          'Created Time': '2025-02-26T00:51:01.535Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '75e9bb32-76a2-4434-9f08-157a1f0f4e03',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3452708146443, 25.2584828924584],
              [55.3454770277045, 25.2584828924584],
              [55.3454770277045, 25.2590194860569],
              [55.3452708146443, 25.2590194860569],
              [55.3452708146443, 25.2584828924584],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93505859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fd4c9358-6c2b-485a-adcf-66ada46c4a40',
          'Modified Time': '2025-02-25T17:49:46.979Z',
          'Created Time': '2025-02-26T00:02:49.057Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e19df4fe-65ca-4abf-ac0f-e242e20de698',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3725993977971, 25.256578847149],
              [55.3727205942859, 25.256578847149],
              [55.3727205942859, 25.2566961856169],
              [55.3725993977971, 25.2566961856169],
              [55.3725993977971, 25.256578847149],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93408203125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd3d7c0de-9c0c-4253-ae6c-8685e6367cda',
          'Modified Time': '2025-02-26T05:53:52.750Z',
          'Created Time': '2025-02-25T20:25:51.566Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1604c841-7211-499a-aa69-63b32a2fa7ad',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3389942867767, 25.2638938174676],
              [55.3392746132769, 25.2638938174676],
              [55.3392746132769, 25.2640331264464],
              [55.3389942867767, 25.2640331264464],
              [55.3389942867767, 25.2638938174676],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '13c23dae-4b7f-41ff-94af-5eed592894f3',
          'Modified Time': '2025-02-26T02:45:11.932Z',
          'Created Time': '2025-02-26T03:33:22.647Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '20bdfccf-25a5-45e4-8883-26947cfb7691',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.378474907902, 25.2565250165203],
              [55.3790279558364, 25.2565250165203],
              [55.3790279558364, 25.2567275365759],
              [55.378474907902, 25.2567275365759],
              [55.378474907902, 25.2565250165203],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8411af6b-6083-41ba-bd6c-fdc2dea1a1bf',
          'Modified Time': '2025-02-26T02:45:53.230Z',
          'Created Time': '2025-02-26T12:41:42.681Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '44284456-c6b9-4cc3-b0f6-5fe4cf0cde67',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3492337139852, 25.2580843785013],
              [55.3499257717881, 25.2580843785013],
              [55.3499257717881, 25.2584530580158],
              [55.3492337139852, 25.2584530580158],
              [55.3492337139852, 25.2580843785013],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '941d11c0-f814-4c08-8021-5f3a9e12c030',
          'Modified Time': '2025-02-26T07:51:38.445Z',
          'Created Time': '2025-02-26T13:01:37.375Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e60b75ff-4349-4bf1-a7aa-2984e9098a8e',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3430013010792, 25.2665526391689],
              [55.3433238700362, 25.2665526391689],
              [55.3433238700362, 25.2668701714059],
              [55.3430013010792, 25.2668701714059],
              [55.3430013010792, 25.2665526391689],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93310546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '981658bc-2ec9-40f1-a4dc-ba3f3e41d9e8',
          'Modified Time': '2025-02-26T08:53:26.320Z',
          'Created Time': '2025-02-26T09:11:34.144Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e3c8f72e-cd47-4247-a0de-7f10065528d1',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3555766201424, 25.2529382129811],
              [55.356236638816, 25.2529382129811],
              [55.356236638816, 25.2531552438471],
              [55.3555766201424, 25.2531552438471],
              [55.3555766201424, 25.2529382129811],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9326171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '02453f30-0aa8-4578-b916-7de78338b712',
          'Modified Time': '2025-02-26T05:39:13.197Z',
          'Created Time': '2025-02-25T16:16:20.654Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '90317da6-4a27-493b-9e2d-b4b53b7067ca',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3698268842522, 25.2581123200979],
              [55.3699692099614, 25.2581123200979],
              [55.3699692099614, 25.2582379755751],
              [55.3698268842522, 25.2582379755751],
              [55.3698268842522, 25.2581123200979],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93212890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c7faffc8-d5dc-4285-a1aa-2d6e478fa16e',
          'Modified Time': '2025-02-26T05:10:11.832Z',
          'Created Time': '2025-02-26T08:53:39.911Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9e937ccd-68ee-48fc-a581-61571ced8b9d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3705648847511, 25.2575976992756],
              [55.3707282762795, 25.2575976992756],
              [55.3707282762795, 25.2577585859839],
              [55.3705648847511, 25.2577585859839],
              [55.3705648847511, 25.2575976992756],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93212890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8b3ac1fb-464d-4c4c-99f4-5b8c4a1f6451',
          'Modified Time': '2025-02-26T04:06:55.679Z',
          'Created Time': '2025-02-26T06:34:41.525Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bf6c8191-6748-4dc7-856c-7587de63bd89',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3618786367011, 25.2618296295535],
              [55.3621582636158, 25.2618296295535],
              [55.3621582636158, 25.2623189494817],
              [55.3618786367011, 25.2623189494817],
              [55.3618786367011, 25.2618296295535],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93115234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1080ec87-7762-434e-9eb6-4a298503c4ba',
          'Modified Time': '2025-02-26T11:18:56.539Z',
          'Created Time': '2025-02-25T23:13:17.158Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4d4c4645-ca19-4b12-9341-8635c29b3c5c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3470305271005, 25.2585419643972],
              [55.3473528820191, 25.2585419643972],
              [55.3473528820191, 25.258805596594],
              [55.3470305271005, 25.258805596594],
              [55.3470305271005, 25.2585419643972],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93115234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '080c88e1-846f-4983-9324-d41e5c8c033f',
          'Modified Time': '2025-02-25T16:58:50.627Z',
          'Created Time': '2025-02-25T20:33:57.379Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'caee8bb1-1e0e-42de-8e6d-8b641119233f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3560043965353, 25.2500801939611],
              [55.3566848739364, 25.2500801939611],
              [55.3566848739364, 25.2505568433992],
              [55.3560043965353, 25.2505568433992],
              [55.3560043965353, 25.2500801939611],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.93017578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ecec8b1b-f684-4240-baa5-d90169558438',
          'Modified Time': '2025-02-26T08:58:01.626Z',
          'Created Time': '2025-02-26T01:19:41.473Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '775f83c2-b8e5-422a-bf53-b802bdf1193b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3798987420636, 25.2480476563663],
              [55.3802093247558, 25.2480476563663],
              [55.3802093247558, 25.2483474358703],
              [55.3798987420636, 25.2483474358703],
              [55.3798987420636, 25.2480476563663],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.9296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6edb4469-ec8f-4119-852f-d2185dcac28f',
          'Modified Time': '2025-02-26T03:18:30.366Z',
          'Created Time': '2025-02-26T01:47:06.174Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3538a9d9-d81f-46ce-b2f8-b80dd6407836',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3852694808439, 25.2392013497855],
              [55.3857587648415, 25.2392013497855],
              [55.3857587648415, 25.2394864743942],
              [55.3852694808439, 25.2394864743942],
              [55.3852694808439, 25.2392013497855],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.92724609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '41d8f813-035e-43a8-81b7-1da2ec95a402',
          'Modified Time': '2025-02-26T07:01:57.802Z',
          'Created Time': '2025-02-25T15:20:35.906Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6f4f37c0-fa8c-43d0-a523-17bd82636e4a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3781144763155, 25.2389805136126],
              [55.3787515813372, 25.2389805136126],
              [55.3787515813372, 25.2392609798485],
              [55.3781144763155, 25.2392609798485],
              [55.3781144763155, 25.2389805136126],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.92724609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a6141549-f982-4bd3-b1e7-bd240239dfce',
          'Modified Time': '2025-02-26T00:14:25.182Z',
          'Created Time': '2025-02-26T09:41:21.115Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'a4a804e9-bb04-4460-9c0d-31250ee20beb',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3577355225496, 25.2644823545622],
              [55.3580258694477, 25.2644823545622],
              [55.3580258694477, 25.2646662243546],
              [55.3577355225496, 25.2646662243546],
              [55.3577355225496, 25.2644823545622],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.92431640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '07870785-c083-4f18-b8e4-8ba9e0962747',
          'Modified Time': '2025-02-26T02:07:27.997Z',
          'Created Time': '2025-02-26T07:51:20.533Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9d81dda3-91c9-4c72-b448-ecd3004e4129',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3780829603353, 25.2392917129767],
              [55.3788043898626, 25.2392917129767],
              [55.3788043898626, 25.2394439135029],
              [55.3780829603353, 25.2394439135029],
              [55.3780829603353, 25.2392917129767],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.923828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9488b261-517b-4886-96fa-cadb246be6b1',
          'Modified Time': '2025-02-26T13:19:35.598Z',
          'Created Time': '2025-02-26T14:51:48.657Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'aef8f4ef-b89f-4550-926c-1047c8b01dac',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3694683073242, 25.2578601308089],
              [55.3696528017827, 25.2578601308089],
              [55.3696528017827, 25.2580562519682],
              [55.3694683073242, 25.2580562519682],
              [55.3694683073242, 25.2578601308089],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91943359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd8788988-9734-4575-8006-662cdb453638',
          'Modified Time': '2025-02-26T03:57:44.358Z',
          'Created Time': '2025-02-25T17:24:36.026Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '359734bf-3092-42c1-9222-06d5dcd610a2',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3455774636906, 25.2581697352092],
              [55.3460795141995, 25.2581697352092],
              [55.3460795141995, 25.2583379414453],
              [55.3455774636906, 25.2583379414453],
              [55.3455774636906, 25.2581697352092],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91650390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ed719f58-7dc6-4835-b9e3-729599d104e0',
          'Modified Time': '2025-02-26T10:12:36.466Z',
          'Created Time': '2025-02-26T12:15:55.796Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3bde004a-5fd2-4fb1-8320-464577457fbd',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3554128899294, 25.2539450947326],
              [55.3555765929335, 25.2539450947326],
              [55.3555765929335, 25.2545278313001],
              [55.3554128899294, 25.2545278313001],
              [55.3554128899294, 25.2539450947326],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91259765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd29be7a5-989c-46dc-b46d-0c018cc9e412',
          'Modified Time': '2025-02-26T13:13:57.007Z',
          'Created Time': '2025-02-25T21:11:57.713Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '50bccf14-1b6c-41ca-b0da-d93b92107f63',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.338978476495, 25.2637195904385],
              [55.3393011136334, 25.2637195904385],
              [55.3393011136334, 25.263875522421],
              [55.338978476495, 25.263875522421],
              [55.338978476495, 25.2637195904385],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.912109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5758fae7-efe6-48d0-94f1-c9d4bcda1406',
          'Modified Time': '2025-02-26T08:37:58.881Z',
          'Created Time': '2025-02-25T20:42:12.657Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e3b18db7-de66-4c6d-b764-2e62e62d72c9',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3852696616676, 25.2395108561568],
              [55.3857589486385, 25.2395108561568],
              [55.3857589486385, 25.2397959830652],
              [55.3852696616676, 25.2397959830652],
              [55.3852696616676, 25.2395108561568],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91064453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4cb60ad3-ddc9-403e-8f55-b7fa7dce3d2d',
          'Modified Time': '2025-02-26T11:55:13.931Z',
          'Created Time': '2025-02-25T21:59:21.671Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6e0de562-96ee-433f-9d73-efe761f7af9b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3576932917454, 25.2643435742835],
              [55.3580364277243, 25.2643435742835],
              [55.3580364277243, 25.2644629659353],
              [55.3576932917454, 25.2644629659353],
              [55.3576932917454, 25.2643435742835],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.91015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1becb85f-d2b1-4921-9b76-e468766908ff',
          'Modified Time': '2025-02-26T15:00:08.760Z',
          'Created Time': '2025-02-25T15:25:00.999Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9d6f5f05-d961-49ce-85d8-15bde77eea7d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3620194264952, 25.2466762181532],
              [55.3621512301782, 25.2466762181532],
              [55.3621512301782, 25.2472150075376],
              [55.3620194264952, 25.2472150075376],
              [55.3620194264952, 25.2466762181532],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.87841796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '98182afe-e8f0-45d8-a42c-435fcde8cc71',
          'Modified Time': '2025-02-25T23:41:36.502Z',
          'Created Time': '2025-02-25T21:25:23.920Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2ce00b06-5ea6-421c-b44e-57c976415f14',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4000326829845, 25.2393597592969],
              [55.4002164971448, 25.2393597592969],
              [55.4002164971448, 25.2395199197858],
              [55.4000326829845, 25.2395199197858],
              [55.4000326829845, 25.2393597592969],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.87744140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'cc1ed1b3-c8c7-4b1e-a816-e7d66ba78836',
          'Modified Time': '2025-02-26T04:59:23.545Z',
          'Created Time': '2025-02-25T15:14:02.110Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '851da353-6086-489a-9c1f-60394b8c4e62',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3147602850173, 25.2900918095464],
              [55.3149992349516, 25.2900918095464],
              [55.3149992349516, 25.2904300783408],
              [55.3147602850173, 25.2904300783408],
              [55.3147602850173, 25.2900918095464],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.8759765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8d1f1b5e-5da3-4f1f-a87b-0af2d2494ad7',
          'Modified Time': '2025-02-26T02:07:48.187Z',
          'Created Time': '2025-02-26T03:15:53.069Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '785019ff-302e-4bae-8e28-72252e81d2a1',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3688113140034, 25.2448299384546],
              [55.3689113684062, 25.2448299384546],
              [55.3689113684062, 25.2452261002223],
              [55.3688113140034, 25.2452261002223],
              [55.3688113140034, 25.2448299384546],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.87451171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7f5a6d01-d440-4649-9117-b9e5c2e2d5b5',
          'Modified Time': '2025-02-25T15:47:00.958Z',
          'Created Time': '2025-02-26T09:55:48.125Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '19bec521-6106-4203-aa4c-ba1f4fde7558',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3135618755645, 25.2900225964987],
              [55.3137901835416, 25.2900225964987],
              [55.3137901835416, 25.2902893596076],
              [55.3135618755645, 25.2902893596076],
              [55.3135618755645, 25.2900225964987],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.8720703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c85c5e54-0516-4e38-bd7c-11ea4c33fb64',
          'Modified Time': '2025-02-26T11:04:40.848Z',
          'Created Time': '2025-02-26T00:11:09.008Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cf2974f7-65c5-418a-a8fb-7d5408f9ea9a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3150888664251, 25.2903599927705],
              [55.3153913884768, 25.2903599927705],
              [55.3153913884768, 25.2906423946767],
              [55.3150888664251, 25.2906423946767],
              [55.3150888664251, 25.2903599927705],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86767578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '67f0d43f-277a-4bb1-998b-1597c5982f7e',
          'Modified Time': '2025-02-26T09:19:57.802Z',
          'Created Time': '2025-02-26T06:59:19.436Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ab5f5ba2-5d4f-40cd-adb4-c6816d978435',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790384840992, 25.2561298750098],
              [55.3791594851761, 25.2561298750098],
              [55.3791594851761, 25.2565074017025],
              [55.3790384840992, 25.2565074017025],
              [55.3790384840992, 25.2561298750098],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86474609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9e09d26e-b286-4ab9-a0fe-3e65df700fd6',
          'Modified Time': '2025-02-26T10:51:01.095Z',
          'Created Time': '2025-02-26T14:01:47.614Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2d3a1051-b848-493f-9e68-3de2e482e8cd',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3143151703077, 25.2898194627726],
              [55.3146176802842, 25.2898194627726],
              [55.3146176802842, 25.2900659443053],
              [55.3143151703077, 25.2900659443053],
              [55.3143151703077, 25.2898194627726],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86474609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b18afdb3-4f5d-496a-8147-31417fb820e5',
          'Modified Time': '2025-02-26T06:26:41.476Z',
          'Created Time': '2025-02-26T05:41:59.569Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6aebf6ec-feec-4fbe-9657-d9be2bee1b77',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3138957143235, 25.2903884090885],
              [55.3141451591498, 25.2903884090885],
              [55.3141451591498, 25.2905826698327],
              [55.3138957143235, 25.2905826698327],
              [55.3138957143235, 25.2903884090885],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.86279296875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '68be4b6b-3523-4e08-a206-6f16a11a5a1b',
          'Modified Time': '2025-02-26T02:09:55.966Z',
          'Created Time': '2025-02-26T11:05:25.990Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cc4190a9-000b-4a1b-a302-5d264785cff9',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3139070056086, 25.2895421237839],
              [55.3142202104342, 25.2895421237839],
              [55.3142202104342, 25.2898690931501],
              [55.3139070056086, 25.2898690931501],
              [55.3139070056086, 25.2895421237839],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.853515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2235766f-c66d-4a0d-b990-9fd890e5d2b1',
          'Modified Time': '2025-02-26T01:24:12.212Z',
          'Created Time': '2025-02-26T11:40:54.565Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cc07f245-ab0f-4189-90d9-84874c10fceb',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3667134416663, 25.2833330775868],
              [55.3668347925012, 25.2833330775868],
              [55.3668347925012, 25.2834504610952],
              [55.3667134416663, 25.2834504610952],
              [55.3667134416663, 25.2833330775868],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.84765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c5a0ec01-edd4-4692-ac65-54b9e2a45d9d',
          'Modified Time': '2025-02-25T19:42:15.157Z',
          'Created Time': '2025-02-26T06:22:45.369Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '5d3be3c0-349d-42da-a0ff-944b0be195af',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3258718103796, 25.1809996412517],
              [55.3259723016968, 25.1809996412517],
              [55.3259723016968, 25.1810996104089],
              [55.3258718103796, 25.1810996104089],
              [55.3258718103796, 25.1809996412517],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.84619140625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4448085f-f477-4948-ba95-974662c9eca9',
          'Modified Time': '2025-02-25T19:23:16.166Z',
          'Created Time': '2025-02-25T17:08:56.432Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4e10f766-39cd-4138-b2b7-00d88a83cd33',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3756668271565, 25.2569292750933],
              [55.3757457773649, 25.2569292750933],
              [55.3757457773649, 25.2571914932534],
              [55.3756668271565, 25.2571914932534],
              [55.3756668271565, 25.2569292750933],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.845703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8483e75b-07a9-470b-9cab-66687b30d94e',
          'Modified Time': '2025-02-26T10:12:34.215Z',
          'Created Time': '2025-02-25T19:39:07.731Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c97fb086-7b7b-45f0-aad9-39c9ed6c2712',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3786142738425, 25.2380859591421],
              [55.3786932321581, 25.2380859591421],
              [55.3786932321581, 25.2381597293899],
              [55.3786142738425, 25.2381597293899],
              [55.3786142738425, 25.2380859591421],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.83740234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'dbb97468-2673-4ab4-8573-294ff2d99579',
          'Modified Time': '2025-02-26T11:21:00.756Z',
          'Created Time': '2025-02-26T08:31:01.023Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2c4f22df-6350-4a33-bedf-16a2badbddeb',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3958694486217, 25.2392511579387],
              [55.3960425945325, 25.2392511579387],
              [55.3960425945325, 25.2397166257308],
              [55.3958694486217, 25.2397166257308],
              [55.3958694486217, 25.2392511579387],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.82177734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd04c0aea-6732-4483-8373-28ffc23b511e',
          'Modified Time': '2025-02-26T00:55:26.592Z',
          'Created Time': '2025-02-25T16:28:07.013Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c0977094-5e10-48c7-8914-26ea1249034c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3519332759897, 25.2546407939008],
              [55.3520230609012, 25.2546407939008],
              [55.3520230609012, 25.2552797165475],
              [55.3519332759897, 25.2552797165475],
              [55.3519332759897, 25.2546407939008],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7978515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2efa6a18-9640-4230-95ba-850e186ed319',
          'Modified Time': '2025-02-25T18:21:17.601Z',
          'Created Time': '2025-02-25T18:13:22.563Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eafcac07-6c01-4a70-832d-987d657d3a41',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3137371289882, 25.289830506527],
              [55.3139653793953, 25.289830506527],
              [55.3139653793953, 25.2900433889765],
              [55.3137371289882, 25.2900433889765],
              [55.3137371289882, 25.289830506527],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.79150390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b1b99bc5-22b7-4b00-bf3c-150e7298bde0',
          'Modified Time': '2025-02-26T07:34:50.099Z',
          'Created Time': '2025-02-26T06:38:59.285Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd7332123-b0cb-4142-a0f7-aa1e41853fbc',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3980735318851, 25.2689551568496],
              [55.398247001349, 25.2689551568496],
              [55.398247001349, 25.2690888152225],
              [55.3980735318851, 25.2690888152225],
              [55.3980735318851, 25.2689551568496],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.787109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '31b72fcb-29d6-486b-abb0-d4d7c4322a98',
          'Modified Time': '2025-02-25T16:27:02.820Z',
          'Created Time': '2025-02-25T22:52:35.947Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8d67b1a0-2797-4a2c-a283-f55cdc698abf',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3828565879253, 25.2355059155238],
              [55.3829355049834, 25.2355059155238],
              [55.3829355049834, 25.235579681383],
              [55.3828565879253, 25.235579681383],
              [55.3828565879253, 25.2355059155238],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7822265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'dda458b5-cf46-4604-ad20-46d696bf484e',
          'Modified Time': '2025-02-25T19:04:27.957Z',
          'Created Time': '2025-02-25T17:47:11.221Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'de877fbf-ce37-4675-b26b-7367384e0938',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.365481811602, 25.2601048205083],
              [55.3655397865566, 25.2601048205083],
              [55.3655397865566, 25.2604215778806],
              [55.365481811602, 25.2604215778806],
              [55.365481811602, 25.2601048205083],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7802734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8932a7f8-7929-467a-b345-7faf90361204',
          'Modified Time': '2025-02-26T00:50:40.600Z',
          'Created Time': '2025-02-25T16:46:52.921Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '8c10b398-f8f6-422f-9264-6f68d292febc',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3754668905823, 25.3166265522335],
              [55.3755882226201, 25.3166265522335],
              [55.3755882226201, 25.3166901398282],
              [55.3754668905823, 25.3166901398282],
              [55.3754668905823, 25.3166265522335],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.77978515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0e108126-d7ce-4337-9b59-94c176106559',
          'Modified Time': '2025-02-25T18:49:59.490Z',
          'Created Time': '2025-02-25T17:03:18.926Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b5937c0c-9206-4a8a-ac04-53b67347cc1c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3146063950952, 25.2901783108899],
              [55.3147499166863, 25.2901783108899],
              [55.3147499166863, 25.2905375222608],
              [55.3146063950952, 25.2905375222608],
              [55.3146063950952, 25.2901783108899],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.77880859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8d82483e-3ec4-4ec4-ac97-2ff3fa376291',
          'Modified Time': '2025-02-26T07:46:42.383Z',
          'Created Time': '2025-02-26T12:39:22.625Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7d63b09e-0088-4f7a-a5e4-9124f5ac75dc',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3718683798813, 25.2448181124379],
              [55.3720949702313, 25.2448181124379],
              [55.3720949702313, 25.2448962167583],
              [55.3718683798813, 25.2448962167583],
              [55.3718683798813, 25.2448181124379],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.765625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ad25ddf8-534b-4637-b083-29437fadc0a8',
          'Modified Time': '2025-02-26T07:39:40.010Z',
          'Created Time': '2025-02-25T19:16:34.523Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e6ef048a-8192-4114-be25-68a81a62ea7e',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3565803096488, 25.2060929648545],
              [55.3566594104252, 25.2060929648545],
              [55.3566594104252, 25.2061667039316],
              [55.3565803096488, 25.2061667039316],
              [55.3565803096488, 25.2060929648545],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.76025390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '76a04d76-51c4-4987-8692-682c1d5278a8',
          'Modified Time': '2025-02-26T07:06:26.386Z',
          'Created Time': '2025-02-25T21:26:22.848Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1afdcb9f-01b6-4fce-b6cd-86aa2bd843d4',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.314054988129, 25.290217277387],
              [55.3142726007023, 25.290217277387],
              [55.3142726007023, 25.2904035519664],
              [55.314054988129, 25.2904035519664],
              [55.314054988129, 25.290217277387],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7490234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7f8547cc-1ee9-4e5d-bb4e-89aea5443bec',
          'Modified Time': '2025-02-26T14:51:47.315Z',
          'Created Time': '2025-02-26T08:46:36.859Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1bcd2a95-7cd6-4647-ad9a-645ab022efc6',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4062122552325, 25.2690759759009],
              [55.4063645733872, 25.2690759759009],
              [55.4063645733872, 25.2691833763084],
              [55.4062122552325, 25.2691833763084],
              [55.4062122552325, 25.2690759759009],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7431640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ac6f4fa9-a144-47c7-91cf-61dd28e12175',
          'Modified Time': '2025-02-25T15:27:41.173Z',
          'Created Time': '2025-02-26T07:06:25.732Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '2ccbfa3b-1e5f-4788-b3c1-3aabd72f2e87',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2733353385437, 25.26182257934],
              [55.2739903804582, 25.26182257934],
              [55.2739903804582, 25.2622015251816],
              [55.2733353385437, 25.2622015251816],
              [55.2733353385437, 25.26182257934],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7412109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b0911666-b329-490c-89cd-222cc98e4af5',
          'Modified Time': '2025-02-26T04:57:57.854Z',
          'Created Time': '2025-02-25T17:54:29.443Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '829a524c-418b-4e91-b441-94ef042d514b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.375720192608, 25.2589738322606],
              [55.375851877332, 25.2589738322606],
              [55.375851877332, 25.2591357068054],
              [55.375720192608, 25.2591357068054],
              [55.375720192608, 25.2589738322606],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.7353515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a943d9c5-63e0-408b-8c31-33521027c494',
          'Modified Time': '2025-02-26T06:23:27.626Z',
          'Created Time': '2025-02-26T01:25:38.026Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '62e738bc-6df4-43bf-ab3d-9f18e47b0a5d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3620682360018, 25.257898852354],
              [55.3621578996348, 25.257898852354],
              [55.3621578996348, 25.2582325774596],
              [55.3620682360018, 25.2582325774596],
              [55.3620682360018, 25.257898852354],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.728515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'de08d003-7a78-499f-8609-0a2b859120df',
          'Modified Time': '2025-02-26T04:33:55.276Z',
          'Created Time': '2025-02-26T04:29:45.544Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'dfef8b3e-e3ae-49d3-9001-a81ea7c4de43',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3393183296968, 25.2595663902434],
              [55.3394082419057, 25.2595663902434],
              [55.3394082419057, 25.2596308867028],
              [55.3393183296968, 25.2596308867028],
              [55.3393183296968, 25.2595663902434],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.72314453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '718d586f-424a-497d-aad8-f769a91144a7',
          'Modified Time': '2025-02-26T11:22:10.220Z',
          'Created Time': '2025-02-26T13:20:11.965Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '53bc41a0-da38-4f6c-adb8-cfe8222093a9',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3818589506951, 25.2508031017156],
              [55.3823907559367, 25.2508031017156],
              [55.3823907559367, 25.2508626953039],
              [55.3818589506951, 25.2508626953039],
              [55.3818589506951, 25.2508031017156],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.71533203125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2947e9ce-6616-4457-9e01-238e2b5825a5',
          'Modified Time': '2025-02-25T21:24:57.100Z',
          'Created Time': '2025-02-26T13:47:53.095Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'babd2c84-6cca-451f-8a6a-e5db8261642f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3117768110816, 25.2580523946087],
              [55.3124184463931, 25.2580523946087],
              [55.3124184463931, 25.2582879838918],
              [55.3117768110816, 25.2582879838918],
              [55.3117768110816, 25.2580523946087],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.712890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b08c8fcc-11c2-485e-929d-780f7564f167',
          'Modified Time': '2025-02-26T02:55:11.593Z',
          'Created Time': '2025-02-25T21:44:04.603Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '81893e3a-1e1b-41d1-8ebe-6503a557d8c3',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3805357839225, 25.2895748592736],
              [55.3806991220574, 25.2895748592736],
              [55.3806991220574, 25.2897088894653],
              [55.3805357839225, 25.2897088894653],
              [55.3805357839225, 25.2895748592736],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.70361328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fed92d1c-ec34-42f8-8be3-63addac5e9c7',
          'Modified Time': '2025-02-26T02:40:53.892Z',
          'Created Time': '2025-02-26T06:14:55.291Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e212689e-4b43-44ab-af31-1c5e48886a60',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3319616770828, 25.244006373951],
              [55.3322844818389, 25.244006373951],
              [55.3322844818389, 25.2443238161122],
              [55.3319616770828, 25.2443238161122],
              [55.3319616770828, 25.244006373951],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a231da41-4bcc-42b7-a845-12caaaec42b6',
          'Modified Time': '2025-02-26T13:10:23.162Z',
          'Created Time': '2025-02-26T06:39:26.966Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bee8517a-2895-4859-ae84-06879858f56a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3775936398821, 25.2852907421823],
              [55.3777781052966, 25.2852907421823],
              [55.3777781052966, 25.2853972000588],
              [55.3775936398821, 25.2853972000588],
              [55.3775936398821, 25.2852907421823],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69873046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9b088918-804d-4b6f-a5ad-43ac262a3de7',
          'Modified Time': '2025-02-25T17:19:24.095Z',
          'Created Time': '2025-02-26T01:46:52.008Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0e5b8fd7-352c-426a-98fa-72a71c4bb878',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2909783893992, 25.2882446997141],
              [55.2911758556487, 25.2882446997141],
              [55.2911758556487, 25.2888448719404],
              [55.2909783893992, 25.2888448719404],
              [55.2909783893992, 25.2882446997141],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69677734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0172336c-9558-4288-838d-11a91477844e',
          'Modified Time': '2025-02-26T00:54:06.736Z',
          'Created Time': '2025-02-25T16:24:10.203Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '87d523ee-57d8-40e0-b67f-525b561f3845',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3145214967108, 25.2903668880125],
              [55.3147497387065, 25.2903668880125],
              [55.3147497387065, 25.290588750817],
              [55.3145214967108, 25.290588750817],
              [55.3145214967108, 25.2903668880125],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6962890625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7cf0b2fa-ed61-44ae-b8c3-828b0ddb114f',
          'Modified Time': '2025-02-25T18:22:19.077Z',
          'Created Time': '2025-02-26T04:53:04.906Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f8547d27-7cd6-44e2-b01b-2e13abcd5769',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2886737216421, 25.2133166132564],
              [55.288785299885, 25.2133166132564],
              [55.288785299885, 25.2134073081883],
              [55.2886737216421, 25.2134073081883],
              [55.2886737216421, 25.2133166132564],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.69091796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b8adde6f-f249-4128-8fc1-a92ea7a7bd0f',
          'Modified Time': '2025-02-26T14:39:19.960Z',
          'Created Time': '2025-02-26T02:25:45.179Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3dabd63e-2fde-42d6-b05b-89513de479be',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3946073166476, 25.2615313436233],
              [55.394728253563, 25.2615313436233],
              [55.394728253563, 25.2616127991442],
              [55.3946073166476, 25.2616127991442],
              [55.3946073166476, 25.2615313436233],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.68505859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '0bb88625-b8bf-4036-954a-541acc3d06ca',
          'Modified Time': '2025-02-25T15:08:10.686Z',
          'Created Time': '2025-02-26T11:22:18.011Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'be4dc8c6-1eb7-4a09-9c2d-0b21f9c4c705',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3394481610877, 25.2275044972817],
              [55.3395802924834, 25.2275044972817],
              [55.3395802924834, 25.2275855512195],
              [55.3394481610877, 25.2275855512195],
              [55.3394481610877, 25.2275044972817],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ee938170-5f2c-47d1-828a-002fcb7684ee',
          'Modified Time': '2025-02-26T09:26:41.473Z',
          'Created Time': '2025-02-26T06:56:36.295Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '47736295-894d-49d7-aba4-11c497637ade',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.355036191834, 25.2184470276193],
              [55.3554002257283, 25.2184470276193],
              [55.3554002257283, 25.2185116534122],
              [55.355036191834, 25.2185116534122],
              [55.355036191834, 25.2184470276193],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd441a821-e23d-4b8d-95a4-370b4e5ceea1',
          'Modified Time': '2025-02-26T07:26:55.804Z',
          'Created Time': '2025-02-26T13:38:47.900Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd4f0fb57-9c59-4869-9717-d711c3c698a8',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3999644153014, 25.265793812453],
              [55.4002851096862, 25.265793812453],
              [55.4002851096862, 25.2658690871457],
              [55.3999644153014, 25.2658690871457],
              [55.3999644153014, 25.265793812453],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.67724609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '309feb36-0f61-4136-bd83-345b96d61e4c',
          'Modified Time': '2025-02-26T08:26:19.236Z',
          'Created Time': '2025-02-25T20:00:22.844Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '698d3670-6cce-4535-af1a-fa4d68ed63a0',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3821746985913, 25.2895916328318],
              [55.3824117672535, 25.2895916328318],
              [55.3824117672535, 25.2897592789895],
              [55.3821746985913, 25.2897592789895],
              [55.3821746985913, 25.2895916328318],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.662109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '58e65e9b-158d-44a4-bcc8-d7f5a156d189',
          'Modified Time': '2025-02-26T01:41:06.014Z',
          'Created Time': '2025-02-25T20:13:51.915Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '595f498a-146f-4f76-8666-83ad7ccf1043',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2670689416447, 25.1867287515604],
              [55.2672551563556, 25.1867287515604],
              [55.2672551563556, 25.1868169405087],
              [55.2670689416447, 25.1868169405087],
              [55.2670689416447, 25.1867287515604],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.662109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c9014742-fb80-4b31-baeb-e3aede8ea08c',
          'Modified Time': '2025-02-26T08:38:50.991Z',
          'Created Time': '2025-02-25T20:14:17.818Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd28721e2-2ff2-4b02-85d3-5c8e864664a4',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.311283180068, 25.2084261149978],
              [55.3114158843927, 25.2084261149978],
              [55.3114158843927, 25.2088484051166],
              [55.311283180068, 25.2088484051166],
              [55.311283180068, 25.2084261149978],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.65673828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '780aea67-58f3-4f76-a046-f8d67334d39b',
          'Modified Time': '2025-02-25T17:26:38.301Z',
          'Created Time': '2025-02-26T05:27:12.948Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '83fb7d77-391e-4b2f-b085-07aa16fa9c5a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3371039306114, 25.2380031245905],
              [55.3380186641662, 25.2380031245905],
              [55.3380186641662, 25.238570988319],
              [55.3371039306114, 25.238570988319],
              [55.3371039306114, 25.2380031245905],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.65576171875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4ebc83f0-6f4b-4807-894f-b82ec5426086',
          'Modified Time': '2025-02-25T16:49:00.384Z',
          'Created Time': '2025-02-26T01:28:58.435Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '346d1ccc-bf30-479a-a692-44d50ed15fa4',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4352793583907, 25.2092086543497],
              [55.4360799449447, 25.2092086543497],
              [55.4360799449447, 25.2094926477997],
              [55.4352793583907, 25.2094926477997],
              [55.4352793583907, 25.2092086543497],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6552734375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '220cd8da-5526-4ada-aff2-303b7cd33625',
          'Modified Time': '2025-02-25T16:51:31.926Z',
          'Created Time': '2025-02-25T17:58:44.938Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1fd3133a-3e1a-40af-a69e-01b9b5598238',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3728265485277, 25.2419887944361],
              [55.3732849304381, 25.2419887944361],
              [55.3732849304381, 25.2421313609506],
              [55.3728265485277, 25.2421313609506],
              [55.3728265485277, 25.2419887944361],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.64990234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'afe35104-e485-4827-a658-2dfc8bc0d888',
          'Modified Time': '2025-02-26T13:04:56.356Z',
          'Created Time': '2025-02-26T04:59:37.785Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '9ba93075-5c27-416e-be5c-fe1ea7084d5e',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3310313460884, 25.2284734108691],
              [55.33115302256, 25.2284734108691],
              [55.33115302256, 25.2285637831456],
              [55.3310313460884, 25.2285637831456],
              [55.3310313460884, 25.2284734108691],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6474609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd54703e-94eb-4dba-905f-422da3252fb9',
          'Modified Time': '2025-02-26T00:23:42.949Z',
          'Created Time': '2025-02-26T02:31:45.333Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'cfbccb2a-3485-4fae-9dd5-c8797eac1c15',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4452294105225, 25.2712951540267],
              [55.445349697504, 25.2712951540267],
              [55.445349697504, 25.2713945531077],
              [55.4452294105225, 25.2713945531077],
              [55.4452294105225, 25.2712951540267],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6435546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4228103d-6ef8-40f2-92f7-0488817f4926',
          'Modified Time': '2025-02-26T09:16:02.027Z',
          'Created Time': '2025-02-25T19:18:23.189Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '27017e67-0bd0-47fa-a134-9607820bbd87',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.26553123389, 25.2087657157464],
              [55.2656112214183, 25.2087657157464],
              [55.2656112214183, 25.2088934051008],
              [55.26553123389, 25.2088934051008],
              [55.26553123389, 25.2087657157464],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.63818359375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '093c8bef-a2c1-42d9-9b9e-c6572014f1a3',
          'Modified Time': '2025-02-26T07:34:10.219Z',
          'Created Time': '2025-02-25T18:19:20.115Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd39495e5-2164-4588-9e7f-55904965fe01',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3240870400644, 25.2232148255155],
              [55.3249604157446, 25.2232148255155],
              [55.3249604157446, 25.2237568971939],
              [55.3240870400644, 25.2237568971939],
              [55.3240870400644, 25.2232148255155],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.630859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bf1c87c0-6fb2-406d-95fd-480667ca072f',
          'Modified Time': '2025-02-26T00:16:35.184Z',
          'Created Time': '2025-02-25T23:18:09.686Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'debf9cd0-57cf-4bdb-bea9-783782fc221c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2910020874716, 25.1842380447499],
              [55.2910817408139, 25.1842380447499],
              [55.2910817408139, 25.1843207647219],
              [55.2910020874716, 25.1843207647219],
              [55.2910020874716, 25.1842380447499],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'db4001ba-9dbd-4311-a89c-5337294d9be5',
          'Modified Time': '2025-02-26T09:27:23.416Z',
          'Created Time': '2025-02-26T01:20:51.254Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '88885baf-b783-40bd-88a1-f50191d09c37',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2608103871144, 25.2809620340992],
              [55.2609225035203, 25.2809620340992],
              [55.2609225035203, 25.2810708180891],
              [55.2608103871144, 25.2810708180891],
              [55.2608103871144, 25.2809620340992],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6220703125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '1fbd8fbc-c447-46ae-b331-65c325ccf596',
          'Modified Time': '2025-02-25T20:39:06.452Z',
          'Created Time': '2025-02-26T01:10:36.919Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '537444e3-b45b-42f8-8f56-811f489a1de5',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2969946310718, 25.2603006050704],
              [55.2971062181699, 25.2603006050704],
              [55.2971062181699, 25.2603913772059],
              [55.2969946310718, 25.2603913772059],
              [55.2969946310718, 25.2603006050704],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.62158203125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'afe5e86c-f3a9-4a1f-b048-fba360298130',
          'Modified Time': '2025-02-26T11:03:28.302Z',
          'Created Time': '2025-02-25T23:31:39.619Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '36d96b16-5e20-471f-8a51-125752ddaea0',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3862028202842, 25.24241324147],
              [55.3867658611672, 25.24241324147],
              [55.3867658611672, 25.2425345939628],
              [55.3862028202842, 25.2425345939628],
              [55.3862028202842, 25.24241324147],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.62109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '7bf5d03e-4fce-4288-ba66-8e4e78eabbb5',
          'Modified Time': '2025-02-26T11:42:07.353Z',
          'Created Time': '2025-02-25T19:47:42.214Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6edeb8d6-3197-431a-bb82-48a78c2fffee',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3268783001382, 25.1888453195167],
              [55.3283642560896, 25.1888453195167],
              [55.3283642560896, 25.1897889506924],
              [55.3268783001382, 25.1897889506924],
              [55.3268783001382, 25.1888453195167],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.61865234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2578bc1b-dba3-41d2-870b-18a4e751d5ae',
          'Modified Time': '2025-02-26T01:28:06.924Z',
          'Created Time': '2025-02-25T22:56:02.984Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ada2f0c2-5428-4115-a5c2-4a0218a6e7fd',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3409561803691, 25.2151323732949],
              [55.3416270881522, 25.2151323732949],
              [55.3416270881522, 25.2153037806447],
              [55.3409561803691, 25.2153037806447],
              [55.3409561803691, 25.2151323732949],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6064453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e49047e5-2fff-4c2a-babd-3eb8a98031e6',
          'Modified Time': '2025-02-25T15:32:35.030Z',
          'Created Time': '2025-02-26T02:24:52.165Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f4a350b3-21e2-4021-b56f-a62857c46004',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.334960632826, 25.2067125770502],
              [55.3351245971459, 25.2067125770502],
              [55.3351245971459, 25.2072594131099],
              [55.334960632826, 25.2072594131099],
              [55.334960632826, 25.2067125770502],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6044921875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '05a397e8-5510-4842-9f09-cb0c3bda3ecb',
          'Modified Time': '2025-02-26T07:33:00.845Z',
          'Created Time': '2025-02-26T09:58:52.443Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '348a548c-f9d5-4ce7-b7c5-e13bc89c2909',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3215371980743, 25.3004318570045],
              [55.3217865435294, 25.3004318570045],
              [55.3217865435294, 25.300680007572],
              [55.3215371980743, 25.300680007572],
              [55.3215371980743, 25.3004318570045],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.603515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9d5d1456-ea73-4b0c-9492-7c27a3594381',
          'Modified Time': '2025-02-26T03:10:37.405Z',
          'Created Time': '2025-02-25T18:52:34.159Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '246f2226-d22b-44c2-b7c6-db05438cac1f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3181565121384, 25.2929837098309],
              [55.3183210360689, 25.2929837098309],
              [55.3183210360689, 25.2931806197308],
              [55.3181565121384, 25.2931806197308],
              [55.3181565121384, 25.2929837098309],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.6025390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a43c8801-0885-4447-8a9c-f03df0aa25a5',
          'Modified Time': '2025-02-26T00:17:23.248Z',
          'Created Time': '2025-02-25T19:38:57.973Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd8c7e109-bd07-4adc-87c3-b66640931221',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3159012898554, 25.2948068934618],
              [55.3171794759359, 25.2948068934618],
              [55.3171794759359, 25.295094823734],
              [55.3159012898554, 25.295094823734],
              [55.3159012898554, 25.2948068934618],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5986328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ddce13e5-67f4-4106-b7dd-42ee2de1f7ed',
          'Modified Time': '2025-02-26T02:03:41.820Z',
          'Created Time': '2025-02-26T13:48:47.798Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3b45460b-c616-4c25-baf0-42815c24fbd8',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.317553019607, 25.2515939829568],
              [55.3181729234102, 25.2515939829568],
              [55.3181729234102, 25.2517044784108],
              [55.317553019607, 25.2517044784108],
              [55.317553019607, 25.2515939829568],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.591796875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd4f7eed-333a-47af-8912-cd74fac78d0b',
          'Modified Time': '2025-02-25T17:54:48.500Z',
          'Created Time': '2025-02-26T05:24:57.240Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '23cf6be1-de56-485c-9ce5-6a839b82e9d5',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3717893243058, 25.2448257536035],
              [55.3721951038463, 25.2448257536035],
              [55.3721951038463, 25.2448892395266],
              [55.3717893243058, 25.2448892395266],
              [55.3717893243058, 25.2448257536035],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5888671875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'b01345d1-8017-4aea-9291-0a228f7ed65f',
          'Modified Time': '2025-02-26T14:58:48.517Z',
          'Created Time': '2025-02-25T17:53:30.183Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b09284f1-7c04-4ad8-8633-6863265aae53',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2740545250954, 25.2246592781302],
              [55.2741347555321, 25.2246592781302],
              [55.2741347555321, 25.225002639854],
              [55.2740545250954, 25.225002639854],
              [55.2740545250954, 25.2246592781302],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5849609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8511c987-1f7a-46f7-abfc-fc3d17a8be07',
          'Modified Time': '2025-02-26T14:42:24.580Z',
          'Created Time': '2025-02-26T09:59:30.014Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e2b21dda-affd-4e88-831b-9b9c66d79d67',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3553338106232, 25.2506679993933],
              [55.3554024626732, 25.2506679993933],
              [55.3554024626732, 25.2509844669174],
              [55.3553338106232, 25.2509844669174],
              [55.3553338106232, 25.2506679993933],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.583984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '11d67cbc-2b4d-4682-be4b-5fc25d6ba97b',
          'Modified Time': '2025-02-25T22:19:36.974Z',
          'Created Time': '2025-02-26T10:50:14.477Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'b3367bd8-1fe5-46ea-bc31-eae6b84b7d45',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.405999691655, 25.2859198418012],
              [55.4064254162643, 25.2859198418012],
              [55.4064254162643, 25.2860547780068],
              [55.405999691655, 25.2860547780068],
              [55.405999691655, 25.2859198418012],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.56982421875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd3fa1cc-c434-4746-8084-9f72ce668fd8',
          'Modified Time': '2025-02-26T00:42:02.236Z',
          'Created Time': '2025-02-25T19:51:34.112Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ab4c8194-feaa-44c3-bbfe-d2dbb3fb1ec1',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3567712555829, 25.2954212098674],
              [55.3569772995168, 25.2954212098674],
              [55.3569772995168, 25.2955539533685],
              [55.3567712555829, 25.2955539533685],
              [55.3567712555829, 25.2954212098674],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.564453125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '362c1cb2-e4b5-4895-9af4-5aafe6d4a045',
          'Modified Time': '2025-02-26T08:45:12.666Z',
          'Created Time': '2025-02-25T15:51:01.370Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '248afddc-54c1-414e-bef8-f06cb53461c9',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3244048148033, 25.2227793356003],
              [55.3249606138669, 25.2227793356003],
              [55.3249606138669, 25.2231879569576],
              [55.3244048148033, 25.2231879569576],
              [55.3244048148033, 25.2227793356003],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '5e33525a-7b41-4747-92d1-011af48657bd',
          'Modified Time': '2025-02-26T09:30:54.107Z',
          'Created Time': '2025-02-26T02:57:56.250Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1e040c1d-2b68-49d1-b490-82e5e9cc149a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3587910404612, 25.2558919843499],
              [55.3588385284771, 25.2558919843499],
              [55.3588385284771, 25.2561283242498],
              [55.3587910404612, 25.2561283242498],
              [55.3587910404612, 25.2558919843499],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9a43180f-32e2-49af-a7f9-375054577760',
          'Modified Time': '2025-02-25T16:51:23.107Z',
          'Created Time': '2025-02-26T02:30:50.028Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'abfab2e1-fea0-4aae-b7b1-361e89471a5c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.314129344113, 25.2901031897533],
              [55.3143575603323, 25.2901031897533],
              [55.3143575603323, 25.290289132625],
              [55.314129344113, 25.290289132625],
              [55.314129344113, 25.2901031897533],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5556640625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3fd02757-a0ce-49b7-9f1f-afb1c68edbf7',
          'Modified Time': '2025-02-25T22:00:40.581Z',
          'Created Time': '2025-02-25T18:00:02.750Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'eecd7ef6-9494-47e6-afde-c99115fce20e',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3565433734935, 25.2059706125703],
              [55.3566224744099, 25.2059706125703],
              [55.3566224744099, 25.2060443515266],
              [55.3565433734935, 25.2060443515266],
              [55.3565433734935, 25.2059706125703],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55517578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3896b2dd-9a05-4796-ba5b-f6f9cb564b76',
          'Modified Time': '2025-02-25T15:50:43.797Z',
          'Created Time': '2025-02-26T04:13:45.338Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '6ff1838e-623c-4f56-b221-289c900c2545',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3857696474011, 25.239513010102],
              [55.385827366364, 25.239513010102],
              [55.385827366364, 25.2398027515221],
              [55.3857696474011, 25.2398027515221],
              [55.3857696474011, 25.239513010102],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5537109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'a6b7237c-6c77-41ba-89b1-e92bcd36b5dd',
          'Modified Time': '2025-02-26T13:48:21.734Z',
          'Created Time': '2025-02-26T12:20:21.026Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1040130f-62d9-406c-ac44-7f5536345217',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3549958129658, 25.2529118940174],
              [55.355402385481, 25.2529118940174],
              [55.355402385481, 25.2530651158683],
              [55.3549958129658, 25.2530651158683],
              [55.3549958129658, 25.2529118940174],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55322265625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'bd7fa03e-94fd-4218-9a8f-a9e3917dd925',
          'Modified Time': '2025-02-25T15:37:04.699Z',
          'Created Time': '2025-02-26T05:03:49.744Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '042e2607-8733-4992-85c2-b6ed1ae0c616',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3245174841237, 25.2510505137327],
              [55.3249465932592, 25.2510505137327],
              [55.3249465932592, 25.2515532042895],
              [55.3245174841237, 25.2515532042895],
              [55.3245174841237, 25.2510505137327],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55224609375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'fb203e7c-cc33-40ca-9b47-2e28af8e63cf',
          'Modified Time': '2025-02-25T15:35:26.306Z',
          'Created Time': '2025-02-25T19:13:24.425Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7886c034-222e-4d68-9e23-6eb79321b56c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3519111235364, 25.196887008167],
              [55.3520218980595, 25.196887008167],
              [55.3520218980595, 25.1970584475902],
              [55.3519111235364, 25.1970584475902],
              [55.3519111235364, 25.196887008167],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5517578125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'e988d9fd-e71d-4cd6-b177-8b35fc5c4abb',
          'Modified Time': '2025-02-26T03:52:38.970Z',
          'Created Time': '2025-02-26T01:22:47.621Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1def0704-8dca-448a-8618-53ebb88d6eb1',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4456524689502, 25.2442647578651],
              [55.446128513585, 25.2442647578651],
              [55.446128513585, 25.2443979102198],
              [55.4456524689502, 25.2443979102198],
              [55.4456524689502, 25.2442647578651],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.55126953125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '12c8ade5-58e8-47c7-b2b7-cfacd61ad468',
          'Modified Time': '2025-02-26T06:35:01.427Z',
          'Created Time': '2025-02-26T08:32:50.879Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '4bc68825-18db-4470-8af4-0b4f011d9a9e',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3709028358969, 25.1988633232239],
              [55.3715294607404, 25.1988633232239],
              [55.3715294607404, 25.1989911927822],
              [55.3709028358969, 25.1989911927822],
              [55.3709028358969, 25.1988633232239],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5498046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6d0b62f0-4246-46fa-b6ee-5c4029d5f678',
          'Modified Time': '2025-02-26T09:15:55.677Z',
          'Created Time': '2025-02-25T19:44:19.023Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '37c59752-c4cf-420a-9947-5bc1f53cf304',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4459476366173, 25.2112260675063],
              [55.4460570930857, 25.2112260675063],
              [55.4460570930857, 25.2114511736045],
              [55.4459476366173, 25.2114511736045],
              [55.4459476366173, 25.2112260675063],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54833984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'cdbe40eb-20c1-4cf2-bbe1-c542fab27117',
          'Modified Time': '2025-02-26T14:17:16.467Z',
          'Created Time': '2025-02-26T01:35:30.050Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'be9c709d-ce6f-47e6-bce6-98c832c3a19d',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3384945748761, 25.293510868187],
              [55.3385953974451, 25.293510868187],
              [55.3385953974451, 25.2940508319129],
              [55.3384945748761, 25.2940508319129],
              [55.3384945748761, 25.293510868187],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54833984375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '20621018-3930-49e2-ab51-3ce9d525cc5e',
          'Modified Time': '2025-02-26T09:18:40.401Z',
          'Created Time': '2025-02-25T23:39:23.497Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '76f840f8-f496-4f1b-a312-1861f7d5bf7a',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4170993214944, 25.2827087485953],
              [55.4172409961464, 25.2827087485953],
              [55.4172409961464, 25.2828254632975],
              [55.4170993214944, 25.2828254632975],
              [55.4170993214944, 25.2827087485953],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5478515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '00153f56-ee96-4e47-824b-dd95d0b907db',
          'Modified Time': '2025-02-26T08:32:56.981Z',
          'Created Time': '2025-02-26T00:10:38.627Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0a8df166-03b3-43e1-8037-5a0a9957d595',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3417962559872, 25.2652728189876],
              [55.3418756768564, 25.2652728189876],
              [55.3418756768564, 25.2656518510092],
              [55.3417962559872, 25.2656518510092],
              [55.3417962559872, 25.2652728189876],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54736328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8912a9e7-efdc-434f-a175-57e17538cd83',
          'Modified Time': '2025-02-25T18:59:13.395Z',
          'Created Time': '2025-02-25T17:55:11.146Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '1d86d20c-dac4-4097-b802-bf06466c9117',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2755540657157, 25.2618955667581],
              [55.2762725705304, 25.2618955667581],
              [55.2762725705304, 25.2621287013319],
              [55.2755540657157, 25.2621287013319],
              [55.2755540657157, 25.2618955667581],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54736328125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'd9036a6c-bebf-47a8-a529-cc288aff6d50',
          'Modified Time': '2025-02-25T17:27:52.433Z',
          'Created Time': '2025-02-26T05:25:06.009Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0e9568a3-1050-48e6-b039-63790e1bb5b7',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2740002008856, 25.26185201943],
              [55.2749107625348, 25.26185201943],
              [55.2749107625348, 25.2623575893921],
              [55.2740002008856, 25.2623575893921],
              [55.2740002008856, 25.26185201943],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '3ffa34b7-8862-4a60-8c79-7beb04c2457a',
          'Modified Time': '2025-02-26T08:40:57.315Z',
          'Created Time': '2025-02-25T15:19:02.211Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '28decc01-8517-4174-af43-893b34e337ee',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3142196170035, 25.290016207723],
              [55.3144477962664, 25.290016207723],
              [55.3144477962664, 25.2901662306132],
              [55.3142196170035, 25.2901662306132],
              [55.3142196170035, 25.290016207723],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54248046875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ba7b6217-e23f-4cda-bee2-74fb2696ea45',
          'Modified Time': '2025-02-26T13:09:55.316Z',
          'Created Time': '2025-02-25T21:51:22.276Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'ee5c6988-9017-4c44-994f-f3d918aec2c4',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3485735798433, 25.2565584146955],
              [55.3486423020921, 25.2565584146955],
              [55.3486423020921, 25.256883881874],
              [55.3485735798433, 25.256883881874],
              [55.3485735798433, 25.2565584146955],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.54150390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '6f22607c-1e1e-4cbf-87d2-b0bcc337e419',
          'Modified Time': '2025-02-26T07:30:40.461Z',
          'Created Time': '2025-02-25T22:12:26.010Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '0a60ca22-7bf9-4011-840c-8ed265b1edf3',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.274002609315, 25.2601989989716],
              [55.2740833101063, 25.2601989989716],
              [55.2740833101063, 25.2607850308362],
              [55.274002609315, 25.2607850308362],
              [55.274002609315, 25.2601989989716],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5400390625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2d76766a-722f-4a71-adac-7b37149ef397',
          'Modified Time': '2025-02-25T22:05:34.669Z',
          'Created Time': '2025-02-26T01:25:31.959Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '075f5308-374e-4b33-957b-6f16d2da8c9b',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3142667277275, 25.2905854205467],
              [55.3145586217436, 25.2905854205467],
              [55.3145586217436, 25.2908142759127],
              [55.3142667277275, 25.2908142759127],
              [55.3142667277275, 25.2905854205467],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5380859375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '4a65f623-38a6-4027-ac0d-af6572edb71e',
          'Modified Time': '2025-02-26T08:12:50.477Z',
          'Created Time': '2025-02-26T05:19:53.915Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'f2cfa5b0-f35f-4a0a-831f-fd32cbba2ad1',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3790385818728, 25.2565278170627],
              [55.3791175085341, 25.2565278170627],
              [55.3791175085341, 25.2567451636467],
              [55.3790385818728, 25.2567451636467],
              [55.3790385818728, 25.2565278170627],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.537109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '2fd22252-9d02-4f1e-aa89-69b24a908244',
          'Modified Time': '2025-02-25T17:49:38.484Z',
          'Created Time': '2025-02-25T23:14:38.671Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3f960e77-bb26-4594-b5c7-2aae29445ffa',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3506824421683, 25.2393483045259],
              [55.350803889611, 25.2393483045259],
              [55.350803889611, 25.2394925700733],
              [55.3506824421683, 25.2394925700733],
              [55.3506824421683, 25.2393483045259],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.53662109375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ee372971-3638-4bcf-9bb5-b231ea916cc8',
          'Modified Time': '2025-02-26T10:41:41.499Z',
          'Created Time': '2025-02-26T02:42:07.719Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'c58501e9-f477-4646-bce7-d283a6b75093',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3228880619238, 25.2490403788908],
              [55.3238570826914, 25.2490403788908],
              [55.3238570826914, 25.249202617485],
              [55.3228880619238, 25.249202617485],
              [55.3228880619238, 25.2490403788908],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.53271484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '9c6a84f6-dc55-49f4-b265-da3e2253d3b2',
          'Modified Time': '2025-02-26T00:32:06.851Z',
          'Created Time': '2025-02-25T16:13:25.292Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '7080214a-dc8e-469d-9395-0909dfc66622',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.365543640357, 25.2506158640484],
              [55.3658545739856, 25.2506158640484],
              [55.3658545739856, 25.2517771721759],
              [55.365543640357, 25.2517771721759],
              [55.365543640357, 25.2506158640484],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.53173828125,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '354ec939-92e9-4da2-8520-b87984a10564',
          'Modified Time': '2025-02-26T07:52:00.213Z',
          'Created Time': '2025-02-26T10:21:51.586Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '3815e766-c4e6-4757-9237-f4f0f91bd72e',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3418677408702, 25.3163750139026],
              [55.3426356642151, 25.3163750139026],
              [55.3426356642151, 25.3173341710391],
              [55.3418677408702, 25.3173341710391],
              [55.3418677408702, 25.3163750139026],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.52978515625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'ecfbf18e-6493-435f-8d56-d08b0ad0b501',
          'Modified Time': '2025-02-26T08:43:01.534Z',
          'Created Time': '2025-02-25T18:07:17.014Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: '70f34e1c-88f4-41e5-8f1b-4f8dedd8a29f',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3680395396134, 25.2590585329704],
              [55.3682346465229, 25.2590585329704],
              [55.3682346465229, 25.2591017772971],
              [55.3680395396134, 25.2591017772971],
              [55.3680395396134, 25.2590585329704],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.52685546875,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '245b0831-7bc7-4289-b5c1-b5853d1e6714',
          'Modified Time': '2025-02-25T17:18:29.078Z',
          'Created Time': '2025-02-26T08:31:23.637Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'd7d26829-d474-4911-8354-dc0e44865ff0',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.2771690829452, 25.2705522274061],
              [55.2773553978435, 25.2705522274061],
              [55.2773553978435, 25.2706316631502],
              [55.2771690829452, 25.2706316631502],
              [55.2771690829452, 25.2705522274061],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5234375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c9f2c107-1128-432e-8c40-6128372963a0',
          'Modified Time': '2025-02-26T07:41:47.921Z',
          'Created Time': '2025-02-26T12:03:22.045Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'bc044053-86f2-4195-8078-49c910fc35a8',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.3933130563939, 25.2110582590136],
              [55.3937387758593, 25.2110582590136],
              [55.3937387758593, 25.2111119703304],
              [55.3933130563939, 25.2111119703304],
              [55.3933130563939, 25.2110582590136],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.5146484375,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '22b47bc3-51d0-4abd-b95e-ddb062131952',
          'Modified Time': '2025-02-26T04:38:30.086Z',
          'Created Time': '2025-02-25T20:49:50.627Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e7e6b73c-5ab7-452d-92df-c3a49625306c',
        bbox: null,
      },
      {
        type: 'Feature',
        geometry: {
          coordinates: [
            [
              [55.4453162525327, 25.2152685489845],
              [55.4457815379147, 25.2152685489845],
              [55.4457815379147, 25.2154376769316],
              [55.4453162525327, 25.2154376769316],
              [55.4453162525327, 25.2152685489845],
            ],
          ],
          type: 'Polygon',
        },
        properties: {
          confidence: 0.51416015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '12JUN24WV020600024JUN12064900-P1BS-508587353010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '809c7fde-1cd7-4f89-8e6d-3e1f9bb9a768',
          'Modified Time': '2025-02-26T07:37:51.543Z',
          'Created Time': '2025-02-26T05:42:01.261Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JUN12064900-P1BS-508587353010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        id: 'e18831d6-f6b2-4630-93c6-5265f6a8f2fd',
        bbox: null,
      },
      {
        type: 'Feature',
        id: 'b18831d2-a6b2-4630-92c7-5265f6a8f2ab',
        bbox: null,
        properties: {
          confidence: 0.91416015625,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '24JAN12102356-P1BS-508132033010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': 'c12419ac-b23d-45a4-af40-237f4b135310',
          'Modified Time': '2025-02-25T19:08:01.696Z',
          'Created Time': '2025-02-26T05:55:33.951Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JAN12102356-P1BS-508132033010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [51.61639675218592, 25.26910382748828, -30.041321927681565],
              [51.617096795242674, 25.269103365936374, -30.041913272812963],
              [51.61709697784822, 25.268567812938908, -30.042587460950017],
              [51.616396933993975, 25.268568275532708, -30.04199642688036],
              [51.61639675218592, 25.26910382748828, -30.041321927681565],
            ],
          ],
        },
      },
      {
        type: 'Feature',
        id: 'b27831b5-a6b2-4630-23c8-2265f6a8f2bc',
        bbox: null,
        properties: {
          confidence: 0.93,
          classificationIndex: '0',
          classificationName: 'aircraft',
          Symbology: 'a-.-A-C',
          'Image Location': '24JAN12102356-P1BS-508132033010_01_P001',
          Model: 'greyhawk',
          Verification: 'UNVERIFIED',
          'ATR Id': '8b1181db-b8ad-4705-ac21-d16d35639b69',
          'Modified Time': '2025-02-25T19:42:23.176Z',
          'Created Time': '2025-02-26T05:48:40.405Z',
          Affiliation: 'p',
          imageSourceIdentifier: 'http://localhost:8001/resources/24JAN12102356-P1BS-508132033010_01_P001.NTF',
          detectImage: 'http://dm5:8001/images/adbcb5022a7841769de6a68158cfcee4/adbcb5022a7841769de6a68158cfcee4.tiff',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [51.61504616031048, 25.267036897981683, -30.042787085287273],
              [51.6154640569455, 25.26703662817989, -30.04313936829567],
              [51.615464182724025, 25.26671928057477, -30.043539312668145],
              [51.61504628573267, 25.26671955002236, -30.043187139555812],
              [51.61504616031048, 25.267036897981683, -30.042787085287273],
            ],
          ],
        },
      },
    ],
  },
  selectedId: null,
  showAll: true,
  verified: [
    'b5b89e4f-1ecf-4416-bddf-11b433c39181',
    'e5b89e4f-1ecf-4416-bddf-51b433c39185',
    '85b89e4f-1ecf-4416-bddf-81b433c39188',
    '11b89e4f-1ecf-4416-bddf-11b433c39111',
    '14b89e4f-1ecf-4416-bddf-14b433c39114',
  ],
  unverified: [
    'c5b89e4f-1ecf-4416-bddf-21b433c39182',
    'f5b89e4f-1ecf-4416-bddf-61b433c39186',
    '95b89e4f-1ecf-4416-bddf-91b433c39189',
    'b5b89e4f-1ecf-4416-bddf-21b433c3918a',
    '8d59f37b-f59c-4287-b12b-5de02f5f05ca',
    'ada7ebfa-6949-4139-a6ba-7fc12e5dbbb5',
    'fdc08085-63e9-4eb9-bbab-00328398ba75',
    'f689bb13-b304-40b1-a438-4c9db467b818',
    '004c598e-333c-4bc7-b26a-7a4f57540953',
    '5059b19a-23fd-48f2-9c2c-27d54b5a7d8d',
    'dc4a5733-318b-40d0-8aef-fea7df5b7796',
    '829b7fc7-4d4b-4b97-8999-6f94083e1946',
    '5ddc7a87-9be7-4362-933a-a5fe34988f68',
    'aa86620e-7cb3-478f-a81c-489c71a845a3',
    'c44e300c-3b6e-43d3-995c-02debf89973f',
    'c477d578-7794-450b-9d7c-b6fb024790da',
    '54fecec6-42d9-4e41-a8cb-0e1c94a3e1c8',
    'f5150dd3-d301-4cf9-b320-e128bad44c6b',
    'ae68bdaa-5119-48e3-b4a4-4be56e705e59',
    '45277ac7-ab88-4945-a165-5701a6bb29c4',
    '25c14077-d6b5-4a2f-b8cf-06be50c9945a',
    '063ba487-94ea-445c-afb2-16c0b0d2283b',
    'bb7b2397-b303-4dc3-b357-db8b8b753529',
    '95c0ed4a-7559-4622-8a45-161cd2691446',
    '2532795c-aa4e-4a07-8d8d-05ae937c7101',
    'be9c40d8-809e-40bd-aabb-d465d3c8817d',
    '5eb77d69-3b39-47d0-a661-dc88629d5503',
    'c56129d9-0092-4966-b96f-00ba1a7d867b',
    'b9acbbbe-b480-49f7-a2a3-d876045d0fe7',
    'f430610f-0765-4563-b4a9-4b95c545046c',
    'b3bc33f8-5db1-45e7-aaff-c66cadc517fb',
    '8a8caddf-6336-40cd-bb48-628b81fd796a',
    'fcdb0a46-7d3f-4faa-a33b-94eb4aa87b68',
    '01fb0f4b-2c38-4254-a074-f2905623029b',
    '1951a720-09db-4851-8e44-ff39c2d65850',
    'd8152a32-69b5-44ef-bfb8-97b1a126a7c6',
    'cbffcb64-d47b-4a25-8bc5-d782a6692866',
    'd073d8e3-7e2d-4b0a-b7bc-e23883d5045a',
    'dcd9f650-6d31-4cd4-83fd-f937b0bba606',
    '1f4833e1-3a0f-485e-a781-f016c3821268',
    'c48a82c9-bffe-4532-a4d7-742371e46688',
    '93e7a366-3c38-4e6a-a9dc-f0057e699157',
    '3a53fea4-54c4-417d-bcd7-27dd7e5f4a43',
    '969e8b70-a29a-4773-aa2f-c2aaee5dc17f',
    '9e2c86c3-d6d8-4aa7-b1b7-2daf39deab7f',
    '3ef63248-4ce2-4260-9b4f-cb09b0a8c704',
    '78ba3b4c-3080-4794-8505-7bba48c0d027',
    '70833d5a-1d02-48ed-86b3-5aab86ae51a4',
    'af954ae7-24aa-4f8b-a5c4-4c9fa791a644',
    '025f8c1f-5f62-496b-a2eb-d121bef23967',
    'a02ad6f4-b1e8-4354-a3de-99208f698f09',
    '5dc10694-8791-4f5c-98a4-0fdea00ec46f',
    '432163ce-bf37-4c4c-9086-71239c7b863d',
    '428c41e6-83ee-427b-be58-395346bbaddb',
    '7399737e-fad1-4f20-9d6c-d09c37ff326d',
    'b34aea30-579a-46f0-922a-e57593a96d6a',
    'd9f66c4c-a9af-4e6d-8cb2-fc98a1ad2827',
    '41a12e40-d289-4cec-a315-e5191a755fcd',
    '4bc09d6f-33ca-4f9e-84ff-b5fff0aa6313',
    '48dcf0ec-b2e9-4973-9654-324d40609da2',
    'e2fe2709-bceb-4108-ab52-70e45808af18',
    '09434267-15ab-4953-8160-7e17a5bfcb3b',
    '9c2ea151-f2e1-46f2-86e2-0ed8d7bc6c05',
    'aa889fac-c859-4faf-9d12-5e3287ebba0c',
    'f9602b95-63b1-49b4-9cb7-f9cbc6862ca7',
    'd932f0c0-9cf9-4458-ad59-5216456c2bdf',
    '052aa977-58e7-4ead-81bd-36fc587867d6',
    'b4318171-bd9e-4d3c-b254-5d2ca252497b',
    '3ad06ded-d977-4fda-9aa0-32aefe1083e7',
    '7005a797-2fe9-4452-a435-dff6b0b127aa',
    'd0687d54-ad19-4281-a561-4b5cc05ff4cd',
    'daa5fc4a-a68d-4cf3-8e42-1fd6ac87feb1',
    'b336b876-8a0e-4d0d-937d-3e23f75e14f8',
    'e1514645-040b-4e43-8df1-e9cd2b78852f',
    'df498090-317a-4d74-8906-9f3f87876112',
    'a4491235-dce4-4efb-81f5-d75f830fbff3',
    'ed29fca7-10a4-4bdf-9aef-b94e4cd8afef',
    'e0582775-8db1-4118-953b-36cc73db1267',
    '91e96022-d93d-444d-af4a-04b10b9d6ecb',
    '16359a8f-2067-4dd6-ae36-b8cefdb45e50',
    '6bba42bf-038d-4170-93f3-8e2d9f3cc345',
    'cd71def1-b475-4227-bfca-284f5da6db44',
    '7fdda0eb-f739-48c2-93cd-3bd46b0a8c73',
    '26b54553-461b-43b7-978b-9097fdcdd324',
    'f5d57916-306c-48c1-8d97-e71a80be9def',
    'a3e60e03-8b6d-4cfa-a526-9f79fce3a025',
    '9f9eeec6-db36-48cc-aaa4-236dadd0e57c',
    'c866ae6a-a17a-4a90-a384-1e1f12eed262',
    'aec3fb69-b1d8-4311-a940-3af980866294',
    'e98008ac-1672-4635-990a-463ca766ecb7',
    '92c379dc-be87-4400-95c6-dbf4c130aa88',
    'f47269aa-6e24-4666-b261-b9decf002af2',
    '92d2eb68-c84d-41e9-a982-7adf2b976839',
    'a6122658-6efa-4381-9513-da30f7251cc6',
    'f501d1f5-046e-4c74-9736-2f8afabb3b6b',
    '7f2a29ca-8648-42d0-9e79-59f19d6ac435',
    'dfa839a5-90cf-4737-b9cc-a8a3aa9f8519',
    '6691f26f-270d-4eff-adcc-49b1611d6351',
    'b38d4c87-0e1b-431b-9ffb-a63c4363842c',
    '767400ba-4633-4898-8e11-db07a3641b1d',
    'bf466764-91bf-4d0d-bfe6-b53e368f08bb',
    'eb7f1e68-222f-4f27-bf82-878516231c69',
    '5b062927-6f2f-4d73-9700-fec4f1447d9f',
    '952fcd90-7a7b-4c47-b7ee-977269c06e70',
    '76996b2b-8016-4208-b92b-bb6447da181f',
    '654d9914-3b57-4369-8f0a-d6830bebf4ae',
    '80c41bae-4fcc-49b0-b482-a291b23227e7',
    '9f5f7695-c486-4f7c-b062-ec4c8e9a6969',
    'b3c2ac5a-a671-4d89-96bc-df456e27cd86',
    '337d1258-e42c-475c-a6a2-1c20a6c9a359',
    'c10b7c02-a254-4e6d-b842-41428ae83c4f',
    'fec7421f-4e0b-4517-a97b-9f57d018bc07',
    '9e545541-6ea7-41c8-993e-2da7333e3fa3',
    '4d7dfb60-eb23-4d69-91df-d809b0e29383',
    'a427f497-fa3a-4e56-9a35-2374c256e36e',
    '388d334d-140c-403d-b3e0-94ebc76281a5',
    '11d24104-bd22-4c2e-be58-b852af1b328c',
    'c05f2b9c-3ddc-4fcc-a815-0587b1c2023a',
    'ace81492-c715-4447-96ce-98d34c9d8153',
    '412a9d2c-d7fd-4dc2-b2a1-9389b1fc396d',
    'e86dce3b-0b50-4b41-983e-99d766c78da9',
    '47a41f9f-c113-4a6c-8eea-78d046c15329',
    'eeb7f26c-1cbd-4018-b08e-1b948c202f89',
    'b5f1a027-bdb9-4fc8-8001-d9b33f6c5e4b',
    '75e9bb32-76a2-4434-9f08-157a1f0f4e03',
    'e19df4fe-65ca-4abf-ac0f-e242e20de698',
    '1604c841-7211-499a-aa69-63b32a2fa7ad',
    '20bdfccf-25a5-45e4-8883-26947cfb7691',
    '44284456-c6b9-4cc3-b0f6-5fe4cf0cde67',
    'e60b75ff-4349-4bf1-a7aa-2984e9098a8e',
    'e3c8f72e-cd47-4247-a0de-7f10065528d1',
    '90317da6-4a27-493b-9e2d-b4b53b7067ca',
    '9e937ccd-68ee-48fc-a581-61571ced8b9d',
    'bf6c8191-6748-4dc7-856c-7587de63bd89',
    '4d4c4645-ca19-4b12-9341-8635c29b3c5c',
    'caee8bb1-1e0e-42de-8e6d-8b641119233f',
    '775f83c2-b8e5-422a-bf53-b802bdf1193b',
    '3538a9d9-d81f-46ce-b2f8-b80dd6407836',
    '6f4f37c0-fa8c-43d0-a523-17bd82636e4a',
    'a4a804e9-bb04-4460-9c0d-31250ee20beb',
    '9d81dda3-91c9-4c72-b448-ecd3004e4129',
    'aef8f4ef-b89f-4550-926c-1047c8b01dac',
    '359734bf-3092-42c1-9222-06d5dcd610a2',
    '3bde004a-5fd2-4fb1-8320-464577457fbd',
    '50bccf14-1b6c-41ca-b0da-d93b92107f63',
    'e3b18db7-de66-4c6d-b764-2e62e62d72c9',
    '6e0de562-96ee-433f-9d73-efe761f7af9b',
    '9d6f5f05-d961-49ce-85d8-15bde77eea7d',
    '2ce00b06-5ea6-421c-b44e-57c976415f14',
    '851da353-6086-489a-9c1f-60394b8c4e62',
    '785019ff-302e-4bae-8e28-72252e81d2a1',
    '19bec521-6106-4203-aa4c-ba1f4fde7558',
    'cf2974f7-65c5-418a-a8fb-7d5408f9ea9a',
    'ab5f5ba2-5d4f-40cd-adb4-c6816d978435',
    '2d3a1051-b848-493f-9e68-3de2e482e8cd',
    '6aebf6ec-feec-4fbe-9657-d9be2bee1b77',
    'cc4190a9-000b-4a1b-a302-5d264785cff9',
    'cc07f245-ab0f-4189-90d9-84874c10fceb',
    '5d3be3c0-349d-42da-a0ff-944b0be195af',
    '4e10f766-39cd-4138-b2b7-00d88a83cd33',
    'c97fb086-7b7b-45f0-aad9-39c9ed6c2712',
    '2c4f22df-6350-4a33-bedf-16a2badbddeb',
    'c0977094-5e10-48c7-8914-26ea1249034c',
    'eafcac07-6c01-4a70-832d-987d657d3a41',
    'd7332123-b0cb-4142-a0f7-aa1e41853fbc',
    '8d67b1a0-2797-4a2c-a283-f55cdc698abf',
    'de877fbf-ce37-4675-b26b-7367384e0938',
    '8c10b398-f8f6-422f-9264-6f68d292febc',
    'b5937c0c-9206-4a8a-ac04-53b67347cc1c',
    '7d63b09e-0088-4f7a-a5e4-9124f5ac75dc',
    'e6ef048a-8192-4114-be25-68a81a62ea7e',
    '1afdcb9f-01b6-4fce-b6cd-86aa2bd843d4',
    '1bcd2a95-7cd6-4647-ad9a-645ab022efc6',
    '2ccbfa3b-1e5f-4788-b3c1-3aabd72f2e87',
    '829a524c-418b-4e91-b441-94ef042d514b',
    '62e738bc-6df4-43bf-ab3d-9f18e47b0a5d',
    'dfef8b3e-e3ae-49d3-9001-a81ea7c4de43',
    '53bc41a0-da38-4f6c-adb8-cfe8222093a9',
    'babd2c84-6cca-451f-8a6a-e5db8261642f',
    '81893e3a-1e1b-41d1-8ebe-6503a557d8c3',
    'e212689e-4b43-44ab-af31-1c5e48886a60',
    'bee8517a-2895-4859-ae84-06879858f56a',
    '0e5b8fd7-352c-426a-98fa-72a71c4bb878',
    '87d523ee-57d8-40e0-b67f-525b561f3845',
    'f8547d27-7cd6-44e2-b01b-2e13abcd5769',
    '3dabd63e-2fde-42d6-b05b-89513de479be',
    'be4dc8c6-1eb7-4a09-9c2d-0b21f9c4c705',
    '47736295-894d-49d7-aba4-11c497637ade',
    'd4f0fb57-9c59-4869-9717-d711c3c698a8',
    '698d3670-6cce-4535-af1a-fa4d68ed63a0',
    '595f498a-146f-4f76-8666-83ad7ccf1043',
    'd28721e2-2ff2-4b02-85d3-5c8e864664a4',
    '83fb7d77-391e-4b2f-b085-07aa16fa9c5a',
    '346d1ccc-bf30-479a-a692-44d50ed15fa4',
    '1fd3133a-3e1a-40af-a69e-01b9b5598238',
    '9ba93075-5c27-416e-be5c-fe1ea7084d5e',
    'cfbccb2a-3485-4fae-9dd5-c8797eac1c15',
    '27017e67-0bd0-47fa-a134-9607820bbd87',
    'd39495e5-2164-4588-9e7f-55904965fe01',
    'debf9cd0-57cf-4bdb-bea9-783782fc221c',
    '88885baf-b783-40bd-88a1-f50191d09c37',
    '537444e3-b45b-42f8-8f56-811f489a1de5',
    '36d96b16-5e20-471f-8a51-125752ddaea0',
    '6edeb8d6-3197-431a-bb82-48a78c2fffee',
    'ada2f0c2-5428-4115-a5c2-4a0218a6e7fd',
    'f4a350b3-21e2-4021-b56f-a62857c46004',
    '348a548c-f9d5-4ce7-b7c5-e13bc89c2909',
    '246f2226-d22b-44c2-b7c6-db05438cac1f',
    'd8c7e109-bd07-4adc-87c3-b66640931221',
    '3b45460b-c616-4c25-baf0-42815c24fbd8',
    '23cf6be1-de56-485c-9ce5-6a839b82e9d5',
    'b09284f1-7c04-4ad8-8633-6863265aae53',
    'e2b21dda-affd-4e88-831b-9b9c66d79d67',
    'b3367bd8-1fe5-46ea-bc31-eae6b84b7d45',
    'ab4c8194-feaa-44c3-bbfe-d2dbb3fb1ec1',
    '248afddc-54c1-414e-bef8-f06cb53461c9',
    '1e040c1d-2b68-49d1-b490-82e5e9cc149a',
    'abfab2e1-fea0-4aae-b7b1-361e89471a5c',
    'eecd7ef6-9494-47e6-afde-c99115fce20e',
    '6ff1838e-623c-4f56-b221-289c900c2545',
    '1040130f-62d9-406c-ac44-7f5536345217',
    '042e2607-8733-4992-85c2-b6ed1ae0c616',
    '7886c034-222e-4d68-9e23-6eb79321b56c',
    '1def0704-8dca-448a-8618-53ebb88d6eb1',
    '4bc68825-18db-4470-8af4-0b4f011d9a9e',
    '37c59752-c4cf-420a-9947-5bc1f53cf304',
    'be9c709d-ce6f-47e6-bce6-98c832c3a19d',
    '76f840f8-f496-4f1b-a312-1861f7d5bf7a',
    '0a8df166-03b3-43e1-8037-5a0a9957d595',
    '1d86d20c-dac4-4097-b802-bf06466c9117',
    '0e9568a3-1050-48e6-b039-63790e1bb5b7',
    '28decc01-8517-4174-af43-893b34e337ee',
    'ee5c6988-9017-4c44-994f-f3d918aec2c4',
    '0a60ca22-7bf9-4011-840c-8ed265b1edf3',
    '075f5308-374e-4b33-957b-6f16d2da8c9b',
    'f2cfa5b0-f35f-4a0a-831f-fd32cbba2ad1',
    '3f960e77-bb26-4594-b5c7-2aae29445ffa',
    'c58501e9-f477-4646-bce7-d283a6b75093',
    '7080214a-dc8e-469d-9395-0909dfc66622',
    '3815e766-c4e6-4757-9237-f4f0f91bd72e',
    '70f34e1c-88f4-41e5-8f1b-4f8dedd8a29f',
    'd7d26829-d474-4911-8354-dc0e44865ff0',
    'bc044053-86f2-4195-8078-49c910fc35a8',
    'e7e6b73c-5ab7-452d-92df-c3a49625306c',
    'e18831d6-f6b2-4630-93c6-5265f6a8f2fd',
    'b18831d2-a6b2-4630-92c7-5265f6a8f2ab',
    'b27831b5-a6b2-4630-23c8-2265f6a8f2bc',
  ],
  dismissed: [
    'b5b89e4f-1ecf-4416-bddf-01b433c39180',
    'd5b89e4f-1ecf-4416-bddf-41b433c39184',
    '75b89e4f-1ecf-4416-bddf-71b433c39187',
    '10b89e4f-1ecf-4416-bddf-10b433c39110',
    '13b89e4f-1ecf-4416-bddf-13b433c39113',
  ],
  deleted: [],
  verificationFilter: 'ALL',
  updated: new Date(),
};

//////////////////////////////////////////////////////////

type Atr = { id: string; data: Feature };
type AtrDataParam = {
  verification: string;
  dismissed: boolean;
  deleted: boolean;
  verified: boolean;
  unverified: boolean;
};
const atrAdapter = createEntityAdapter<Atr>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: atr => atr.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

const clearState = state => {
  state.entities = {};
  state.ids = [];
  state.selectedId = null;
  state.geojson = null;
  state.verified = [];
  state.unverified = [];
  state.dismissed = [];
  state.deleted = [];
  state.updated = new Date();
  state.status = RequestStatus.NOT_LOADED;
  state.verificationFilter = 'ALL';
};

export const atrSlice = createSlice({
  name: 'atr',
  // initialState: atrAdapter.getInitialState({
  //   status: RequestStatus.NOT_LOADED,
  //   geojson: null as any,
  //   selectedId: null,
  //   showAll: true,
  //   verified: [] as string[],
  //   unverified: [] as string[],
  //   dismissed: [] as string[],
  //   deleted: [] as string[],
  //   verificationFilter: 'ALL',
  //   updated: new Date(),
  // }),
  initialState: atrAdapter.getInitialState(atrTestState),
  reducers: {
    atrDetectsAdded: atrAdapter.addOne,
    addAtrDetects: (state, action) => {
      // Or, call them as "mutating" helpers in a case reducer
      atrAdapter.addMany(state, action.payload);
      // state.geojson = buildGeoJson(action.payload);
      // state.historicRetrievalInfo = buildHistoricRetrievalData(action.payload);
      state.status = RequestStatus.SUCCESS;
      state.updated = new Date();
    },
    setAtrDetects: (state, action: PayloadAction<Atr[]>) => {
      atrAdapter.setAll(state, action.payload);
      // state.geojson = buildGeoJson(action.payload);
      state.selectedId = null;
      // state.historicRetrievalInfo = buildHistoricRetrievalData(action.payload);
      state.updated = new Date();
      state.status = RequestStatus.SUCCESS;
    },
    updateAtrDetects: (state, action: PayloadAction) => {
      //Not yet implemented
      //we need to be able to remove a specific datum and replace it with another
      // return state;
    },
    removeAtrDetects: state => {
      //Not yet implemented
      // return state;
    },
    clearAtrDetects: state => {
      clearState(state);
    },
    showAllDetects: state => {
      state.showAll = true;
    },
    hideAllDetects: state => {
      state.showAll = false;
    },
    setState: (state, action: PayloadAction<RequestStatus>) => {
      if (action.payload === null) return state;
      state.updated = new Date();
      state.status = action.payload;
    },
    setVerificationFilter: (state, action) => {
      state.verificationFilter = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllAtrDetects.pending, (state, { payload }) => {
      state.status = RequestStatus.PENDING;
    });
    builder.addCase(getAllAtrDetects.fulfilled, (state, { payload }) => {
      // if (payload) state.data.features = payload;
      if (payload) {
        atrAdapter.setAll(state, payload.entities);
        // const data = Object.values(payload);
        // const d2 = data.map((d: any) => d.data);

        // const gjfc: any = {
        //   type: 'FeatureCollection',
        //   features: d2,
        // };
        state.geojson = payload.response;
        state.verified = payload.verified;
        state.unverified = payload.unverified;
        state.dismissed = payload.dismissed;
        // state.historicRetrievalInfo = buildHistoricRetrievalData(payload);
        state.updated = new Date();
        state.status = RequestStatus.SUCCESS;
        // const entities = payload.flatMap(entry => {
        //   return entry.payload.map(x => {
        //     return { id: x.data.properties.id, opsBoxId: entry.opsBoxId, data: x.data};
        //   });
        // });
        // intelDataAdapter.setAll(state, payload);
        // state.selectedId = null;
      }
    });
    builder.addCase(getAllAtrDetects.rejected, (state, action) => {
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(getAtrDetectById.pending, (state, { payload }) => {
      state.status = RequestStatus.PENDING;
    });
    builder.addCase(getAtrDetectById.fulfilled, (state, { payload }) => {
      if (payload) {
        // TODO
      }
    });
    builder.addCase(getAtrDetectById.rejected, (state, action) => {
      state.updated = new Date();
      state.status = RequestStatus.ERROR;
    });
    builder.addCase(clearAtrDetects, (state, action) => {
      clearState(state);
    });
    builder.addCase(setAtrDetects, (state, action) => {
      clearState(state);
    });
  },
});

const getData = async (requestUrl: string) => {
  const client = axios.create();
  axiosRetry(client, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
  });
  const response = await client.get(requestUrl);
  return response.data;
};

/**
 * Thunk to retrieve all atr detects
 */
export const getAllAtrDetects = createAsyncThunk('atr/getAllAtrDetects', async (arg: any, thunkAPI) => {
  try {
    const response = await getData(arg);

    // const mr =response.flatMap(entry => {
    //   return entry.payload.map(p => p.data);
    // });
    // const entities = response.features.flatMap(entry => {
    //   return entry.map(x => {
    //     return { id: x.id, data: x.data };
    //   });
    // }
    const verified: string[] = [];
    const unverified: string[] = [];
    const dismissed: string[] = [];
    const entities = response.features.map(entry => {
      const verifiedState = entry.properties['Verification'];
      if (verifiedState && verifiedState.toLowerCase() === 'verified') {
        verified.push(entry.id);
      }
      if (verifiedState && verifiedState.toLowerCase() === 'dismissed') {
        dismissed.push(entry.id);
      }
      if (verifiedState && verifiedState.toLowerCase() === 'unverified') {
        unverified.push(entry.id);
      }
      return { id: entry.id, data: entry };
    });
    console.log(`getAllAtr ${entities}`);
    return { response, entities, verified, unverified, dismissed };
  } catch (ex) {
    //@ts-ignore
    console.error(`Atr Error occurred calling '${arg}', code=${ex.code}`);
    return thunkAPI.rejectWithValue(ex);
  }
});

export const getAtrDetectById = createAsyncThunk('atr/getAtrDetectById', async (arg: any, thunkAPI) => {
  return thunkAPI.rejectWithValue('Not implemented');
});

export const {
  selectById: selectAtrDetectById,
  selectIds: selectAtrDetectIds,
  selectEntities: selectAtrDetectEntities,
  selectAll: selectAllAtrDetects,
  selectTotal: selectTotalAtrDetects,
} = atrAdapter.getSelectors((state: any) => state.atr);

const buildGeoJson = payload => {
  const data = Object.values(payload);
  const d2 = data.map((d: any) => d.data);

  const gjfc: any = {
    type: 'FeatureCollection',
    features: d2,
  };
  return gjfc;
};

const showAllState = state => state.atr.showAll;
const updatedState = state => state.atr.updated;
const statusState = state => state.atr.status;
const verificationFilterState = state => state.atr.verificationFilter;
export const selectAtrShowAll = createSelector([showAllState], x => x);

export const selectAtrUpdated = createSelector([updatedState], x => x);
export const selectAtrStatus = createSelector([statusState], x => x);
export const selectVerificationFilter = createSelector([verificationFilterState], x => x);
export const dismissedState = state => state.atr.dismissed;
export const verifiedState = state => state.atr.verified;
export const unverifiedState = state => state.atr.unverified;
const atrGeojsonState = state => state.atr.geojson;
export const selectAtrGeojson = createSelector([atrGeojsonState], x => x);

export const selectAtr = (args: AtrDataParam) =>
  createSelector(
    [state => selectAllAtrDetects(state), dismissedState, verifiedState, unverifiedState],
    (detects, dismissed: any, verified: any, unverified: any) => {
      const filteredDetects = detects.filter(data => {
        if (!args) {
          return true;
        }
        if (args.verification && args.verification.toLowerCase() === 'all') {
          return true;
        }
        let filter = false;
        if (!args.dismissed) {
          filter = dismissed.includes(data.id);
        }
        if (!args.verified) {
          filter = filter || verified.includes(data.id);
        }
        if (!args.unverified) {
          filter = filter || unverified.includes(data.id);
        }
        return !filter;
      });
      return filteredDetects;
    },
  );

export const selectAtrAsGeoJSON = (args: AtrDataParam) =>
  createSelector(
    [state => selectAllAtrDetects(state), dismissedState, verifiedState, unverifiedState],
    (detects, dismissed: any, verified: any, unverified: any) => {
      const filteredDetects = detects.filter(data => {
        if (!args) {
          return true;
        }
        if (args.verification && args.verification.toLowerCase() === 'all') {
          return true;
        }
        let filter = false;
        if (!args.dismissed) {
          filter = dismissed.includes(data.id);
        }
        if (!args.verified) {
          filter = filter || verified.includes(data.id);
        }
        if (!args.unverified) {
          filter = filter || unverified.includes(data.id);
        }
        return !filter;
      });
      return buildGeoJson(filteredDetects);
    },
  );

export const selectAtrIdsByImageId = createSelector(
  [
    // Usual first input - extract value from `state`
    selectAllAtrDetects,
    // Take the second arg, `entry parameters`, and forward to the output selector
    (state, imageId) => imageId,
  ],
  // Output selector gets (`items, entry)` as args
  (entities, imageId) => {
    const filteredDetectsIds = entities.filter(detect => {
      if (detect.data.properties) {
        // 20250216 -- TODO ..update with imageId when available
        const dataImageId = detect.data.properties['Image Location'];
        return dataImageId === imageId;
      } else {
        return false;
      }
    });
    return filteredDetectsIds;
  },
);

export const selectDetectsByImageId = createSelector(
  [
    // Usual first input - extract value from `state`
    selectAtrDetectEntities,
    // Take the second arg, `entry parameters`, and forward to the output selector
    (state, imageId) => imageId,
  ],
  // Output selector gets (`items, entry)` as args
  (entities, imageId) => {
    const values = Object.values(entities);
    const filteredDetects = values.filter(detect => {
      if (!detect) {
        return false;
      }
      if (detect.data.properties) {
        // 20250216 -- TODO ..update with imageId when available
        const dataImageId = detect.data.properties['Image Location'];
        return dataImageId === imageId;
      } else {
        return false;
      }
    });
    return filteredDetects;
  },
);

export const ATR_ALL_FILTER = {
  verification: 'ALL',
  dismissed: true,
  deleted: true,
  verified: true,
  unverified: true,
};

export const ATR_VERIFIED_FILTER = {
  verification: 'VERIFIED',
  dismissed: false,
  deleted: false,
  verified: true,
  unverified: false,
};

export const ATR_UNVERIFIED_FILTER = {
  verification: 'UNVERIFIED',
  dismissed: false,
  deleted: false,
  verified: false,
  unverified: true,
};

export const ATR_DISMISSED_FILTER = {
  verification: 'DISMISSED',
  dismissed: true,
  deleted: false,
  verified: false,
  unverified: true,
};

export const buildAtrFilteredDataRequest = (filter: string) => {
  let atrFilter = ATR_ALL_FILTER;
  if (filter) {
    const upper = filter.toUpperCase();
    switch (upper) {
      case 'VERIFIED':
        atrFilter = ATR_VERIFIED_FILTER;
        break;
      case 'UNVERIFIED':
        atrFilter = ATR_UNVERIFIED_FILTER;
        break;
      default:
        atrFilter = ATR_ALL_FILTER;
        break;
    }
  }
  return atrFilter;
};

export const {
  addAtrDetects,
  setAtrDetects,
  updateAtrDetects,
  removeAtrDetects,
  clearAtrDetects,
  showAllDetects,
  hideAllDetects,
  setState,
} = atrSlice.actions;
export default atrSlice.reducer;
